import { Directive, Input, OnChanges, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform, OnChanges {

  @Input('formato') formato: string = 'dd/MM/yyyy';

  transform(value: Date | string, ...args: any[]): string {
    if (!value) {
      return '';
    }

    let date: Date;
    if (typeof value === 'string') {
      date = new Date(value);
    } else {
      date = value;
    }

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();

    return this.formato.replace('dd', day).replace('MM', month).replace('yyyy', year);
  }

  ngOnChanges() {
    // Si necesitas realizar alguna lógica adicional cuando el formato cambia, puedes hacerlo aquí.
  }
}
