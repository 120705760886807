<div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
    <div class="flex flex-column align-items-center justify-content-center">
        <div style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%);">
            <div class="w-full surface-card py-8 px-5 sm:px-8" style="border-radius:53px">
                <div class="flex justify-content-center">
                    <img src="assets/parkifacil.svg" alt="parkifacil logo" class="mb-5 w-12rem flex-shrink-0">      
                </div>          

                <p-toast></p-toast>

                <form [formGroup]="loginForm">
                    <div>
                        <label for="email" class="block text-900 text-xl font-medium mb-2">Usuario</label>
                        <input formControlName="email" id="email"  name="email" type="text" placeholder="Ingresar usuario" pInputText class="w-full md:w-30rem mb-5" style="padding:1rem">
                        


                        <label for="password" class="block text-900 font-medium text-xl mb-2">Contraseña</label>
                        <p-password formControlName="password" id="password" [feedback]="false" placeholder="Ingresar contraseña" [toggleMask]="true" styleClass="mb-5" inputStyleClass="w-full p-3 md:w-30rem"></p-password>


                        <div class="flex align-items-center justify-content-between mb-5 gap-5">
                            <div class="flex align-items-center">
                                <p-checkbox id="rememberme1" [binary]="true" styleClass="mr-2"></p-checkbox>
                                <label for="rememberme1">Recuérdame</label>
                            </div>
                            <a class="font-medium no-underline ml-2 text-right cursor-pointer" style="color: var(--primary-color)">Recuperar contraseña</a>
                        </div>
                        <button pButton pRipple label="Ingresar" class="w-full p-3 text-xl" type="submit" (click)="login()"></button>
                    </div>
                </form>  

            </div>
        </div>
    </div>
</div>
