<div class="card">
    
    <p-toast></p-toast>

    <div class="p-fluid grid">
          <div class="field col-9">
                <label htmlFor="user">Nueva contraseña</label>
                <input [(ngModel)]="password" pInputText [pKeyFilter]="blockSpace" placeholder="Ejemplo: @WS12B"/>
          </div>
</div>

    <div>
          <p-button  label="Guardar contraseña" [raised]="false" severity="success" icon="pi pi-check" iconPos="right" (click)="guardarContrasena()"></p-button>
    </div>


</div>