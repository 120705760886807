<div class="grid">
	<div class="col-12">

		<div class="card px-6 py-6"> 


			<ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
				<li>
					<a class="text-500 no-underline line-height-3 cursor-pointer">Home</a>
				</li>
				<li class="px-2">
					<i class="pi pi-angle-right text-500 line-height-3"></i>
				</li>
				<li>
					<a class="text-500 no-underline line-height-3 cursor-pointer">Ventas</a>
				</li>
				<li class="px-2">
					<i class="pi pi-angle-right text-500 line-height-3"></i>
				</li>
				<li>
					<span class="text-900 line-height-3">Vendedores</span>
				</li>
			</ul>
			<br>

			<p-toast></p-toast>

			<p-toolbar styleClass="mb-4">
				<ng-template pTemplate="left">

					<span class="block mt-2 md:mt-0 p-input-icon-left">
						<i class="pi pi-search"></i>
						<input pInputText type="text" (input)="dtUsuarios.filterGlobal($event.target.value, 'contains')" placeholder="Alias, rfc, nombre"  class="w-full sm:w-auto"/>
					</span>
						
				</ng-template>

				<ng-template pTemplate="right">
					<button pButton pRipple label="Agregar" icon="pi pi-plus" class="p-button-success mr-2" (click)="showDialog()"></button>
			</ng-template>
		</p-toolbar>

		<p-table #dtUsuarios [value]="products" [columns]="cols" responsiveLayout="scroll" [rows]="10" [globalFilterFields]="['nombre','email', 'rfc', 'alias']" 
		[paginator]="true" [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true" currentPageReportTemplate="Monstrando {first} a {last} de {totalRecords} registros" 
		[(selection)]="selectedProducts" selectionMode="multiple" [rowHover]="true" dataKey="id">

		<ng-template pTemplate="header">
			<tr>
					<th pSortableColumn="name">Alias</th>
					<th pSortableColumn="name">RFC</th>
					<th pSortableColumn="name">Nombre</th>
					<th pSortableColumn="name">Correo</th>
                              <!-- <th pSortableColumn="name">Tipo de usuario<p-sortIcon field="tipo"></p-sortIcon></th> -->
					<th></th>
			</tr>
	</ng-template>

	<ng-template pTemplate="body" let-product>
		<tr>	
			<td style="width:15%; min-width:15rem; ">
				<!-- <span class="p-column-title">Alias</span> -->
				{{product.alias}}
		</td>

				<td style="width:14%; min-width:14rem; ">
				<!-- <span class="p-column-title">Correo</span> -->
				{{product?.rfc}}
				</td>

				<td style="width:10%; min-width:15rem; "><span class="p-column-title">Nombre</span>
						{{product.nombre}}
				</td>
				<td style="width:10%; min-width:15rem; ">
						<span class="p-column-title">Correo</span>
						{{product.email}}
				</td>
                        <!-- <td style="width:16%; min-width:10rem; ">
                              <span class="p-column-title">Tipo agente</span>

					@if (product.rol.descripcion == "AGENTE_TICKETS") {
						
						Vendedor de tickets
					}

					@if (product.rol.descripcion == "AGENTE_MULTAS") {
						
						Agente de multas
					}

                  </td> -->
				<td>
						<div class="flex justify-content-end">
								<button  pTooltip="Actualizar información"  pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-info mr-5" (click)="edit(product)"></button>
								<button  pTooltip="Desactivar vendedor"  pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger" (click)="deleteModal(product)"></button>
						</div>
				</td>
		</tr>
</ng-template>

		</p-table>
		</div>
	</div>
</div>

<p-dialog header="Registrar vendedor de tickets" [(visible)]="visibleModal" (onHide)="hideDialog()"  [modal]="true" [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">
	
	<form [formGroup]="nuevoAgenteForm">
		<div class="p-fluid grid">
			<!-- <div class="field col-4">
				<label htmlFor="amount">Rol</label>
				<p-dropdown [(ngModel)]="selectedRol" [ngModelOptions]="{standalone: true}" [options]="roles" optionLabel="rol" [showClear]="true" placeholder="Selecciona un rol"></p-dropdown>
			</div> -->
			<div class="field col-12">
				<label htmlFor="amount">Nombre</label>
				<span class="p-input-icon-left">
					<i class="pi pi-user"></i>
					<input maxlength="30" formControlName="nombre" maxlength="25" type="text" pInputText placeholder="Ingresar nombre" />
				</span>
			</div>
		</div>
	
		<div class="p-fluid grid">
	
			<div class="field col-6">
				<label htmlFor="amount">Correo</label>
				<span class="p-input-icon-left">
					<i class="pi pi-envelope"></i>
					<input maxlength="30" formControlName="email" type="email" [email]="true" pInputText placeholder="Ingresar correo" />
					@if (nuevoAgenteForm.get('email').invalid && nuevoAgenteForm.get('email').touched) {
						@if (nuevoAgenteForm.get('email').errors.required) {
							<div>El correo es requerido</div>
						}
						@if (nuevoAgenteForm.get('email').errors.email) {
							<div>El correo es invalido</div>
						}
					}
				</span>
			</div>
	
			<div class="field col-6">
				<label htmlFor="amount">Telefono</label>
				<span class="p-input-icon-left">
					<!-- <i class="pi pi-whatsapp"></i> -->
					<!-- <input formControlName="telefono" type="text" pInputText placeholder="Ingresar telefono" /> -->
					<p-inputMask 
   									 mask="(999) 999-9999" 
   									 formControlName="telefono"
   									 placeholder="(000) 000-0000" />
				</span>
			</div>
	
		</div>
		
		<p-divider></p-divider>

		<br>

		<div class="p-fluid grid">
	
			<div class="field col-4">
				<label htmlFor="amount">RFC</label>
				<span class="p-input-icon-left">
					<i class="pi pi-id-card"></i>
					<input maxlength="13" style="text-transform:uppercase" formControlName="rfc" type="text" pKeyFilter="alphanum" pInputText placeholder="Ingresar RFC"/>
				</span>
			</div>

			<div class="field col-4">
				<label htmlFor="amount">Alias</label>
				<span class="p-input-icon-left">
					<i class="pi pi-info-circle"></i>
					<input maxlength="25" formControlName="alias" type="text" pInputText placeholder="Ingresar alias"/>
				</span>
			</div>
	
			<div class="field col-4">
				<label htmlFor="amount">Tipo persona</label>
				<p-dropdown [(ngModel)]="selectedTipo" [ngModelOptions]="{standalone: true}" [options]="tipos" optionLabel="tipo" [showClear]="true" placeholder="Selecciona un tipo"></p-dropdown>
			</div>

		</div>


		<p-divider></p-divider>

		<div class="p-fluid grid">
	
			<div class="field col-9">
				<label htmlFor="amount">Calle</label>
				<span class="p-input-icon-left">
					<i class="pi pi-map-marker"></i>
					<input maxlength="25" formControlName="calle" type="text" pInputText placeholder="Ingresar calle"/>
				</span>
			</div>

			<div class="field col-3">
				<label htmlFor="amount">Número</label>
				<span class="p-input-icon-left">
					<i class="pi pi-map-marker"></i>
					<input maxlength="4" formControlName="numero" type="text"  pKeyFilter="int" pInputText placeholder="Ingresar número"/>
				</span>
			</div>
	
		</div>


		<div class="p-fluid grid">
	
			<div class="field col-5">
				<label htmlFor="amount">Colonia</label>
				<span class="p-input-icon-left">
					<i class="pi pi-map-marker"></i>
					<input maxlength="25" formControlName="colonia" type="text" pInputText placeholder="Ingresar colonia"/>
				</span>
			</div>

			<div class="field col-3">
				<label htmlFor="amount">Código postal</label>
				<span class="p-input-icon-left">
					<i class="pi pi-map-marker"></i>
					<input maxlength="5" formControlName="cp" type="text" pInputText placeholder="CP" pKeyFilter="int"/>
				</span>
			</div>

			<div class="field col-4">
				<label htmlFor="amount">Municipio</label>
				<span class="p-input-icon-left">
					<i class="pi pi-map-marker"></i>
					<input maxlength="30" formControlName="municipio" type="text" pInputText placeholder="Ingresar municipio"/>
				</span>
			</div>
	
	
		</div>

		<p-divider></p-divider>
	
		<div class="p-fluid grid">
	
			<div class="field col-6">
				<label htmlFor="amount">Contraseña</label>
				<span class="p-input-icon-left">
					<i class="pi pi-eye"></i>
					<input maxlength="25" formControlName="password" type="text" pInputText placeholder="Ingresar contraseña" />
				</span>
			</div>
	
			<div class="field col-6">
				<label htmlFor="amount">Repetir contraseña</label>
				<span class="p-input-icon-left">
					<i class="pi pi-eye"></i>
					<input maxlength="25" formControlName="passwordRepeat" type="text" pInputText placeholder="Ingresar contraseña" />
				</span>
			</div>
	
		</div>
	
		<div class="p-fluid grid">
			<div class="field col-9"></div>
			<div class="field col-3">
				<p-button (click)="registrarAgente()" type="submit" label="Guardar" [raised]="false" severity="success" icon="pi pi-save" iconPos="left"></p-button>
			</div>
		</div>
	</form>
</p-dialog>

<p-dialog header="Actualizar vendedor" [modal]="true" (onHide)="cancelEdit()" [(visible)]="editMod" [style]="{ width: '25rem' }">
	<!-- <span class="p-text-secondary block mb-5">El siguiente será eliminado:</span> -->
	<form [formGroup]="editForm">

		<div class="flex align-items-center gap-3 mb-5">
			<label class="font-semibold w-6rem">Correo</label>
			<input maxlength="30" formControlName="email" pInputText id="email" type="email" [email]="true" class="flex-auto" autocomplete="off"/>
			@if (editForm.get('email').invalid && editForm.get('email').touched) {
				@if (editForm.get('email').errors.required) {
					<small>El correo es requerido</small>
				}
				@if (editForm.get('email').errors.email) {
					<small>El correo es invalido</small>
				}
			}
		</div>
		<div class="flex align-items-center gap-3 mb-5">
			<label class="font-semibold w-6rem">Nombre</label>
			<input maxlength="30" formControlName="nombre" pInputText   id="email" class="flex-auto" autocomplete="off"/>
		</div>

		<div class="flex align-items-center gap-3 mb-5">
			<label class="font-semibold w-6rem">RFC</label>
			<input maxlength="13"  style="text-transform:uppercase" formControlName="rfc" pInputText  pKeyFilter="alphanum" id="email" class="flex-auto" autocomplete="off"/>
		</div>

		<div class="flex align-items-center gap-3 mb-5">
			<label class="font-semibold w-6rem">Alias</label>
			<input maxlength="20" formControlName="alias" pInputText   id="email" class="flex-auto" autocomplete="off"/>
		</div>

		<div class="flex align-items-center gap-3 mb-5">
			<label htmlFor="amount">Tipo persona</label>
			<p-dropdown [(ngModel)]="selectedTipo" [ngModelOptions]="{standalone: true}" [options]="tipos" optionLabel="tipo" [showClear]="true" placeholder="Selecciona un tipo"></p-dropdown>
		</div>

		<!-- <div class="flex align-items-center gap-3 mb-5">
			<label class="font-semibold w-6rem">Nombre</label>
			<input maxlength="25" formControlName="nombre" pInputText pKeyFilter="alpha"  id="email" class="flex-auto" autocomplete="off"/>
		</div> -->

		<div class="flex align-items-center gap-3 mb-5">
			<label class="font-semibold w-6rem">Calle</label>
			<input maxlength="25" formControlName="calle" pInputText  class="flex-auto" autocomplete="off"/>
		</div>
		<div class="flex align-items-center gap-3 mb-5">
			<label class="font-semibold w-6rem">Número</label>
			<input maxlength="4"  formControlName="numero" pInputText pKeyFilter="int"  id="email" class="flex-auto" autocomplete="off"/>
		</div>
		<div class="flex align-items-center gap-3 mb-5">
			<label class="font-semibold w-6rem">Colonia</label>
			<input maxlength="25" formControlName="colonia" pInputText  id="email" class="flex-auto" autocomplete="off"/>
		</div>
		<div class="flex align-items-center gap-3 mb-5">
			<label class="font-semibold w-6rem">Código postal</label>
			<input maxlength="5" formControlName="cp" pInputText pKeyFilter="int"  id="email" class="flex-auto" autocomplete="off"/>
		</div>
		<div class="flex align-items-center gap-3 mb-5">
			<label class="font-semibold w-6rem">Municipio</label>
			<input maxlength="30" formControlName="municipio" pInputText   id="email" class="flex-auto" autocomplete="off"/>
		</div>
		<div class="flex align-items-center gap-3 mb-5">
			<label class="font-semibold w-6rem">Telefono</label>
			<!-- <input pInputText id="email" class="flex-auto" autocomplete="off"/> -->
			<p-inputMask 
			mask="(999) 999-9999" 
			formControlName="telefono"
			placeholder="(000) 000-0000" />
		</div>

	</form>
	<div class="flex justify-content-end gap-2">
		<p-button label="Cancelar" severity="secondary" (click)="cancelEdit()" />
		<p-button label="Confirmar" (click)="saveEdit()"/>
	</div>
</p-dialog>

<p-dialog header="Eliminar usuario" [modal]="true" [(visible)]="deleteMod" (onHide)="closeWarning()" [style]="{ width: '25rem' }">
	<!-- <span class="p-text-secondary block mb-5">El siguiente será eliminado:</span> -->
	<div class="flex align-items-center gap-3 mb-5">
		<label class="font-semibold w-6rem">Rol</label>
		<input pInputText id="email" class="flex-auto" autocomplete="off" [(ngModel)]="deleteRol" disabled/>
	</div>
	<div class="flex align-items-center gap-3 mb-5">
		<label class="font-semibold w-6rem">Nombre</label>
		<input pInputText id="email" class="flex-auto" autocomplete="off" [(ngModel)]="deleteName" disabled/>
	</div>
	<div class="flex justify-content-end gap-2">
		<p-button label="Cancelar" severity="warning" (click)="cancelDelete()" />
		<p-button label="Confirmar" severity="success" (click)="deleteProduct()" />
	</div>
</p-dialog>

<p-dialog header="Advertencia" [modal]="true" [(visible)]="warningSaldoMod">
	<div class="mt-3 mb-3 grid align-items-center">
		<i class="pi pi-exclamation-triangle text-500 text-4xl"></i>
		<span class="xl">El usuario no puede ser eliminado ya que cuenta con ${{saldo}} de saldo disponible.</span>
	</div>
	<div class="flex justify-content-end gap-2">
		<p-button label="Cerrar" severity="success" (click)="closeWarning()" />
	</div>
</p-dialog>