export enum API_URLS{
      LOGEO = 'auth/login',
      GET_LISTADO_AGENTES = 'agentes',
      REGISTRAR_AGENTE = 'auth/register',
      GET_AGENTES_TICKET = 'agentes/tickets',
      POST_VENTA_PAQUETE = 'ventas/paquete',
      GET_SALDOS_AGENTES = 'ventas',
      GET_VENDEDORES = 'agentes/tickets',
      GET_MULTAS_TOTAL = 'multas',
      PUT_PAGAR_MULTA = 'multas/pagar/',
      GET_PAQUETES_SALDO = 'ventas/paquetes',
      GET_COSTOS = 'ventas/costos',
      GET_HISTORIAL = 'ventas/historial/',
      GET_HISTORIAL_AGENTE = 'ventas/historial/agente/',
      GET_USUARIOS_SISTEMA = 'sistema/usuarios',
      GET_COSTOS_SISTEMA = 'sistema/costos',
      UPDATE_COSTOS = 'sistema/costos/actualizar',
      UPDATE_PARAMETROS = 'sistema/parametros/ticket/multa',
      UPDATE_PARAMETROS_TICKET = 'sistema/ticket',
      UPDATE_PARAMETROS_MULTA = 'sistema/multa',
      GET_PARAMETROS_TICKET_MULTA = 'sistema/parametros',
      GET_PARAMETROS_TICKET = 'sistema/parametros/leyenda/ticket',
      GET_PARAMETROS_MULTA = 'sistema/parametros/leyenda/multa',
      GET_PARAMETROS_CONFIGURACION = 'sistema/parametros/configuracion',
      GET_MOTIVOS_MULTA = 'multas/motivos',
      POST_MOTIVOS_MULTA = 'multas/motivos/registrar',
      PUT_MOTIVOS_MULTA = 'multas/motivos/actualizar/',
      DELETE_MOTIVOS_MULTA = 'multas/motivos/borrar/',
      GET_AUDITORIAS = 'auditoria',
      BORRADO_LOGICO = 'administrador/borrar/',
      GET_USUARIO = 'administrador/usuario/',
      EDIT_USER = 'administrador/editar',
      GET_SALDO = 'ventas/saldo/',
      GET_BITACORA_PARAMETROS = 'auditoria/bitacora',
      POST_RESET_PASSWORD = 'auth/password/',
      UPDATE_PARAMETROS_CONFIGURACION = 'sistema/parametros/configuracion',
      POST_BITACORA_VENTAS = 'auditoria/bitacora/ventas/registrar',
      GET_BITACORA_VENTAS = 'auditoria/bitacora/ventas',
      POST_BITACORA_MULTAS = 'auditoria/bitacora/multas/registrar',
      GET_BITACORA_MULTAS = 'auditoria/bitacora/multas',
      GET_BITACORA_VENDEDORES = 'auditoria/bitacora/vendedores',
      GET_LISTADO_AGENTES_MULTAS = 'agentes/multas',
      GET_TIMEZONE = 'timezone/current',
      SET_TIMEZONE = 'timezone/set',
      GET_IMG_MULTA = 'multas/download/',
}