import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/app.layout.module';
import { NotfoundComponent } from './demo/components/notfound/notfound.component';
import { ProductService } from './demo/service/product.service';
import { CountryService } from './demo/service/country.service';
import { CustomerService } from './demo/service/customer.service';
import { EventService } from './demo/service/event.service';
import { IconService } from './demo/service/icon.service';
import { NodeService } from './demo/service/node.service';
import { PhotoService } from './demo/service/photo.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptorService } from './auth/service/jwt-interceptor.service';
import { ErrorInterceptorService } from './auth/service/error-interceptor.service';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { DateFormatPipe } from './dateFormatPipe';

@NgModule({
    declarations: [AppComponent, NotfoundComponent,DateFormatPipe],
    imports: [AppRoutingModule, AppLayoutModule, NgxUiLoaderModule],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass:JwtInterceptorService, multi:true},
        {provide: HTTP_INTERCEPTORS, useClass:ErrorInterceptorService, multi:true},
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        // { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true},
        CountryService, CustomerService, EventService, IconService, NodeService,
        PhotoService, ProductService
    ],
    exports: [
        DateFormatPipe
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
