import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from "./service/app.layout.service";
import { SplitButtonModule } from 'primeng/splitbutton';
import { AuthService } from '../auth/service/user.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
})
export class AppTopBarComponent {
    router = inject(Router)

    items: MenuItem[];
    name:String;

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;

    constructor(public layoutService: LayoutService,   private authService: AuthService,) { 
        this.name = sessionStorage.getItem('nombre');

        this.items = [
            {
                label: 'Cerrar sesión',
                icon: 'pi pi-sign-out',
                command: () => {
                    this.cerrarSesion();
                }
            },
            {
                label: 'Cambiar contraseña',
                icon: 'pi pi-key',
                command: () => {
                    this.router.navigate(['home/cambiar-contrasena'])
                }
            },
        ];
    }

    cerrarSesion(){
        this.authService.logout();
    }

}
