import { tap } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { API_URLS } from '../auth/shared/models/apiURL';

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {

  constructor(private http: HttpClient) { 

  }

  httpOptions = {
        headers: new HttpHeaders({
              'Content-Type': 'application/json'
        })
  };

  public getToken(){
        return sessionStorage.getItem("token");
  }

getTimeZone(){
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
    return this.http.get<any>(environment.API_URL + API_URLS.GET_TIMEZONE, this.httpOptions);
}

setTimeZone(timezone){
      let params = new HttpParams()
      .set('timeZoneId', timezone);

      this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
      return this.http.post<any>(environment.API_URL + API_URLS.SET_TIMEZONE + "?"+params,this.httpOptions);
}

}
