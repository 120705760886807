import { Component, OnInit, inject } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DividerModule } from 'primeng/divider';
import { FieldsetModule } from 'primeng/fieldset';
import { InputTextModule } from 'primeng/inputtext';
import { SliderModule } from 'primeng/slider';
import { SystemService } from '../../service/system.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputMaskModule } from 'primeng/inputmask';

interface Costos{
costoAuto: number
costoMoto : number
hora_fin: any
hora_inicio: any
paqueteDesde: number
paqueteHasta: number
intervalo:number
porcentajeGanancia: number
ticketMaximo: number
valorHacendario: number
saldoMaximo: number
utilidad_maxima: number
}

@Component({
  selector: 'app-citizen-payment',
  standalone: true,
  imports: [
    FieldsetModule, 
    InputTextModule, 
    ButtonModule, 
    DividerModule, 
    CalendarModule,
    ReactiveFormsModule,
    FormsModule,
    ToastModule,
    InputNumberModule,
    InputMaskModule,
    CalendarModule
  ],
  providers:[MessageService],
  templateUrl: './citizen-payment.component.html',
  styleUrl: './citizen-payment.component.scss'
})
export class CitizenPaymentComponent implements OnInit{
  systemService = inject(SystemService)

  costoAuto: number = 0
  costoMoto : number = 0
  hora_fin: Date|undefined
  hora_inicio: Date|undefined
  paqueteDesde: number = 0
  paqueteHasta: number = 0
  porcentajeGanancia: number = 0
  ticketMaximo: number = 0
  valorHacendario: number = 0
  intervalo: number = 0
  saldoMaximo: number = 0

  costosForm: FormGroup

  minInicio = new Date();
  maxInicio = new Date();
  minFinal = new Date();
  maxFinal = new Date();

  utilidad_maxima: number;

  userName: string;
  currentValues: Costos
  
  constructor(
    private formBuilder: FormBuilder,
		private messageService: MessageService,
  ){}
  ngOnInit(){
    this.getCostos()
    this.createForm()
    this.getUserData()

    this.minInicio.setHours(7,0,0,0)
    this.maxInicio.setHours(11,0,0,0)
    
    this.minFinal.setHours(12,0,0,0)
    this.maxFinal.setHours(24,0,0,0)

  }

  createForm(): FormGroup{
		return this.costosForm = this.formBuilder.group({
			costoAuto: ['',],
			costoMoto: ['',],
			hora_fin: ['',],
			hora_inicio: ['',],
			paqueteDesde: ['', ],
			paqueteHasta: ['', ],
      intervalo: ['', ],
			porcentajeGanancia: ['',],
			ticketMaximo: ['', ],
			valorHacendario: ['',],
      saldoMaximo: ['',],
		});
	}


  updateValues(){

    if(this.costoAuto > this.valorHacendario || this.costoMoto > this.valorHacendario){

      this.addWarning('error',`El costo de la hora debe ser 
      menor al valor máximo del código hacendario`,'Advertencia')

    }else if(this.paqueteHasta > this.saldoMaximo){

      this.addWarning('error',`El paquete máximo no debe ser mayor 
      que el saldo máximo permitido `,'Advertencia')

    }else{

    let saveValue;
    
    if(!this.hora_inicio || !this.hora_fin){
      this.addWarning('error','Debe seleccionar un horario de operación','Advertencia')
      return
    }

    if(this.porcentajeGanancia > this.utilidad_maxima){
      this.addWarning('error',`La utilidad máxima para el vendedor es del ${this.utilidad_maxima}%`,'Advertencia')
      return
    }
      
    let startHours = this.hora_inicio.getHours().toString().padStart(2, '0')
    let startMinutes = this.hora_inicio.getMinutes().toString().padStart(2, '0')
    let formatedStart = `${startHours}:${startMinutes}`

    let endHours = this.hora_fin.getHours().toString().padStart(2, '0')
    let endMinutes = this.hora_fin.getMinutes().toString().padStart(2, '0')
    let formatedEnd = `${endHours}:${endMinutes}`

    
    this.costosForm.controls['costoAuto'].setValue(this.costoAuto)
    this.costosForm.controls['costoMoto'].setValue(this.costoMoto)
    this.costosForm.controls['hora_fin'].setValue(formatedEnd)
    this.costosForm.controls['hora_inicio'].setValue(formatedStart)
    this.costosForm.controls['paqueteDesde'].setValue(this.paqueteDesde)
    this.costosForm.controls['paqueteHasta'].setValue(this.paqueteHasta)
    this.costosForm.controls['intervalo'].setValue(this.intervalo)
    this.costosForm.controls['porcentajeGanancia'].setValue(this.porcentajeGanancia)
    this.costosForm.controls['ticketMaximo'].setValue(this.ticketMaximo)
    this.costosForm.controls['valorHacendario'].setValue(this.valorHacendario)
    this.costosForm.controls['saldoMaximo'].setValue(this.saldoMaximo)

    saveValue = this.costosForm.value 

    console.log("update values",saveValue)

    let modifiedFields = this.getModifiedFields(this.currentValues, saveValue);
    //console.log("modifiedFields",modifiedFields)

    this.systemService.updateCostos(saveValue).subscribe({
      next: (data) => {
        this.addWarning('success','Datos actualizados correctamente','Notificación')
        setTimeout(() => {
          this.getCostos();
        }, 1000);

        this.systemService.postModifiedFields(modifiedFields).subscribe(
          (data) => {
            console.log("modifiedFields",data)
          }
        )

      },error: (error)=> {
        this.addWarning('error','Error al actualizar los datos','Advertencia')
        throw new console.error(error);
      },
    })

  }
}

  getCostos(){
    this.systemService.getCostosSistema().subscribe({

      next: (data:Costos) => {

        // console.log(data)

        let inicio = new Date();
        let fin = new Date();

        inicio.setHours(data.hora_inicio[0], data.hora_inicio[1],0,0)
        fin.setHours(data.hora_fin[0], data.hora_fin[1],0,0)
        
        this.costoAuto = data.costoAuto | 0
        this.costoMoto = data.costoMoto | 0
        this.hora_fin = fin;
        this.hora_inicio = inicio;
        this.paqueteDesde = data.paqueteDesde | 0
        this.paqueteHasta = data.paqueteHasta | 0
        this.porcentajeGanancia = data.porcentajeGanancia | 0
        this.intervalo = data.intervalo | 0
        this.ticketMaximo = data.ticketMaximo | 0
        this.valorHacendario = data.valorHacendario | 0
        this.saldoMaximo = data.saldoMaximo | 0
        this.utilidad_maxima = data.utilidad_maxima | 0
        this.currentValues = data
        this.currentValues.hora_inicio = inicio.getHours().toString().padStart(2, '0') + ':' + inicio.getMinutes().toString().padStart(2, '0')
        this.currentValues.hora_fin = fin.getHours().toString().padStart(2, '0') + ':' + fin.getMinutes().toString().padStart(2, '0')
        console.log("currentValues",this.currentValues)

      },error: (error) =>{
        console.error(error)
      },
     }
    )
  }

  getModifiedFields(currentValues: any, updateValues: any): any[] {
    const modifiedFields: any[] = [];

    for (const key in currentValues) {
        if (currentValues.hasOwnProperty(key) && updateValues.hasOwnProperty(key)) {
            if (currentValues[key] !== updateValues[key]) {
                modifiedFields.push({
                    campo: key,
                    valor_anterior: currentValues[key],
                    valor_actual: updateValues[key],
                    usuario: this.userName
                });
            }
        }
    }

    return modifiedFields;
}

getUserData(){
  let id = sessionStorage.getItem('id');
  this.systemService.getUsuario(Number(id)).subscribe({
    next: (data) => {
      // console.log(data)
      this.userName = data.nombre
    }
  })
}

  addWarning(severity: string,message: string, resumen: string){
		this.messageService.add({ severity: severity, summary: resumen, 
		detail: message });
	}
}
