<div class="grid">
	<div class="col-12">

		<div class="card px-6 py-6"> 

			<ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
				<li>
					<a class="text-500 no-underline line-height-3 cursor-pointer">Home</a>
				</li>
				<li class="px-2">
					<i class="pi pi-angle-right text-500 line-height-3"></i>
				</li>
				<li>
					<a class="text-500 no-underline line-height-3 cursor-pointer">Ventas</a>
				</li>
				<li class="px-2">
					<i class="pi pi-angle-right text-500 line-height-3"></i>
				</li>
				<li>
					<span class="text-900 line-height-3">Actualización de multas</span>
				</li>
			</ul>
			<br>

			<p-toast></p-toast>

                  <p-toolbar styleClass="mb-4">
				<ng-template pTemplate="right">

					<span class="block mt-2 md:mt-0 p-input-icon-left">
						<i class="pi pi-search"></i>
						<input pInputText type="text" (input)="dtMultas.filterGlobal($event.target.value, 'contains')" placeholder="Vehiculo, tipo multa"  class="w-full sm:w-auto"/>
					</span>

					<span class="block ml-3 mt-2 md:mt-0 p-input-icon-left">
						<i class="pi pi-calendar"></i>
						<p-calendar clear
						placeholder="Fecha" 
						[(ngModel)]="rangeDates" 
						selectionMode="range" 
						dataType="string"
						dateFormat="dd/mm/yy"
						[showButtonBar]="true"
						(onSelect)="filterProducts($event)"
						(onMonthChange)="changeMonth($event)"
						(onClearClick)="clearProducts()"
						(onTodayClick)="todayProducts()"/>
					</span>
						<!-- <div class="my-2">
								<button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success mr-2" (click)="openNew()"></button>
								<button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger" (click)="deleteSelectedProducts()" [disabled]="!selectedProducts || !selectedProducts.length"></button>
						</div> -->
				</ng-template>

				<!-- <ng-template pTemplate="right">
					<button pButton pRipple label="Agregar" icon="pi pi-plus" class="p-button-success mr-2" ></button>
			</ng-template> -->
		</p-toolbar>


		<p-table #dtMultas [value]="products" [columns]="cols" responsiveLayout="scroll" [rows]="10" [globalFilterFields]="['placa','tipo','marca','modelo','multa']" 
		[paginator]="true" [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true" currentPageReportTemplate="Monstrando {first} a {last} de {totalRecords} registros" 
		[(selection)]="selectedProducts" selectionMode="multiple" [rowHover]="true" dataKey="id">

		<ng-template pTemplate="header">
			<tr>	<th pSortableColumn="fechaCandado">Fecha<p-sortIcon field="fechaCandado"/></th>
					<th>Hora</th>
					<th>Folio</th>
					<th>Vehiculo</th>
					<th>Tipo multa</th>
					<th>Estatus</th>
                        <th></th>
						<th></th>
			</tr>
	</ng-template>

	<ng-template pTemplate="body" let-product>
		<tr>
				<td style="width:7%; min-width:7rem;">
					<span class="p-column-title">fechaCandado</span>
					{{product.fechaCandado}}
				</td>
				<td style="width:7%; min-width:7rem;">
					{{product.horaCandado}}
				</td>

				<td style="width:7%; min-width:7rem;">
					{{product.folio_multa}}
				</td>

				<td style="width:10%; min-width:23rem;"><span class="p-column-title">vehiculo</span>
						{{product.placa}} {{product.marca}} {{product.modelo}}
				</td>
				<td style="width:10%; min-width:10rem;">
						<span class="p-column-title">tipo</span>
						{{product.multa}}
				</td>
                        <td style="width:10%; min-width:10rem;">
                              @if (product.pagada) {
                                    PAGADA
                              }

                              @else {
                                    PENDIENTE
                              }
                        </td>

				<td>
							<div class="flex justify-content-end">
									<button  pTooltip="Fotos del vehiculo"  pButton pRipple icon="pi pi-image" class="p-button-rounded p-button-info mr-5" (click)="getImagenesMulta(product.id)"></button>
									<!-- <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger" (click)="deleteProduct(product)"></button> -->
							</div>
						</td>

				<td>
						<div class="flex justify-content-end">
								<button  pTooltip="Registro de pago de multa"  pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-info mr-5" (click)="showDialog(product)"></button>
								<!-- <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger" (click)="deleteProduct(product)"></button> -->
						</div>
				</td>
		</tr>
</ng-template>

		</p-table>
		</div>
	</div>
</div>



      <p-dialog header="Registro de pago de multa" [(visible)]="visibleModal" [modal]="true" [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">
            
			@if(documento){
				<div class="p-fluid grid">
					<div class="field col-6">
						  <label htmlFor="amount">Vehiculo</label>
						  <span class="p-input-icon-left">
								<i class="pi pi-car"></i>
								<input type="text" [(ngModel)]="vehiculo" pInputText disabled/>
						  </span>
					</div>
  
					<div class="field col-4">
					  <label htmlFor="amount">Documento</label>
					  <span class="p-input-icon-left">
							<i class="pi pi-file"></i>
							<input type="text" [(ngModel)]="documento" pInputText disabled/>
					  </span>
					</div>
  
					<div class="field col-2">
						  <label htmlFor="amount">Tipo</label>
						  <span class="p-input-icon-left">
								<i class="pi pi-info-circle"></i>
								<input type="text" pInputText [(ngModel)]="tipoMulta" disabled/>
						  </span>
					</div>
			  </div>
			}

			@else {
				<div class="p-fluid grid">
					<div class="field col-8">
						  <label htmlFor="amount">Vehiculo</label>
						  <span class="p-input-icon-left">
								<i class="pi pi-car"></i>
								<input type="text" [(ngModel)]="vehiculo" pInputText disabled/>
						  </span>
					</div>
					<div class="field col-4">
						  <label htmlFor="amount">Tipo vehiculo</label>
						  <span class="p-input-icon-left">
								<i class="pi pi-info-circle"></i>
								<input type="text" pInputText [(ngModel)]="tipoMulta" disabled/>
						  </span>
					</div>
			  </div>
			}



            <div class="p-fluid grid">

                  <div class="field col-3">
                        <label htmlFor="amount">Monto</label>
                        <span class="p-input-icon-left">
                              <i class="pi pi-money-bill"></i>
                              <input type="number" pInputText [(ngModel)]="monto" disabled/>
                        </span>
                  </div>

                  <div class="field col-4">
                        <label htmlFor="amount">Agente</label>
                        <span class="p-input-icon-left">
                              <i class="pi pi-user"></i>
                              <input type="text" pInputText [(ngModel)]="agente" disabled/>
                        </span>
                  </div>

				  <div class="field col-5">
					<label htmlFor="amount">Folio de registro de pago</label>
					<span class="p-input-icon-left">
						  <i class="pi pi-info-circle"></i>
						  <input type="text" maxlength="20" [(ngModel)]="folio" pInputText [disabled]="pagada"/>
					</span>
			  </div>

            </div>

            <div class="p-fluid grid">
                  <div class="field col-9"></div>
                  <div class="field col-3">
                        <p-button (click)="pagarMulta()" label="Pagar" [raised]="false" severity="success" 
				icon="pi pi-save" iconPos="left" [disabled]="pagada"></p-button>
                  </div>
            </div>
      </p-dialog>

	<!-- <p-dialog 
    [modal]="true"
    [(visible)]="imageModal" 
    [style]="{ width: '50rem' }" 
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }">
    
	<p-galleria 
    [(value)]="imagenesMulta" 
    [numVisible]="5" 
    [circular]="true" 
    [showItemNavigators]="true" 
    [showThumbnails]="false" 
    [responsiveOptions]="responsiveOptions" 
    [containerStyle]="{ 'max-width': '640px' }">
        <ng-template pTemplate="item" let-item>
            <img class="w-9" [src]="'data:image/png;base64,'+item.image" style="width: 100%; display: block;" />
        </ng-template>
</p-galleria>

	</p-dialog> -->


	<p-galleria
    [(value)]="imagenesMulta"
    [(visible)]="imageModal"
    [responsiveOptions]="responsiveOptions"
    [containerStyle]="{ 'max-width': '850px' }"
    [numVisible]="7"
    [circular]="true"
    [fullScreen]="true"
    [showItemNavigators]="true"
    [showThumbnails]="false">
        <ng-template pTemplate="item" let-item>
            <img class="w-6"
				[src]="'data:image/png;base64,'+item.image"
                style="width: 100%; display: block;" />
        </ng-template>
</p-galleria>