import { Product } from './../../../demo/api/product';
import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { ToolbarModule } from 'primeng/toolbar';
import { SystemService } from '../../service/system.service';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { CatalogUsersService } from 'src/app/catalog-users/service/catalogUsers.service';
import { InputMaskModule } from 'primeng/inputmask';
import { KeyFilterModule } from 'primeng/keyfilter';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-system-users',
  standalone: true,
  imports: [
    ToolbarModule, 
    TableModule, 
    ButtonModule, 
    InputTextModule, 
    DialogModule, 
    DropdownModule, 
    FormsModule, 
    ReactiveFormsModule,
    ToastModule,
    InputMaskModule,
    KeyFilterModule,
    TooltipModule
],
  templateUrl: './system-users.component.html',
  providers:[MessageService],
  styleUrl: './system-users.component.scss'
})
export class SystemUsersComponent implements OnInit{

  systemService = inject(SystemService)
  userService = inject(CatalogUsersService)

  visibleModal: boolean = false;
  deleteMod: boolean = false;
  editMod: boolean = false;

  cols: any[] = [];
	selectedProducts: any[] = [];
  products = []

  deleteRol: string | undefined;
  deleteName: string | undefined;


  roles = [
    {id:1, rol:"ADMINISTRADOR"},
    {id:2, rol:"VENTAS"},
    // {id:3, rol:"SISTEMA"},
    {id:3, rol:"COORDINADOR"},
    {id:6, rol:"AUDITOR MOVIL"},
    {id:8, rol:"AUDITOR WEB"}
  ]

  selectedRol: any;

  nuevoUsuarioForm: FormGroup;
  editForm: FormGroup; 

  deleteProd: any;

  mainId: number;

  editId: number | undefined;

  constructor(
    private formBuilder: FormBuilder,
		private messageService: MessageService,
  ){}
  ngOnInit(): void {

    this.getUsuarios();
    this.createForm();
    this.createEditForm();
    this.mainId = Number(sessionStorage.getItem("id"))

    this.cols = [
			{ field: 'nombre', header: 'Nombre' },
			{ field: 'Correo', header: 'Correo' },
			{ field: 'rol', header: 'Rol' },
	];

  }

  saveEdit(){
    this.editForm.controls['id'].setValue(this.editId)
    let saveValue = this.editForm.value;
    console.log(saveValue);

    if(!this.editForm.valid){
      
      const camposInvalidos = this.obtenerCamposInvalidos();
      console.log('Campos inválidos:', camposInvalidos);

      this.addWarning('warn','Favor de llenar todos los campos.','Advertencia')
      return;
    }

    this.userService.putUser(saveValue).subscribe({
      next: (data) => {
        this.addWarning('success','Usuario editado correctamente.','Notificación')
      },
      error: (errorData) => {
        console.log(errorData);
        
        this.addWarning('error', "Error al editar usuario", "Advertencia")

      },
      complete: () => {
        this.editMod = false;
        this.editId = undefined;
        this.editForm.reset();
        this.getUsuarios();
      }
    })
  }


  edit(product: any){
    this.editMod = true;
    this.editId = product.idUsuarioPlataforma;

  this.userService.getById(product.idUsuarioPlataforma).subscribe({
      next: (data) => {
        
        console.log(data);

        this.editForm.patchValue({
          nombre: product.nombre,
          email: product.email,
          telefono: data.telefono,
          iniciales: data.iniciales,
        })

      },
      error: (errorData) => {
        console.log(errorData);
        this.addWarning('error', "Error al obtener usuario", "Advertencia")
      },
      complete: () => {
        
      }
    }) 
  }

  obtenerCamposInvalidos() {
    const camposInvalidos = [];
    const controles = this.editForm.controls;

    for (const nombreControl in controles) {
      if (controles[nombreControl].invalid) {
        camposInvalidos.push(nombreControl);
      }
    }

    return camposInvalidos;
  }

  cancelEdit(){
    this.editMod = false;
    this.editId = undefined;
    this.editForm.reset();
  }

  createEditForm(): FormGroup{
		return this.editForm = this.formBuilder.group({
			id: [,],
      nombre: ['', Validators.required],
			email: ['',  Validators.required],
      telefono: ['',  Validators.required],
      iniciales:['',  Validators.required],
		});
	}

  cancelDelete(){
    this.deleteMod = false;
    this.deleteProd = undefined;
    this.deleteName = undefined;
    this.deleteRol = undefined;
  }

  deleteModal(product: any){
    this.deleteMod = true;
    this.deleteProd = product;

    this.deleteName = product.nombre;
    this.deleteRol = product.rol.descripcion;
  }

  deleteProduct(){
    this.userService.borradoLogico(this.deleteProd.idUsuarioPlataforma).subscribe({
      next: (data) => {
        this.addWarning('success','Usuario eliminado correctamente.','Notificación')
      },
      error: (errorData) => {
        console.log(errorData);
        
        this.addWarning('error', "Error al eliminiar usuario", "Advertencia")

      },
      complete: () => {
        this.getUsuarios();
        this.deleteMod = false;
        this.deleteProd = undefined;
        this.deleteName = undefined;
        this.deleteRol = undefined;
      }
    })
  }

  createForm(): FormGroup{
		return this.nuevoUsuarioForm = this.formBuilder.group({
			rol: [,],
			nombre: ['', Validators.required],
			email: ['', Validators.required],
      telefono: ['', Validators.required],
			password: ['', Validators.required],
      iniciales:['',Validators.required],
			passwordRepeat: ['',Validators.required],
		});
	}

  registrarUsuario(){

    if(this.nuevoUsuarioForm.valid && this.selectedRol){

      this.nuevoUsuarioForm.controls['rol'].setValue(this.selectedRol.id)
			let saveValue = this.nuevoUsuarioForm.value;

      if(saveValue.password !== saveValue.passwordRepeat){
				this.addWarning("warn",'Las contraseñas deben coincidir.', "Advertencia")
			}else{

        delete saveValue.passwordRepeat;

        JSON.stringify(saveValue);
			console.log(JSON.stringify(saveValue))
			this.userService.postNuevoAgente(saveValue).subscribe({
				next: (data) => {

          setTimeout(
            () => {
              this.getUsuarios()
            },
            1000 );

					this.addWarning('success','Usuario registrado correctamente.','Notificación')
				},
				error: (errorData) => {
					console.log(errorData);
					
					this.addWarning('error', errorData.error, "Advertencia")

				},
				complete: () => {
					this.nuevoUsuarioForm.reset();
					this.selectedRol = undefined;
					this.hideDialog();
				}
			})

      }
    }else{
      this.addWarning("warn",'Faltan datos por ingresar.', "Advertencia")
    }

  }

  addWarning(severity: string,message: string, resumen: string){
		this.messageService.add({ severity: severity, summary: resumen, 
		detail: message });
	}

  showDialog(): void{
    this.visibleModal = true;
  }

  hideDialog(): void{
    this.visibleModal = false;
  }

  getUsuarios(){
    this.systemService.getUsuariosSistema().subscribe((data:any)=>{
      this.products = data;
      this.products.sort((a, b) => (a.id < b.id ? -1 : 1));
    })
  }

}
