import { Tooltip } from 'chart.js';
import { Product } from 'src/app/demo/api/product';
import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { SystemService } from '../../service/system.service';
import { DatePipe } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-penalty-reason',
  standalone: true,
  imports: [
    ToolbarModule, 
    TableModule, 
    ButtonModule, 
    InputTextModule, 
    DialogModule, 
    DropdownModule, 
    FormsModule, 
    ReactiveFormsModule,
    ToastModule,
    TooltipModule
  ],
  templateUrl: './penalty-reason.component.html',
  providers:[MessageService, DatePipe],
  styleUrl: './penalty-reason.component.scss'
})
export class PenaltyReasonComponent implements OnInit{

  systemService = inject(SystemService)

  products = []
  cols: any[] = [];
  selectedProducts: any[] = [];

  motivoForm: FormGroup;

  edit: boolean = false;
  visibleModal: boolean = false;
  delete: boolean = false;

  editId: number | undefined;
  deleteId: number | undefined;

  editMotivo: string | undefined;
  deleteMotivo: string | undefined;
  header: string;
  
  constructor(
    private datePipe:DatePipe,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
  ){}
  ngOnInit(){
    this.getMotivosMulta()
    this.createForm();

    this.cols = [
			{ field: 'motivo', header: 'Motivo' },
			{ field: 'fecha', header: 'Fecha' },
	  ];
  }

  registrarMotivo(){
    if(this.motivoForm.valid){

      let saveValue = this.motivoForm.value

      this.systemService.postMotivoMulta(saveValue).subscribe({
        next:(data)=>{

          this.addWarning("success", "Motivo registrado correctamente", "Mensaje")

          setTimeout(()=>{ 
            this.getMotivosMulta();
          },1000)

          this.hideDialog()

        },error:(error)=>{
          console.log(error)

          this.addWarning("error",'Error al registrar el motivo de multa.', "Error")

        }
      });
    }else{
      this.addWarning("warn",'Falta ingresar motivo.', "Advertencia")
    }
  }


  addWarning(severity: string,message: string, resumen: string){
		this.messageService.add({ severity: severity, summary: resumen, 
		detail: message });
	}

  createForm(): FormGroup{
		return this.motivoForm = this.formBuilder.group({
			motivo: ['',Validators.required],
		});
	}

  getMotivosMulta(){
    this.systemService.getMotivosMulta().subscribe((data:any)=>{
      this.products = data.map((item: any) => {
        return {
          ...item,
          fecha: this.datePipe.transform(item.fecha, 'dd/MM/yyyy HH:mm')
        };
      });

      this.products.sort((a, b) => (a.id < b.id ? -1 : 1));
    })
  }

  editDialog(motivo:any){ 
    console.log(motivo)
    this.edit = true;
    this.editId = motivo.id;
    this.editMotivo = motivo.motivo;

    this.motivoForm.patchValue({
      motivo: motivo.motivo
    })

    this.showDialog();
  }

  updateMotivo(){

    if(this.motivoForm.invalid){
      this.addWarning("warn",'Falta ingresar motivo.', "Advertencia")
      return;
    }

    let saveValue = this.motivoForm.value

    this.systemService.putMotivoMulta(this.editId, saveValue).subscribe({
      next:(data)=>{
        this.addWarning("success", "Motivo actualizado correctamente", "Mensaje")

        setTimeout(()=>{ 
          this.getMotivosMulta();
        },1000)

        this.hideDialog()
      },error:(error)=>{
        console.log(error)
        this.addWarning("error",'Error al actualizar el motivo de multa.', "Error")
      }
    });
  }

  deleteMotiv(){
    this.systemService.deleteMotivoMulta(this.deleteId).subscribe({
      next:(data)=>{
        
        this.addWarning("success", "Motivo eliminado correctamente", "Mensaje")

        setTimeout(()=>{ 
          this.getMotivosMulta();
        },1000)

        this.hideDeleteDialog()
      },error:(error)=>{
        console.log(error)
        this.addWarning("error",'Error al eliminar el motivo de multa.', "Error")
      }
    });
  }

  deleteDialog(product:any){
    this.delete = true;
    this.deleteId = product.id;
    this.deleteMotivo = product.motivo;
  }

  hideDeleteDialog(){
    this.delete = false;
    this.deleteId = undefined;
    this.deleteMotivo = undefined;
  }

  showDialog(){
    this.edit == true ? this.header = "Editar Motivo" : this.header = "Registrar Motivo";
    this.visibleModal = true;
  }

  hideDialog(): void{
    this.visibleModal = false;
    this.edit = false;
    this.editId = undefined;
    this.editMotivo = undefined;
    this.motivoForm.reset();
  }
}
