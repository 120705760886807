<div class="grid">
	<div class="col-12">

		<div class="card px-6 py-6"> 

			<ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
				<li>
					<a class="text-500 no-underline line-height-3 cursor-pointer">Home</a>
				</li>
				<li class="px-2">
					<i class="pi pi-angle-right text-500 line-height-3"></i>
				</li>
				<li>
					<a class="text-500 no-underline line-height-3 cursor-pointer">Auditor Web</a>
				</li>
				<li class="px-2">
					<i class="pi pi-angle-right text-500 line-height-3"></i>
				</li>
				<li>
					<span class="text-900 line-height-3">Bitacora de vendedores</span>
				</li>
			</ul>
			<br>
                  <p-toast></p-toast>
			<p-toolbar styleClass="mb-4">
				<ng-template pTemplate="left">

					<span class="block mt-2 md:mt-0 p-input-icon-left">
						<i class="pi pi-search"></i>
						<input pInputText type="text" (input)="dtBitacora.filterGlobal($event.target.value, 'contains')" placeholder="Buscar..."  class="w-full sm:w-auto"/>
					</span>
					<span class="block ml-3 mt-2 md:mt-0 p-input-icon-left">
						<i class="pi pi-calendar"></i>
						<p-calendar clear
						placeholder="Fecha" 
						[(ngModel)]="rangeDates" 
						selectionMode="range" 
						dataType="string"
						dateFormat="dd/mm/yy"
						[showButtonBar]="true"
						(onMonthChange)="changeMonth($event)"
						(onSelect)="filterProducts($event)"
						(onClearClick)="clearProducts()"
						(onTodayClick)="todayProducts()"/>
					</span>
						<!-- <div class="my-2">
								<button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success mr-2" (click)="openNew()"></button>
								<button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger" (click)="deleteSelectedProducts()" [disabled]="!selectedProducts || !selectedProducts.length"></button>
						</div> -->
				</ng-template>
		</p-toolbar>

        <p-table #dtBitacora [value]="bitacora" responsiveLayout="scroll" [rows]="10" [globalFilterFields]="['campo','fecha','usuario','hora']" 
		[paginator]="true" [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true" currentPageReportTemplate="Monstrando {first} a {last} de {totalRecords} registros" 
		selectionMode="multiple" [rowHover]="true" dataKey="id">

		<ng-template pTemplate="header">
			<tr>
					<th pSortableColumn="fecha">Fecha<p-sortIcon field="fecha"></p-sortIcon></th>
                    <th>Hora</th>
					<th>Usuario</th>
					<th>Movimiento</th>
					<th>Vendedor</th>
                    <th>Detalle</th>
            </tr>
	</ng-template>

	<ng-template pTemplate="body" let-product>
		<tr>
				<td style="width:5%">
						{{product.fecha}}
				</td>

                <td style="width:5%">
                        {{product.hora}}
                </td>

                <td style="width:10%">
                    {{product.usuario}}
                </td>

                <td style="width:8%">
                    {{product.movimiento}}
                </td>

                <td style="width:10%">
                    {{product.vendedor.nombre}}
                </td>

                <td style="width:2%;"> 
                    <button  pTooltip="Detalle del vendedor"  pButton pRipple icon="pi pi-eye" class="p-button-rounded p-button-info mr-5" (click)="showDialog(product)"></button>
                </td>
		</tr>
</ng-template>

		</p-table>

		</div>
	</div>
</div>

<p-dialog header="Vendedor" [modal]="true" (onHide)="editMod=false" [(visible)]="editMod" [style]="{ width: '25rem' }">
	<!-- <span class="p-text-secondary block mb-5">El siguiente será eliminado:</span> -->
	<form [formGroup]="editForm">

		<div class="flex align-items-center gap-3 mb-5">
			<label class="font-semibold w-6rem">Correo</label>
			<input maxlength="30" formControlName="email" pInputText id="email" type="email" [email]="true" class="flex-auto" autocomplete="off" readonly/>
			@if (editForm.get('email').invalid && editForm.get('email').touched) {
				@if (editForm.get('email').errors.required) {
					<small>El correo es requerido</small>
				}
				@if (editForm.get('email').errors.email) {
					<small>El correo es invalido</small>
				}
			}
		</div>
		<div class="flex align-items-center gap-3 mb-5">
			<label class="font-semibold w-6rem">Nombre</label>
			<input maxlength="20" formControlName="nombre" pInputText   id="email" class="flex-auto" autocomplete="off" readonly/>
		</div>

		<div class="flex align-items-center gap-3 mb-5">
			<label class="font-semibold w-6rem">RFC</label>
			<input maxlength="13"  style="text-transform:uppercase" formControlName="rfc" pInputText  pKeyFilter="alphanum" id="email" class="flex-auto" autocomplete="off"
            readonly/>
		</div>

		<div class="flex align-items-center gap-3 mb-5">
			<label class="font-semibold w-6rem">Alias</label>
			<input maxlength="20" formControlName="alias" pInputText   id="email" class="flex-auto" autocomplete="off" readonly/>
		</div>

        <div class="flex align-items-center gap-3 mb-5">
			<label class="font-semibold w-6rem">Tipo de persona</label>
			<input maxlength="20" formControlName="tipo_persona" pInputText   id="email" class="flex-auto" autocomplete="off" readonly/>
		</div>

		<!-- <div class="flex align-items-center gap-3 mb-5">
			<label htmlFor="amount">Tipo persona</label>
			<p-dropdown [(ngModel)]="selectedTipo" [ngModelOptions]="{standalone: true}" [options]="tipos" optionLabel="tipo" [showClear]="true" placeholder="Selecciona un tipo"></p-dropdown>
		</div> -->

		<!-- <div class="flex align-items-center gap-3 mb-5">
			<label class="font-semibold w-6rem">Nombre</label>
			<input maxlength="25" formControlName="nombre" pInputText pKeyFilter="alpha"  id="email" class="flex-auto" autocomplete="off"/>
		</div> -->

		<!-- <div class="flex align-items-center gap-3 mb-5">
			<label class="font-semibold w-6rem">Calle</label>
			<input maxlength="25" formControlName="calle" pInputText pKeyFilter="alpha" class="flex-auto" autocomplete="off"/>
		</div>
		<div class="flex align-items-center gap-3 mb-5">
			<label class="font-semibold w-6rem">Número</label>
			<input maxlength="4"  formControlName="numero" pInputText pKeyFilter="int"  id="email" class="flex-auto" autocomplete="off"/>
		</div>
		<div class="flex align-items-center gap-3 mb-5">
			<label class="font-semibold w-6rem">Colonia</label>
			<input maxlength="25" formControlName="colonia" pInputText pKeyFilter="alpha"  id="email" class="flex-auto" autocomplete="off"/>
		</div>
		<div class="flex align-items-center gap-3 mb-5">
			<label class="font-semibold w-6rem">Código postal</label>
			<input maxlength="5" formControlName="cp" pInputText pKeyFilter="int"  id="email" class="flex-auto" autocomplete="off"/>
		</div>
		<div class="flex align-items-center gap-3 mb-5">
			<label class="font-semibold w-6rem">Municipio</label>
			<input maxlength="30" formControlName="municipio" pInputText pKeyFilter="alpha"  id="email" class="flex-auto" autocomplete="off"/>
		</div> -->

        <div class="flex align-items-center gap-3 mb-5">
			<label class="font-semibold w-6rem">Dirección</label>
			<input maxlength="20" formControlName="direccion" pInputText   id="email" class="flex-auto" autocomplete="off" readonly/>
		</div>

        <div class="flex align-items-center gap-3 mb-5">
			<label class="font-semibold w-6rem">Telefono</label>
			<input maxlength="20" formControlName="telefono" pInputText   id="email" class="flex-auto" autocomplete="off" readonly/>
		</div>

	</form>
	<div class="flex justify-content-end gap-2">
		<p-button label="Cerrar" severity="secondary" (click)="editMod=false" />
		<!-- <p-button label="Confirmar" (click)="saveEdit()"/> -->
	</div>
</p-dialog>