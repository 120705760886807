import { Component, OnInit, inject } from '@angular/core';
import { ReactiveFormsModule, FormsModule, FormGroup, FormBuilder } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { FieldsetModule } from 'primeng/fieldset';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ToastModule } from 'primeng/toast';
import { SystemService } from '../../service/system.service';

interface Parametros{
  // municipio: string;
  // eslogan: string;
  // letrero: string;
  fundamento: string;
  footerMulta: string;
  footerSegundaLinea: string;
  costoMulta: number;
  costoMaximoMulta : number;
}

@Component({
  selector: 'app-penalty-params',
  standalone: true,
  imports: [
    FieldsetModule, 
    InputTextModule, 
    ButtonModule, 
    DividerModule,
    InputTextareaModule,
    ToastModule, 
    ReactiveFormsModule,
    FormsModule,
    InputNumberModule
  ],
  providers:[MessageService],
  templateUrl: './penalty-params.component.html',
  styleUrl: './penalty-params.component.scss'
})
export class PenaltyParamsComponent implements OnInit{
  systemService = inject(SystemService)

  municipio: string = '';
  eslogan: string = '';
  letrero: string = '';
  fundamento: string = '';
  footerMulta: string = '';
  userName: string;
  footerSegundaLinea: string = '';

  costoMulta: number = 0;
  costoMaximoMulta: number = 0;

  parametrosForm: FormGroup;

  currentValues: Parametros;

  textoMaxlength: number = 35;

  fundamentoMaxlength: number = 2500;

  constructor(
    private formBuilder: FormBuilder,
		private messageService: MessageService,
  ){}
  ngOnInit(){
    this.getParametrosMulta();
    this.createForm();
    this.getUserData();
  }

  updateValues(){

    if(this.costoMulta>this.costoMaximoMulta){

      this.addWarning('error',`El costo de la multa debe ser menor 
      al costo del código hacendario`,'Advertencia')

    }else{

    let saveValue;
    
    // this.parametrosForm.controls['municipio'].setValue(this.municipio)
    // this.parametrosForm.controls['eslogan'].setValue(this.eslogan)
    // this.parametrosForm.controls['letrero'].setValue(this.letrero)
    this.parametrosForm.controls['fundamento'].setValue(this.fundamento)
    this.parametrosForm.controls['footerMulta'].setValue(this.footerMulta)
    this.parametrosForm.controls['costoMulta'].setValue(this.costoMulta)
    this.parametrosForm.controls['costoMaximoMulta'].setValue(this.costoMaximoMulta)
    this.parametrosForm.controls['footerSegundaLinea'].setValue(this.footerSegundaLinea)

    saveValue = this.parametrosForm.value

    let modifiedFields = this.getModifiedFields(this.currentValues, saveValue);
    console.log("modifiedFields",modifiedFields)

    this.systemService.updateParametrosMulta(saveValue).subscribe({
      next: (data)=>{

        this.addWarning('success','Datos actualizados correctamente','Notificación')
        setTimeout(() => {
          this.getParametrosMulta();
        }, 1000);

        this.systemService.postModifiedFields(modifiedFields).subscribe(
          (data) => {
            console.log("modifiedFields",data)
          }
        )

      }, error: (error)=>{
        this.addWarning('error','Error al actualizar parametros','Advertencia')
      }
    })

    }
  }

  createForm(): FormGroup{
		return this.parametrosForm = this.formBuilder.group({
			// municipio: ['',],
      // eslogan: ['',],
			// letrero: ['',],
			fundamento: ['',],
			footerMulta: ['',],
			costoMulta: [0, ],
      costoMaximoMulta:[0,],
      footerSegundaLinea: ['',]
		});
	}

  getParametrosMulta(){
    this.systemService.getParametrosMulta().subscribe({
      next: (data : Parametros) =>{

        // this.municipio = data.municipio
        // this.eslogan = data.eslogan
        // this.letrero = data.letrero
        this.fundamento = data.fundamento
        this.footerMulta = data.footerMulta
        this.costoMulta = data.costoMulta
        this.costoMaximoMulta = data.costoMaximoMulta
        this.footerSegundaLinea = data.footerSegundaLinea

        this.currentValues = data;

          // console.log(this.footerSegundalinea)
      }, error: (error) =>{
        this.addWarning('error','Error al obtener los parametros','Advertencia')
      }
    })
  }

  addWarning(severity: string,message: string, resumen: string){
		this.messageService.add({ severity: severity, summary: resumen, 
		detail: message });
	}

  getModifiedFields(currentValues: any, updateValues: any): any[] {
    const modifiedFields: any[] = [];

    for (const key in currentValues) {
        if (currentValues.hasOwnProperty(key) && updateValues.hasOwnProperty(key)) {
            if (currentValues[key] !== updateValues[key]) {
                modifiedFields.push({
                    campo: key,
                    valor_anterior: currentValues[key],
                    valor_actual: updateValues[key],
                    usuario: this.userName
                });
            }
        }
    }

    return modifiedFields;
}

getUserData(){
  let id = sessionStorage.getItem('id');
  this.systemService.getUsuario(Number(id)).subscribe({
    next: (data) => {
      this.userName = data.nombre
    }
  })
}
}
