import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API_URLS } from "src/app/auth/shared/models/apiURL";
import { environment } from "src/environments/environment";

@Injectable({
      providedIn: 'root'
})
export class PaymentService {
      constructor(private http: HttpClient) { 

      }

      httpOptions = {
            headers: new HttpHeaders({
                  'Content-Type': 'application/json'
            })
      };

      public getToken(){
            return sessionStorage.getItem("token");
      }

      getMultasTotal(month:number, year:number){
            this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
            return this.http.get<any>(environment.API_URL + API_URLS.GET_MULTAS_TOTAL +
                  `?mes=${month}&anio=${year}`, this.httpOptions);
      }

      pagarMulta(id: number, folio:string = "NA"){
            this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
            return this.http.put<any>(environment.API_URL+API_URLS.PUT_PAGAR_MULTA+id+"/"+folio,{},this.httpOptions);
      }

      postBitacoraMulta(bitacora:any){
            this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
            return this.http.post<any>(environment.API_URL+API_URLS.POST_BITACORA_MULTAS,bitacora,this.httpOptions);
      }

      getImagenesMulta(id:number){
            this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
            return this.http.get<any>(environment.API_URL + API_URLS.GET_IMG_MULTA + id, this.httpOptions);
      }
}