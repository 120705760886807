import { OnInit, inject } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];

    activeItem: MenuItem | undefined;
    router = inject(Router)

    constructor(public layoutService: LayoutService) { }

    ngOnInit() {

        let rol = sessionStorage.getItem("rol");

        switch(rol){
            case 'ACCESO_MAESTRO':
               
            this.model = [
                {
                    label: 'ACCESO MAESTRO',
                    items: [
                        { label: 'Usuarios plataforma', icon: 'pi pi-fw pi-users', routerLink: ['/home/usuarios-sistema'] },
                        { label: 'Usuarios', icon: 'pi pi-fw pi-id-card', routerLink: ['/home/agentes'] },
                        { label: 'Venta de saldo', icon: 'pi pi-fw pi-ticket', routerLink: ['/home/venta-ticket'] },
                        { label: 'Saldo vendedores', icon: 'pi pi-fw pi-user', routerLink: ['/home/usuarios-ticket'] },
                        { label: 'Actualización de multas', icon: 'pi pi-fw pi-wallet', routerLink: ['/home/cobro-multas']},
                        { label: 'Configuración', icon: 'pi pi-fw pi-cog', routerLink: ['/home/configuracion'] },
                        { label: 'Parametros', icon: 'pi pi-fw pi-money-bill', routerLink: ['/home/parametros'] },
                        { label: 'Parametros ticket venta', icon: 'pi pi-fw pi-info-circle', routerLink: ['/home/parametros-ticket']},
                        { label: 'Parametros ticket multa', icon: 'pi pi-fw pi-info-circle', routerLink: ['/home/parametros-multa'] },
                        { label: 'Motivos de multa', icon: 'pi pi-fw pi-book', routerLink: ['/home/motivos-multa'] },
                        { label: 'Configuracion de parametros', icon: 'pi pi-fw pi-cog', routerLink: ['/home/configuracion-parametros'] },
                    ]
                },
            ];

            this.router.navigate(['/home/usuarios-sistema'])

                break;
            case 'VENTAS':
                
            this.model = [
                {
                    label: 'Ventas',
                    items: [
                        { label: 'Registro de venta de saldo', icon: 'pi pi-fw pi-ticket', routerLink: ['/home/venta-ticket'] },
                        { label: 'Saldo vendedores', icon: 'pi pi-fw pi-user', routerLink: ['/home/usuarios-ticket'] },
                        { label: 'Historial de ventas', icon: 'pi pi-fw pi-history', routerLink: ['/home/historial-ventas']},
                        { label: 'Actualización de multas', icon: 'pi pi-fw pi-wallet', routerLink: ['/home/cobro-multas']},
                        { label: 'Vendedores', icon: 'pi pi-fw pi-id-card', routerLink: ['/home/agentes'] },
                    ]
                },
            ];

            this.router.navigate(['/home/venta-ticket'])

                break;
            case 'SISTEMA':
                
            this.model = [
                {
                    label:'Coordinador Municipal',
                    items:[
                        // { label: 'Usuarios de sistema', icon: 'pi pi-fw pi-users', routerLink: ['/home/usuarios-sistema'] },
                        { label: 'Parametros de ventas', icon: 'pi pi-fw pi-money-bill', routerLink: ['/home/parametros'] },
                        { label: 'Parametros ticket venta', icon: 'pi pi-fw pi-ticket', routerLink: ['/home/parametros-ticket'] },
                        { label: 'Parametros ticket multa', icon: 'pi pi-fw pi-info-circle', routerLink: ['/home/parametros-multa'] },
                        // { label: 'Auditoria', icon: 'pi pi-fw pi-info-circle', routerLink: ['/home/auditoria'] },
                        { label: 'Motivos de multa', icon: 'pi pi-fw pi-book', routerLink: ['/home/motivos-multa'] },
                    ]
                }
            ];

            this.router.navigate(['/home/parametros'])

                break;
            case 'ADMINISTRADOR':
                
            this.model = [
                {
                    label:'Administrador general',
                    items:[
                        { label: 'Usuarios platafroma', icon: 'pi pi-fw pi-users', routerLink: ['/home/usuarios-sistema'] },
                        { label: 'Agentes de multas', icon: 'pi pi-fw pi-id-card', routerLink: ['/home/agentes-multas'] },
                        { label: 'Configuración', icon: 'pi pi-fw pi-cog', routerLink: ['/home/configuracion'] },
                        { label: 'Configuración de parametros código hacendario', icon: 'pi pi-fw pi-info-circle', routerLink: ['/home/configuracion-parametros'] },
                        { label: 'Configuración de zona horaria', icon: 'pi pi-fw pi-clock', routerLink: ['/home/configuracion-tiempo'] },
                    ]
                }
                
            ];

            this.router.navigate(['/home/usuarios-sistema'])

            break;

            case 'AUDITOR_WEB':
                
            this.model = [
                {
                    label:'Auditor Web',
                    items:[
                        { label: 'Reportes de auditoria', icon: 'pi pi-fw pi-users', routerLink: ['/home/auditorias'] },
                        { label: 'Bitacora de parametros de ventas', icon: 'pi pi-fw pi-info-circle', routerLink: ['/home/bitacora-parametros'] },
                        { label: 'Bitacora de parametros ticket venta', icon: 'pi pi-fw pi-info-circle', routerLink: ['/home/bitacora-ticket-venta'] },
                        { label: 'Bitacora de parametros ticket multa', icon: 'pi pi-fw pi-info-circle', routerLink: ['/home/bitacora-ticket-multa'] },
                        { label: 'Bitacora de ventas', icon: 'pi pi-fw pi-info-circle', routerLink: ['/home/bitacora-ventas']},
                        { label: 'Bitacora de multas', icon: 'pi pi-fw pi-info-circle', routerLink: ['/home/bitacora-multas']},
                        { label: 'Bitacora de vendedores', icon: 'pi pi-fw pi-info-circle', routerLink: ['/home/bitacora-vendedores'] },
                    ]
                }
            ];

            this.router.navigate(['/home/auditorias'])
            break;

            case 'AUDITOR':
                this.router.navigate([''])
                break;
            
            case 'AGENTE_TICKETS':
                this.router.navigate([''])
                break;

            case 'AGENTE_MULTAS':
                this.router.navigate([''])
                break;
        }
    }
}
