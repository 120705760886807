<div class="grid">
	<div class="col-12">

		<div class="card px-6 py-6"> 

			<ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
				<li>
					<a class="text-500 no-underline line-height-3 cursor-pointer">Home</a>
				</li>
				<li class="px-2">
					<i class="pi pi-angle-right text-500 line-height-3"></i>
				</li>
				<li>
					<a class="text-500 no-underline line-height-3 cursor-pointer">Auditor Web</a>
				</li>
				<li class="px-2">
					<i class="pi pi-angle-right text-500 line-height-3"></i>
				</li>
				<li>
					<span class="text-900 line-height-3">Reporte de auditoria</span>
				</li>
			</ul>
			<br>
            <p-toast></p-toast>
			<p-toolbar styleClass="mb-4">
				<ng-template pTemplate="left">

					<span class="block mt-2 md:mt-0 p-input-icon-left">
						<i class="pi pi-search"></i>
						<input pInputText type="text" (input)="dtAuditoria.filterGlobal($event.target.value, 'contains')" placeholder="Buscar..."  class="w-full sm:w-auto"/>
						
						<!-- <p-calendar class="ml-3" placeholder="Fecha..." selectionMode="range" [(ngModel)]="rangeDates"  (onSelect)="dtAuditoria.filter(rangeDates,'release_datetime', 'dateRangeFilter')" ></p-calendar> -->
					
					</span>

					<span class="block ml-3 mt-2 md:mt-0 p-input-icon-left">
						<i class="pi pi-calendar"></i>
						<p-calendar clear
						placeholder="Fecha" 
						[(ngModel)]="rangeDates" 
						selectionMode="range" 
						dataType="string"
						dateFormat="dd/mm/yy"
						[showButtonBar]="true"
						(onSelect)="filterProducts($event)"
						(onMonthChange)="changeMonth($event)"
						(onClearClick)="clearProducts()"
						(onTodayClick)="todayProducts()"/>
					</span>
						<!-- <div class="my-2">
								<button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success mr-2" (click)="openNew()"></button>
								<button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger" (click)="deleteSelectedProducts()" [disabled]="!selectedProducts || !selectedProducts.length"></button>
						</div> -->
				</ng-template>
		</p-toolbar>

        <p-table #dtAuditoria [value]="products" [columns]="cols" responsiveLayout="scroll" [rows]="10" [globalFilterFields]="['folio','nombre','ubicacion_inicio', 'ubicacion_fin','auditor.nombre','fecha']"
		[paginator]="true" [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true" currentPageReportTemplate="Monstrando {first} a {last} de {totalRecords} registros" 
		[(selection)]="selectedProducts" selectionMode="multiple" [rowHover]="true" dataKey="id">

		<ng-template pTemplate="header">
			<tr>
					<th>Folio</th>
					<th>Nombre</th>
					<th pSortableColumn="fecha">Fecha<p-sortIcon field="fecha"></p-sortIcon></th>
					<th>Inicio</th>
					<th>Fin</th>
					<th>Auditor</th>
					<th>Tickets validados</th>
                    <th>Accion</th>
			</tr>
	</ng-template>

	<ng-template pTemplate="body" let-product>
		<tr>
				<td style="width:10%;">
						{{product.folio}}
				</td>

                <td style="width:15%;">
                        {{product.nombre}}
                </td>

                <td style="width:14%;">
                    @if(product.fecha){
                        {{product.fecha}}
                    }
                    @else {
                        Sin fecha}

                </td>

				<td style="width:11%;">
                    @if(product.inicio){
                        {{product.inicio}}
                    }
                    @else {
                        Sin iniciar}

                </td>

				<td style="width:11%;">
                    @if(product.fin){
                        {{product.fin}}
                    }
                    @else {
                        Sin finalizar}

                </td>

                <td style="width:14%;">
                    {{product.auditor.nombre}}
                </td>

				<td style="width:8%; min-width:5rem;">
					<div class="flex justify-content-end">
								<button  pTooltip="Tickets validados por el auditor móvil"  pButton pRipple icon="pi pi-qrcode" class="p-button-rounded p-button-info mr-5" (click)="getTicketsByAuditoria(product.id)"></button>
					</div>
				</td>

				<td style="width:8%; min-width:5rem;">
					<div class="flex justify-content-end">
								<button  pTooltip="Ver observación e incidencias"  pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-info mr-5" (click)="showIncidencias(product)"></button>
					</div>
				</td>
		</tr>
</ng-template>

		</p-table>

		</div>
	</div>
</div>


<p-dialog header="Observación" [(visible)]="visibleModal" [modal]="true" [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">
        <div class="p-fluid grid">
            <div class="field col-12">
                  <textarea 
                  [ngModelOptions]="{standalone: true}"  [(ngModel)]="selectedObs"
                    rows="5"
                    cols="30" 
                    disabled
                    pInputTextarea>
                </textarea>
            </div>
        </div>

		<h5>Incidencias</h5>

        <p-table [value]="incidencias" [tableStyle]="{ 'min-width': '50rem' }">
            <ng-template pTemplate="header">
                <tr>
                    <th>Motivo</th>
					<th>Ubicación</th>
                    <th>Hora</th>
                    <th>Placa</th>
                    <th>Marca y color</th>
                    <th>Tipo</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product>
                <tr>
                    <td>{{ product.motivo }}</td>
                    <td>{{ product.ubicacion }}</td>
					<td>{{ product.hora }}</td>
                    <td>{{ product.placa }}</td>
                    <td>{{ product.modelo }}</td>
                    <td>{{ product.tipo }}</td>
                </tr>
            </ng-template>
        </p-table>
        
</p-dialog>


<p-dialog header="Tickets validados durante la auditoria" (onHide)="visibleTicket = false" [(visible)]="visibleTicket" [modal]="true" [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">

	<p-table [value]="ticketsAuditoria" [tableStyle]="{ 'min-width': '30rem' }">
		<ng-template pTemplate="header">
			<tr>
				<th>Fecha de validación</th>
				<th>Cantidad</th>
				<th>Tipo</th>
				<th>Hora inicio</th>
				<th>Fecha fin</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-product>
			<tr [ngClass]="{'bg-red-100': product.vencido}">
				<td>{{product.fecha_validacion}} {{ product.hora_validacion }}</td>
				<td>${{ product.cantidad }}</td>
				<td>{{ product.tipo }}</td>
				<td>{{ product.hora_pago }}</td>
				<td> {{ product.hora_vence }}</td>
			</tr>
		</ng-template>
	</p-table>
	
</p-dialog>