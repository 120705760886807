import { filter } from 'rxjs';
import { Product } from 'src/app/demo/api/product';
import { PaymentService } from './../../service/penalty-payment.service';
import { Component, OnInit, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { ToolbarModule } from 'primeng/toolbar';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { AuditService } from 'src/app/audit/service/audit.service';
import { TooltipModule } from 'primeng/tooltip';
import { CalendarModule } from 'primeng/calendar';
import { GalleriaModule } from 'primeng/galleria';

@Component({
  selector: 'app-penalty-payment',
  standalone: true,
  imports: [ToolbarModule, TableModule, ButtonModule, InputTextModule, DialogModule, DropdownModule, FormsModule, ToastModule,
    TooltipModule, CalendarModule, ReactiveFormsModule, GalleriaModule
  ],
  providers:[MessageService],
  templateUrl: './penalty-payment.component.html',
  styleUrl: './penalty-payment.component.scss',
})
export class PenaltyPaymentComponent implements OnInit{

  paymentService = inject(PaymentService);

  visibleModal: boolean = false;

  cols: any[] = [];
	selectedProducts: any[] = [];
  products = [
	]

  roles = [
    {id:1, rol:"Administrador"},
    {id:2, rol:"Ventas"},
    {id:3, rol:"Sistema"}
  ]

  selectedRol: any;

  multas = [
    {id:1, tipo:"Candado"},
    {id:2, tipo:"Fuga"}
  ]

  selectedMulta = {id:1, tipo:"Candado"};

  vehiculo: string | undefined
  tipoMulta: string | undefined
  agente: string | undefined
  documento:string | undefined

  monto: number | undefined
  idMulta: number | undefined

  pagada: boolean | undefined

  folio: string | undefined

  rangeDates = []; 

  originalProducts = []

  imageModal:boolean = false;
  imagenesMulta:any[] | undefined;

  responsiveOptions: any[] = [
    {
        breakpoint: '1024px',
        numVisible: 5
    },
    {
        breakpoint: '768px',
        numVisible: 3
    },
    {
        breakpoint: '560px',
        numVisible: 1
    }
];

  constructor(private messageService: MessageService,){}
  
  ngOnInit(){

    const now = new Date();
		const month = now.getMonth() + 1; // getMonth() returns month index starting from 0
		const year = now.getFullYear();

    this.getMultas(month, year);
    this.cols = [
			{ field: 'nombre', header: 'Nombre' },
			{ field: 'Correo', header: 'Correo' },
			{ field: 'rol', header: 'Rol' },
	];

  }

  async getCurrentMonthAndYear(): Promise<{ month: number; year: number; }> {
		const now = new Date();
		const month = now.getMonth() + 1; // getMonth() returns month index starting from 0
		const year = now.getFullYear();
	
		return { month, year };
	}

  closeImgDialog(){
    this.imageModal = false; 
    this.imagenesMulta = undefined;
  }

  getImagenesMulta(id:number){
    this.paymentService.getImagenesMulta(id).subscribe({
      next: (data) =>{
        console.log(data)
        if(data.length !== 0){
          this.imagenesMulta = data
          this.imageModal = true;
        }else{
          this.addWarning("error", "No se registraron fotografías para esta multa","Advertencia")
        }
        // this.imagenesMulta = data;
      }, error: (errorData) =>{ 
        this.addWarning("error", "Error al obtener fotografias","Error")
    }
  })
  }

  showDialog(product: any): void{
    console.log(product);

    this.vehiculo = `${product?.placa} ${product.marca} ${product.modelo}`;
    this.tipoMulta = product.tipo;
    this.documento = product.documento;
    this.monto = product.monto;
    this.agente = product.agente.nombre;
    this.idMulta = product.id
    this.visibleModal = true;
    this.pagada = product.pagada;
    this.folio = product.folio_municipio;

    // console.log(this.documento)
  }

  hideDialog(): void{
    this.visibleModal = false;
    this.vehiculo = undefined
    this.tipoMulta = undefined
    this.monto = undefined
    this.documento = undefined
    this.agente = undefined
    this.idMulta = undefined
    this.pagada = undefined
    this.folio = undefined
  }

  async getMultas(month:number,year:number){
    this.paymentService.getMultasTotal(month,year).subscribe((data)=>{
      this.products = data;
      this.products.sort((a, b) => (a.id > b.id ? -1 : 1));
      this.originalProducts = this.products;
    })
  }

  pagarMulta(){
    let folioPago = this.folio;

    if(!folioPago){
      this.addWarning("error", "Favor de ingresar un folio de pago","Error")
      return;
    }

    this.paymentService.pagarMulta(this.idMulta, folioPago).subscribe({
      next: async (data) =>{

        let bitacora = {
          usuario:  sessionStorage.getItem('nombre'),
          movimiento: "ACTUALIZACIÓN",
          multa: this.idMulta
        }

        this.paymentService.postBitacoraMulta(bitacora).subscribe((data)=>{console.log(data)})

        this.addWarning("success", "Multa pagada corrrectamente","Pago")
        const { month, year } = await this.getCurrentMonthAndYear();
        this.getMultas(month, year);
        this.hideDialog();
        this.folio = undefined;
      }, error: (errorData) =>{ 
        console.log(errorData)
        this.addWarning("error", errorData.error.message,"Error")
      }
    })
  }

  addWarning(severity: string,message: string, resumen: string){
		this.messageService.add({ severity: severity, summary: resumen, 
		detail: message });
	}

  filterProducts(avent:any): void {

		const [startDate, endDate] = this.rangeDates.map(this.parseDate);

		console.log(startDate)
		console.log(endDate)

		const filteredProducts = this.originalProducts.filter(product => {
			const productDate = this.parseDate(product.fechaCandado);
			return productDate >= startDate && productDate <= endDate;
		});

		this.products = filteredProducts; 
	}

	clearProducts(){
		this.products = this.originalProducts;
	}

	async todayProducts(){
    const { month, year } = await this.getCurrentMonthAndYear();
    await this.getMultas(month, year);

    setTimeout(
			() => {
			this.filterToday()
			},
			500 );


	}

  async filterToday(){
		let today = this.rangeDates
		today[1] = this.rangeDates[0];

		const [startDate, endDate] = today.map(this.parseDate);
		
		const filteredProducts = this.originalProducts.filter(product => {
			const productDate = this.parseDate(product.fechaCandado);
			return productDate >= startDate && productDate <= endDate;
		});

		this.products = filteredProducts; 
  }

  changeMonth(event:any){

		console.log(event)

		let month = event.month;
		let year = event.year;

		this.getMultas(month, year);
	}
  
	parseDate(dateString: string): Date {
		const [day, month, year] = dateString.split('/').map(Number);
		return new Date(year, month - 1, day);
	}

}
