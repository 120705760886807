import { filter } from 'rxjs';
import { Component, OnInit, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { AuditService } from '../../service/audit.service';
import { CalendarModule } from 'primeng/calendar';

interface Bitacora {
  id:       number;
  usuario:  string;
  campo:    string;
  anterior: string;
  actual:   string;
  fecha:    string;
  hora:     string;
}

@Component({
  selector: 'app-bitacora-ticket-params',
  standalone: true,
  imports: [
    ToolbarModule, 
    TableModule, 
    ButtonModule, 
    InputTextModule, 
    DialogModule, 
    DropdownModule, 
    FormsModule, 
    ReactiveFormsModule,
    ToastModule,
    InputTextareaModule,
    CalendarModule,
    FormsModule
  ],
  providers:[MessageService],
  templateUrl: './bitacora-ticket-params.component.html',
  styleUrl: './bitacora-ticket-params.component.scss'
})
export class BitacoraTicketParamsComponent implements OnInit{
  auditService = inject(AuditService);
  
  bitacora: Bitacora[] = [];
  originalProducts: Bitacora[] = [];
  rangeDates = []; 

  month: number;
  year: number

  constructor(){}
  ngOnInit(){

    const { month, year } = this.getCurrentMonthAndYear();
	  this.month = month;
	  this.year = year;

    this.getBitacora(month, year);
  }

  getCurrentMonthAndYear(): { month: number, year: number } {
		const now = new Date();
		const month = now.getMonth() + 1; // getMonth() returns month index starting from 0
		const year = now.getFullYear();
		// console.log(month, year)
		return { month, year };
	}

  changeMonth(event:any){

		console.log(event)

		let month = event.month;
		let year = event.year;

		this.getBitacora(month, year);
	}
	

  getBitacora(month:number, year:number){
    this.auditService.getBitacoraParametros(month,year).subscribe((data:Bitacora[])=>{
      let bitacora = data.filter((item) => item.campo == 'municipio' || item.campo == 'eslogan' || item.campo == 'letrero'
      || item.campo == 'municipioSegundaLinea' || item.campo == 'esloganSegundaLinea');
      this.bitacora = bitacora.sort((a, b) => b.id - a.id);
      this.originalProducts = this.bitacora;
      console.log(this.bitacora); 
    })
  }

  filterProducts(avent:any): void {

		const [startDate, endDate] = this.rangeDates.map(this.parseDate);

    console.log(this.rangeDates)

		const filteredProducts = this.originalProducts.filter(product => {
			const productDate = this.parseDate(product.fecha);
			return productDate >= startDate && productDate <= endDate;
		});

		this.bitacora = filteredProducts; 
	}

	clearProducts(){
		// this.bitacora = this.originalProducts;
    this.getBitacora(this.month, this.year);
	}

	todayProducts(){
		this.getBitacora(this.month, this.year);

    setTimeout(
      () => {
        this.filtertoday();
      },
      1000
    );

	}

  filtertoday(){
    let today = this.rangeDates
		today[1] = this.rangeDates[0];

		const [startDate, endDate] = today.map(this.parseDate);
		
		const filteredProducts = this.originalProducts.filter(product => {
			const productDate = this.parseDate(product.fecha);
			return productDate >= startDate && productDate <= endDate;
		});

		this.bitacora = filteredProducts; 
  }

	parseDate(dateString: string): Date {
		const [day, month, year] = dateString.split('/').map(Number);
		return new Date(year, month - 1, day);
	}	
}

