import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API_URLS } from "src/app/auth/shared/models/apiURL";
import { environment } from "src/environments/environment";


@Injectable({
      providedIn: 'root'
})
export class TicketSaleService{

      constructor(private http: HttpClient) { 

      }

      httpOptions = {
            headers: new HttpHeaders({
                  'Content-Type': 'application/json'
            })
      };

      public getToken(){
            return sessionStorage.getItem("token");
      }

      getAgentesTicket(){
            this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
            return this.http.get<any>(environment.API_URL + API_URLS.GET_AGENTES_TICKET, this.httpOptions);
      }

      postVentaPaquete(venta){
            this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
            return this.http.post<any>(environment.API_URL + API_URLS.POST_VENTA_PAQUETE, venta, this.httpOptions);
      }

      getPaquetesSaldo(){
            this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
            return this.http.get<any>(environment.API_URL + API_URLS.GET_PAQUETES_SALDO, this.httpOptions);
      }

      getCostos(){
            this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
            return this.http.get<any>(environment.API_URL + API_URLS.GET_COSTOS, this.httpOptions);
      }

      postBitacoraVentas(bitacora:any){
            this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
            return this.http.post<any>(environment.API_URL + API_URLS.POST_BITACORA_VENTAS, bitacora, this.httpOptions);
      }

}