<div class="grid">
	<div class="col-12">

		<div class="card px-6 py-6"> 

			<ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
				<li>
					<a class="text-500 no-underline line-height-3 cursor-pointer">Home</a>
				</li>
				<li class="px-2">
					<i class="pi pi-angle-right text-500 line-height-3"></i>
				</li>
				<li>
					<a class="text-500 no-underline line-height-3 cursor-pointer">Ventas</a>
				</li>
				<li class="px-2">
					<i class="pi pi-angle-right text-500 line-height-3"></i>
				</li>
				<li>
					<span class="text-900 line-height-3">Saldo vendedores</span>
				</li>
			</ul>
			<br>
			<p-toast></p-toast>
			<p-toolbar styleClass="mb-4">
				<ng-template pTemplate="left">

					<span class="block mt-2 md:mt-0 p-input-icon-left">
						<i class="pi pi-search"></i>
						<input pInputText type="text" (input)="dtSaldo.filterGlobal($event.target.value, 'contains')" placeholder="Nombre"  class="w-full sm:w-auto"/>
					</span>
						<!-- <div class="my-2">
								<button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success mr-2" (click)="openNew()"></button>
								<button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger" (click)="deleteSelectedProducts()" [disabled]="!selectedProducts || !selectedProducts.length"></button>
						</div> -->
				</ng-template>

				<!-- <ng-template pTemplate="right">
					<button pButton pRipple label="Agregar" icon="pi pi-plus" class="p-button-success mr-2" ></button>
			</ng-template> -->
		</p-toolbar>

		<p-table #dtSaldo [value]="products" [columns]="cols" responsiveLayout="scroll" [rows]="10" [globalFilterFields]="['agente.nombre']" 
		[paginator]="true" [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true" currentPageReportTemplate="Monstrando {first} a {last} de {totalRecords} registros" 
		[(selection)]="selectedProducts" selectionMode="multiple" [rowHover]="true" dataKey="id">

		<ng-template pTemplate="header">
			<tr>
					<!-- <th style="width: 3rem">
							<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
					</th> -->
					<th pSortableColumn="code">Nombre <p-sortIcon field="nombre"></p-sortIcon></th>
					<th pSortableColumn="code">Dirección <p-sortIcon field="dirección"></p-sortIcon></th>
					<th pSortableColumn="name">Saldo<p-sortIcon field="horas"></p-sortIcon></th>
					<th></th>
			</tr>
	</ng-template>

	<ng-template pTemplate="body" let-product>
		<tr>
				<td style="width:7%; min-width:15rem;"><span class="p-column-title">Nombre</span>
						{{product.agente.nombre}}
				</td>
				<td style="width:15%; min-width:35rem;">
					<span class="p-column-title">Horas</span>
					{{product.direccion}}
				</td>
				<td style="width:5%; min-width:8rem;">
						<span class="p-column-title">Horas</span>
						${{product.paquete}}
				</td>
				<td>
						<div class="flex justify-content-end">
								<button  pTooltip="Historial del vendedor"  pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-info mr-5" (click)="editProduct(product)"></button>
								<!-- <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger" (click)="deleteProduct(product)"></button> -->
						</div>
				</td>
		</tr>
</ng-template>

		</p-table>
		</div>
	</div>
</div>

<p-dialog 
	[header]="header" 
      [modal]="true"
      [(visible)]="visible" 
      [style]="{ width: '50rem' }" 
      [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }">
	
	<p-table [value]="history" [columns]="historyCols" responsiveLayout="scroll" [rows]="10" [globalFilterFields]="['name','country.name','representative.name','status']" 
		[paginator]="true" [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true" currentPageReportTemplate="Monstrando {first} a {last} de {totalRecords} registros" 
		[(selection)]="selectedProducts" selectionMode="multiple" [rowHover]="true" dataKey="id">

		<ng-template pTemplate="header">
			<tr>
					<th pSortableColumn="code">Fecha<p-sortIcon field="dirección"></p-sortIcon></th>
					<th pSortableColumn="name">Saldo<p-sortIcon field="horas"></p-sortIcon></th>
					<th></th>
			</tr>
	</ng-template>

	<ng-template pTemplate="body" let-product>
		<tr>
				<td style="width:5%">
					<span class="p-column-title">Fecha</span>
					{{product.fecha}}
				</td>
				<td style="width:5%">
						<span class="p-column-title">Saldo</span>
						${{product.paquete}}
				</td>
				<td style="width:2%">
						<div class="flex justify-content-end">
							<span class="p-column-title"></span>
								@if (product.borrable) {
									<button  pTooltip="Eliminar venta"  pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger mr-5" (click)="eliminarVenta(product)"></button>
								}
						</div>
				</td>
		</tr>
</ng-template>

		</p-table>

</p-dialog>	