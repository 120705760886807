import { filter } from 'rxjs';
import { Product } from './../../../demo/api/product';
import { Component, OnInit, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { AuditService } from '../../service/audit.service';
import { CalendarModule } from 'primeng/calendar';

interface Bitacora {
  id:       number;
  usuario:  string;
  campo:    string;
  anterior: string;
  actual:   string;
  fecha:    string;
  hora:     string;
}

@Component({
  selector: 'app-bitacora-multa-params',
  standalone: true,
  imports: [
    ToolbarModule, 
    TableModule, 
    ButtonModule, 
    InputTextModule, 
    DialogModule, 
    DropdownModule, 
    FormsModule, 
    ReactiveFormsModule,
    ToastModule,
    InputTextareaModule,
    CalendarModule,
    FormsModule
  ],
  providers:[MessageService],
  templateUrl: './bitacora-multa-params.component.html',
  styleUrl: './bitacora-multa-params.component.scss'
})
export class BitacoraMultaParamsComponent implements OnInit{
  auditService = inject(AuditService);
  
  bitacora: Bitacora[] = [];
  originalProducts: Bitacora[] = [];

  fundamento: string;

  visible: boolean = false;

  rangeDates = []; 

  month: number;
  year: number;

  constructor(){}
  ngOnInit(){

    const { month, year } = this.getCurrentMonthAndYear();
	  this.month = month;
	  this.year = year;

    this.getBitacora(month, year);
  }

  getCurrentMonthAndYear(): { month: number, year: number } {
		const now = new Date();
		const month = now.getMonth() + 1; // getMonth() returns month index starting from 0
		const year = now.getFullYear();
		// console.log(month, year)
		return { month, year };
	}
	

  getBitacora(month:number, year:number){
    this.auditService.getBitacoraParametros(month,year).subscribe((data:Bitacora[])=>{
      let bitacora = data.filter((item) => 
        item.campo == 'footerMulta' 
      || item.campo == 'costoMulta' 
      || item.campo == 'fundamento'
      || item.campo == 'footerSegundaLinea');
      this.bitacora = bitacora.sort((a, b) => b.id - a.id);
      this.originalProducts = this.bitacora;
      console.log(this.bitacora); 
    })
  }

  edit(product: string){
    this.fundamento = product;
    this.visible = true;
  }

  filterProducts(avent:any): void {

		const [startDate, endDate] = this.rangeDates.map(this.parseDate);

    console.log(this.rangeDates)

		// console.log(startDate)
		// console.log(endDate)

		const filteredProducts = this.originalProducts.filter(product => {
			const productDate = this.parseDate(product.fecha);
			return productDate >= startDate && productDate <= endDate;
		});

		this.bitacora = filteredProducts; 
	}

	clearProducts(){
		// this.bitacora = this.originalProducts;
    this.getBitacora(this.month, this.year);
	}

	todayProducts(){
    
    this.getBitacora(this.month, this.year);

    setTimeout(
      () => {
        this.filtertoday();
      },
      1000
    );
	
	}

  changeMonth(event:any){

		console.log(event)

		let month = event.month;
		let year = event.year;

		this.getBitacora(month, year);
	}

  filtertoday(){
    let today = this.rangeDates
		today[1] = this.rangeDates[0];

		const [startDate, endDate] = today.map(this.parseDate);
		
		const filteredProducts = this.originalProducts.filter(product => {
			const productDate = this.parseDate(product.fecha);
			return productDate >= startDate && productDate <= endDate;
		});

		this.bitacora = filteredProducts; 
  }

	parseDate(dateString: string): Date {
		const [day, month, year] = dateString.split('/').map(Number);
		return new Date(year, month - 1, day);
	}	
}
