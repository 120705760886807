<div class="card">

      <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
            <li>
                  <a class="text-500 no-underline line-height-3 cursor-pointer">Home</a>
            </li>
            <li class="px-2">
                  <i class="pi pi-angle-right text-500 line-height-3"></i>
            </li>
            <li>
                  <a class="text-500 no-underline line-height-3 cursor-pointer">Ventas</a>
            </li>
            <li class="px-2">
                  <i class="pi pi-angle-right text-500 line-height-3"></i>
            </li>
            <li>
                  <span class="text-900 line-height-3 ">Registro de venta de saldo</span>
            </li>
      </ul>
      <br>
      
      <p-toast></p-toast>

      <form [formGroup]="ventaTicketsForm">

            <div class="p-fluid grid">
            
                  <div class="field col-3">
                        <label htmlFor="amount">Cantidad pagada</label>
                        <p-dropdown [ngModelOptions]="{standalone: true}" (onChange)="selectAmount($event.value)"  [options]="amount" [(ngModel)]="selectedAmount" optionLabel="amount" placeholder="Selecciona un paquete de saldo">
                              <ng-template let-country pTemplate="item">
                                    <div>${{ country.amount }}</div>
                              </ng-template>
                        </p-dropdown>
                  </div>
                  <div class="field col-9">
                        <label htmlFor="user">Vendedor</label>
                        <p-dropdown [ngModelOptions]="{standalone: true}" appendTo="body" [options]="products" [(ngModel)]="selectedProduct" optionLabel="nombre" [showClear]="true" placeholder="Selecciona un vendedor">

                              <ng-template let-country pTemplate="item">
                                    <div>{{ country.nombre }} - {{ country.direccion }}</div>
                              </ng-template>
                              
                        </p-dropdown>
                  </div>
      </div>

      <div class="p-fluid grid">
            
            <div class="field col-3">
                  <label htmlFor="amount">Fecha</label>
                  <span class="p-input-icon-left">
                        <i class="pi pi-calendar"></i>
                        <input [ngModelOptions]="{standalone: true}" type="text" pInputText  [(ngModel)]="date"  disabled/>
                  </span>
            </div>
            <div class="field col-9">
                  <label htmlFor="user">Folio de recibo de pago</label>
                  <input formControlName="folio" maxlength="20" pInputText pKeyFilter="int" placeholder="Ingresar folio de pago" />
                  <!-- <p-dropdown appendTo="body" [options]="products" [(ngModel)]="selectedProduct" optionLabel="user" [showClear]="true" placeholder="Selecciona un usuario"></p-dropdown> -->
            </div>
</div>
      
      <br>

      <div class="p-fluid grid">
            <div class="field col-3">
                  <label htmlFor="amount">Saldo aplicado</label>
                  <input [ngModelOptions]="{standalone: true}" [(ngModel)]="saldoAplicado" pInputText type="number" pKeyFilter="int" placeholder="Calculando" disabled/>
            </div>
            <div class="field col-3">
                  <label htmlFor="user">Costo hora auto</label>
                  <input pInputText type="number" pKeyFilter="int" placeholder="Costo" [ngModelOptions]="{standalone: true}"  disabled [(ngModel)]="costoAuto"/>
            </div>
            <div class="field col-3">
                  <label htmlFor="user">Costo hora moto</label>
                  <input pInputText type="number" pKeyFilter="int" placeholder="Costo" [ngModelOptions]="{standalone: true}"  disabled [(ngModel)]="costoMoto"/>
            </div>
            <div class="field col-3">
                  <label htmlFor="user">Comisión</label>
                  <input pInputText type="text" pKeyFilter="int" [ngModelOptions]="{standalone: true}" [(ngModel)]="porcentajeEtiqueta" disabled/>
            </div>
      </div>

      <div>
            <p-button type="submit" label="Aplicar pago" [raised]="false" severity="success" icon="pi pi-check" iconPos="right" (click)="registrarVenta()"></p-button>
      </div>

      </form>

</div>