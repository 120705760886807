import { Component, OnInit, inject } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ToastModule } from 'primeng/toast';
import { TimezoneService } from '../timezone.service';

@Component({
  selector: 'app-timezone',
  standalone: true,
  imports: [
    FieldsetModule, 
    InputTextModule, 
    ButtonModule, 
    DividerModule,
    InputTextareaModule,
    ToastModule, 
    ReactiveFormsModule,
    FormsModule,
    InputNumberModule,
    DropdownModule
  ],
  providers:[MessageService],
  templateUrl: './timezone.component.html',
  styleUrl: './timezone.component.scss'
})
export class TimezoneComponent implements OnInit{
  timeService = inject(TimezoneService);

  timezones: any = [
    {
        "descripcion": "America/Cancun"
    },
    {
        "descripcion": "America/Merida"
    },
    {
        "descripcion": "America/Mexico_City"
    },
    {
        "descripcion": "America/Monterrey"
    },
    {
        "descripcion": "America/Matamoros"
    },
    {
        "descripcion": "America/Ojinaga"
    },
    {
        "descripcion": "America/Chihuahua"
    },
    {
        "descripcion": "America/Hermosillo"
    },
    {
        "descripcion": "America/Mazatlan"
    },
    {
        "descripcion": "America/Tijuana"
    },
    {
        "descripcion": "America/Bahia_Banderas"
    }
]

  selectedZone:any;

  constructor(private messageService: MessageService) { }
  ngOnInit() {
    this.getTimeZone();
  }

  getTimeZone(){
    this.timeService.getTimeZone().subscribe((data)=>{
      console.log(data);

      let zone = {
        descripcion: data.timeZone
      }

      this.selectedZone = zone;
    })
  }

  setTimeZone(){
    this.timeService.setTimeZone(this.selectedZone.descripcion).subscribe((data)=>{
      console.log(data);
      this.messageService.add({severity:'success', summary: 'Exito', detail: 'Zona horaria actualizada correctamente'});

      this.getTimeZone();
    })
  }
}
