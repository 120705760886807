import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URLS } from 'src/app/auth/shared/models/apiURL';
import { environment } from 'src/environments/environment';

interface Costos{
  costoAuto: number
  costoMoto : number
  hora_fin: string
  hora_inicio: string
  paqueteDesde: number
  paqueteHasta: number
  intervalo:number
  porcentajeGanancia: number
  ticketMaximo: number
  valorHacendario: number
  }

  interface ParametrosTicket{
    municipio: string;
    eslogan: string;
    letrero: string;
  }

  interface ParametrosMulta{
    fundamento: string;
    footerMulta: string;
    costoMulta: number;
  }

@Injectable({
  providedIn: 'root'
})
export class SystemService {

  constructor(private http: HttpClient) { 

  }

  httpOptions = {
        headers: new HttpHeaders({
              'Content-Type': 'application/json'
        })
  };

  public getToken(){
        return sessionStorage.getItem("token");
  }

  getUsuariosSistema(){
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
    return this.http.get<any>(environment.API_URL + API_URLS.GET_USUARIOS_SISTEMA, this.httpOptions);
  }

  getCostosSistema(){
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
    return this.http.get<any>(environment.API_URL + API_URLS.GET_COSTOS_SISTEMA, this.httpOptions);
  }

  getParametros(){
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
    return this.http.get<any>(environment.API_URL + API_URLS.GET_PARAMETROS_TICKET_MULTA, this.httpOptions);
  }

  getParametrosTicket(){
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
    return this.http.get<any>(environment.API_URL + API_URLS.GET_PARAMETROS_TICKET, this.httpOptions);
  }

  getParametrosMulta(){
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
    return this.http.get<any>(environment.API_URL + API_URLS.GET_PARAMETROS_MULTA, this.httpOptions);
  }

  updateCostos(datos: Costos){
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
    return this.http.post<any>(environment.API_URL + API_URLS.UPDATE_COSTOS,datos,this.httpOptions);
  }

  // updateParametros(datos: Parametros){
  //   this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
  //   return this.http.post<any>(environment.API_URL + API_URLS.UPDATE_PARAMETROS,datos,this.httpOptions);
  // }

  updateParametrosTicket(datos: ParametrosTicket){
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
    return this.http.post<any>(environment.API_URL + API_URLS.UPDATE_PARAMETROS_TICKET,datos,this.httpOptions);
  }

  updateParametrosMulta(datos: ParametrosMulta){
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
    return this.http.post<any>(environment.API_URL + API_URLS.UPDATE_PARAMETROS_MULTA,datos,this.httpOptions);
  }

  getMotivosMulta(){
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
    return this.http.get<any>(environment.API_URL + API_URLS.GET_MOTIVOS_MULTA, this.httpOptions);
  }

  getUsuario(id: number){
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
    return this.http.get<any>(environment.API_URL + API_URLS.GET_USUARIO + id, this.httpOptions);
  }

  postMotivoMulta(motivo:any){
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
    return this.http.post<any>(environment.API_URL + API_URLS.POST_MOTIVOS_MULTA,motivo,this.httpOptions);
  }

  putMotivoMulta(id: number, motivo:any){
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
    return this.http.put<any>(environment.API_URL + API_URLS.PUT_MOTIVOS_MULTA + id,motivo,this.httpOptions);
  }

  deleteMotivoMulta(id: number){
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
    return this.http.delete<any>(environment.API_URL + API_URLS.DELETE_MOTIVOS_MULTA + id,this.httpOptions);
  }

  postModifiedFields(modifiedFields:any[]){
  this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
    return this.http.post<any>(environment.API_URL + API_URLS.GET_BITACORA_PARAMETROS,modifiedFields,this.httpOptions);
  }

  getParametrosConfiguracion(){
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
    return this.http.get<any>(environment.API_URL + API_URLS.GET_PARAMETROS_CONFIGURACION, this.httpOptions);
  }

  updateParametrosConfiguracion(datos: any){
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
    return this.http.post<any>(environment.API_URL + API_URLS.UPDATE_PARAMETROS_CONFIGURACION,datos,this.httpOptions);
  }
}
