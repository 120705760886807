<div class="grid">
      <div class="col-12">
            <div class="card px-6 py-6">


                  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
                        <li>
                              <a class="text-500 no-underline line-height-3 cursor-pointer">Home</a>
                        </li>
                        <li class="px-2">
                              <i class="pi pi-angle-right text-500 line-height-3"></i>
                        </li>
                        <li>
                              <a class="text-500 no-underline line-height-3 cursor-pointer">Administrador general</a>
                        </li>
                        <li class="px-2">
                              <i class="pi pi-angle-right text-500 line-height-3"></i>
                        </li>
                        <li>
                              <span class="text-900 line-height-3 ">Configuración</span>
                        </li>
                  </ul>
                  <br>


                  <p-fieldset legend="Conexión">

                        <div class="p-fluid grid">
                              <div class="field col-12">
                                    <label htmlFor="amount">URL de conexión</label>
                                    <!-- <p-dropdown  [options]="amount" [(ngModel)]="selectedAmount" optionLabel="amount" [showClear]="true" placeholder="Selecciona un paquete de horas"></p-dropdown> -->
                                    <!-- <input pInputText pKeyFilter="int" placeholder="$8.00" disabled/> -->
                                    <span class="col-12" class="p-input-icon-left">
                                          <i class="pi pi-database"></i>
                                          <input type="text" pInputText placeholder="Ejemplo: jdbc:postgresql://localhost:8080"  disabled=""/>
                                    </span>
                              </div>
                        </div>

                        <div class="p-fluid grid">
                              <div class="field col-6">
                                    <label htmlFor="amount">Usuario</label>
                                    <span class="col-12" class="p-input-icon-left">
                                          <i class="pi pi-user"></i>
                                          <input type="text" pInputText placeholder="Ingresar usuario"  disabled=""/>
                                    </span>
                              </div>

                              <div class="field col-6">
                                    <label htmlFor="amount">Contraseña</label>
                                    <span class="col-12" class="p-input-icon-left">
                                          <i class="pi pi-lock"></i>
                                          <input type="text" pInputText placeholder="Ingresar contraseña"  disabled=""/>
                                    </span>
                              </div>
                        </div>

                        
                  </p-fieldset>

                  <br><br>

                  <p-fieldset legend="Configuración de WS de acceso">
                        <div class="p-fluid grid">
                              <div class="field col-12">
                                    <label htmlFor="amount">URL</label>
                                    <!-- <p-dropdown  [options]="amount" [(ngModel)]="selectedAmount" optionLabel="amount" [showClear]="true" placeholder="Selecciona un paquete de horas"></p-dropdown> -->
                                    <!-- <input pInputText pKeyFilter="int" placeholder="$8.00" disabled/> -->
                                    <span class="col-12" class="p-input-icon-left">
                                          <i class="pi pi-database"></i>
                                          <input type="text" pInputText placeholder="localhost:8080"  disabled=""/>
                                    </span>
                              </div>
                        </div>                        
                  </p-fieldset>

                  <br><br>

                  <p-fieldset legend="Licenciamiento">

                        <div class="p-fluid grid">
                              <div class="field col-6">
                                    <label htmlFor="amount">Razón Social</label>
                                    <!-- <p-dropdown  [options]="amount" [(ngModel)]="selectedAmount" optionLabel="amount" [showClear]="true" placeholder="Selecciona un paquete de horas"></p-dropdown> -->
                                    <!-- <input pInputText pKeyFilter="int" placeholder="$8.00" disabled/> -->
                                    <span class="col-12" class="p-input-icon-left">
                                          <i class="pi pi-database"></i>
                                          <input type="text" pInputText placeholder=""  disabled=""/>
                                    </span>
                              </div>
                              <div class="field col-6">
                                    <label htmlFor="amount">RFC</label>
                                    <!-- <p-dropdown  [options]="amount" [(ngModel)]="selectedAmount" optionLabel="amount" [showClear]="true" placeholder="Selecciona un paquete de horas"></p-dropdown> -->
                                    <!-- <input pInputText pKeyFilter="int" placeholder="$8.00" disabled/> -->
                                    <span class="col-12" class="p-input-icon-left">
                                          <i class="pi pi-database"></i>
                                          <input type="text" pInputText placeholder=""  disabled=""/>
                                    </span>
                              </div>
                        </div>

                        <div class="p-fluid grid">
                              <div class="field col-12">
                                    <label htmlFor="amount">Empresa</label>
                                    <!-- <p-dropdown  [options]="amount" [(ngModel)]="selectedAmount" optionLabel="amount" [showClear]="true" placeholder="Selecciona un paquete de horas"></p-dropdown> -->
                                    <!-- <input pInputText pKeyFilter="int" placeholder="$8.00" disabled/> -->
                                    <span class="col-12" class="p-input-icon-left">
                                          <i class="pi pi-database"></i>
                                          <input type="text" pInputText placeholder=""  disabled=""/>
                                    </span>
                              </div>
                        </div>

                        <div class="p-fluid grid">
                              <div class="field col-12">
                                    <label htmlFor="amount">Licencia</label>
                                    <span class="col-12" class="p-input-icon-left">
                                          <i class="pi pi-shield"></i>
                                          <input type="text" pInputText placeholder="Ingresar licencia"  disabled=""/>
                                    </span>
                              </div>
                        </div>                        
                  </p-fieldset>

                  <br><br>

                  <div class="p-fluid grid">
                        <div class="field col-10"> 

                        </div>

                        <div class="field col-2">
                              
                                    <p-button label="Guardar" [raised]="false" severity="success" icon="pi pi-save" iconPos="right"></p-button>
                              
                        </div>
                  </div>

            </div>
      </div>
</div>