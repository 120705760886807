import { Component, OnInit, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { KeyFilterModule } from 'primeng/keyfilter';
import { AuthService } from '../service/user.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [
    ToolbarModule, 
    InputTextModule, 
    ButtonModule, 
    DialogModule, 
    DropdownModule, 
    FormsModule,
    ReactiveFormsModule,
    ToastModule,
    DividerModule,
    KeyFilterModule
  ],
  providers: [MessageService],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent implements OnInit{

  authService = inject(AuthService)

  blockSpace: RegExp;
  password: string;

  constructor(private messageService: MessageService){}
  ngOnInit(){
    
    this.blockSpace = /[^\s]/;

  }

  guardarContrasena(){

    if(!this.password){
      this.addWarning('error','Por favor ingrese una contraseña')
      return;
    }

    let newPass = {
      password: this.password
    }

    this.authService.cambiarContraseña(sessionStorage.getItem("id"),newPass).subscribe({
      next: (data) => {
        this.addWarning('success','Contraseña actualizada correctamente')
      },
      error: (error) => {
        console.log(error)
        this.addWarning('error','Error al actualizar la contraseña');
      }
    })
     

  }

  addWarning(severity: string,message: string){
    this.messageService.add({ severity: severity, summary: 'Mensaje', 
    detail: message });
  }
}
