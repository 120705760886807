<div class="layout-topbar ">
    
    <button #menubutton class="p-link layout-menu-button layout-topbar-button m-3" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>

    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>


    <a class="layout-topbar-logo" routerLink="">
        <!-- <img src="assets/layout/images/{{layoutService.config().colorScheme === 'light' ? 'logo-dark' : 'logo-white'}}.svg" alt="logo"> -->
        <img src="assets/parkifacil.svg" alt="logo" class="h-4rem">
        <!-- <span class="text-blue-400 font-medium text-3xl">Parkifácil - Municipios</span> -->
    </a>

    <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
        <!-- <button class="p-link layout-topbar-button">
            <i class="pi pi-calendar"></i>
            <span>Calendar</span>
        </button> -->

        <p class="flex align-items-center mt-3">{{name}}</p>

        <button class="p-link layout-topbar-button" (click)="menu.toggle($event)">
            <i class="pi pi-user"></i>
            <span>Profile</span>
        </button>

        <p-tieredMenu #menu [model]="items" [popup]="true"></p-tieredMenu>

    </div>
</div>