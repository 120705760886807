<div class="grid">
    <div class="col-12">
          <div class="card px-6 py-6">
                <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
                      <li>
                            <a class="text-500 no-underline line-height-3 cursor-pointer">Home</a>
                      </li>
                      <li class="px-2">
                            <i class="pi pi-angle-right text-500 line-height-3"></i>
                      </li>
                      <li>
                            <a class="text-500 no-underline line-height-3 cursor-pointer">Coordinador</a>
                      </li>
                      <li class="px-2">
                            <i class="pi pi-angle-right text-500 line-height-3"></i>
                      </li>
                      <li>
                            <span class="text-900 line-height-3 ">Parametros ticket venta</span>
                      </li>
                </ul>
                <br>

                <p-toast></p-toast>

                    <form [formGroup]="parametrosForm">

                        <div class="pb-2">   
                              <span class="text-900 line-height-3 font-semibold  text-xl">Nombre municipio</span>
                        </div>
  
                          
                        <div class="p-fluid grid">
                          <div class="field col-4">
                                <label htmlFor="amount">Linea 1</label>
                                <span class="col-8" class="p-input-icon-left">
                                      <i class="pi pi-database"></i>
                                      <input maxlength="30" [ngModelOptions]="{standalone: true}"  [(ngModel)]="municipio" type="text" pInputText placeholder="Nombre"/>
                                    </span>
                                    <small>Caracteres restantes: {{ municipioMaxlength - municipio.length }}</small>

                              </div>
                      </div>

                      <div class="p-fluid grid">
                        <div class="field col-4">
                              <label htmlFor="amount">Linea 2</label>
                              <span class="col-8" class="p-input-icon-left">
                                    <i class="pi pi-database"></i>
                                    <input maxlength="30" [ngModelOptions]="{standalone: true}"  [(ngModel)]="municipioSegundaLinea" type="text" pInputText placeholder="Nombre"/>
                              </span>
                              <small>Caracteres restantes: {{ municipioMaxlength - municipioSegundaLinea.length }}</small>
                        </div>
                    </div>
                      
                    <div class="pb-2 pt-2">   
                        <span class="text-900 line-height-3 font-semibold  text-xl">Eslogan</span>
                  </div>

                    <div class="p-fluid grid">
                          <div class="field col-4">
                                <label htmlFor="amount">Linea 1</label>
                                <span class="col-12" class="p-input-icon-left">
                                      <i class="pi pi-database"></i>
                                      <input maxlength="30" [ngModelOptions]="{standalone: true}"  [(ngModel)]="eslogan" type="text" pInputText placeholder="Eslogan"/>
                                </span>
                                <small>Caracteres restantes: {{ esloganMaxlength - eslogan.length }}</small>
                          </div>
                    </div>
                    
                    <div class="p-fluid grid">
                        <div class="field col-4">
                              <label htmlFor="amount">Linea 2</label>
                              <span class="col-12" class="p-input-icon-left">
                                    <i class="pi pi-database"></i>
                                    <input maxlength="30" [ngModelOptions]="{standalone: true}"  [(ngModel)]="esloganSegundaLinea" type="text" pInputText placeholder="Eslogan"/>
                              </span>
                              <small>Caracteres restantes: {{ esloganMaxlength - esloganSegundaLinea.length }}</small>
                        </div>
                  </div>

                  <div class="pb-2 pt-2">   
                        <span class="text-900 line-height-3 font-semibold  text-xl">Texto impreso ticket</span>
                  </div>

                    <div class="p-fluid grid">
                      <div class="field col-12">
                            <label htmlFor="amount">Linea 1</label>
                            <span class="col-12" class="p-input-icon-left">
                                  <i class="pi pi-database"></i>
                                  <input maxlength="50" [ngModelOptions]="{standalone: true}"  [(ngModel)]="letrero" type="text" pInputText placeholder="Texto impreso ticket"/>
                            </span>
                            <small>Caracteres restantes: {{ textoMaxlength - letrero.length }}</small>
                      </div>
                </div>
  
                <!-- <div class="p-fluid grid">
                  <div class="field col-12">
                        <label htmlFor="amount">Linea 2</label>
                        <span class="col-12" class="p-input-icon-left">
                              <i class="pi pi-database"></i>
                              <input [ngModelOptions]="{standalone: true}"  [(ngModel)]="letrero" type="text" pInputText placeholder="Texto impreso ticket"/>
                        </span>
                  </div>
            </div> -->

                    <!-- <p-divider></p-divider>
                    <br>
  
                    <div class="pb-2">   
                      <span class="text-900 line-height-3 font-semibold text-xl">Multa</span>
                  </div>
  
                    
                  <div class="p-fluid grid">
                      <div class="field col-12">
                            <label htmlFor="amount">Texto impreso multa</label>
                            <span class="col-8" class="p-input-icon-left">
                                  <i class="pi pi-database"></i>
                                  <input [ngModelOptions]="{standalone: true}"  [(ngModel)]="footerMulta"  type="text" pInputText placeholder="Texto impreso multa"/>
                            </span>
                      </div>
                  </div>
                  <div class="p-fluid grid">
                      <div class="field col-12">
                            <label htmlFor="amount">Fundamentos multa</label>
                            <textarea 
                            [ngModelOptions]="{standalone: true}"  [(ngModel)]="fundamento"
                              rows="5"
                              cols="30" 
                              pInputTextarea>
                          </textarea>
                      </div>
                  </div>
  
                  <div class="p-fluid grid">
                      <div class="field col-3">
                            <label htmlFor="amount">Costo multa</label>
                            <span class="col-12" class="p-input-icon-left">
                              
                            <p-inputNumber mode="currency" currency="USD" locale="en-US" 
                            [ngModelOptions]="{standalone: true}"  [(ngModel)]="costoMulta" type="number" placeholder="Ingresar costo"
                            />
                            
                              </span>
                      </div>

                      <div class="field col-4">
                        <label htmlFor="amount">Costo máximo de multa por código hacendario</label>
                        <span class="col-12" class="p-input-icon-left">
                              <i class="pi pi-money-bill"></i>
                              <input disabled [ngModelOptions]="{standalone: true}"  [(ngModel)]="costoMaximoMulta" type="number" pInputText placeholder="Ingresar costo"/>
                        </span>
                      </div>
                </div> -->
                    
  
                    <div class="p-fluid grid">
                          <!-- <div class="field col-10"> 
  
                          </div> -->
  
                          <div class="field col-2">
                                
                                      <p-button (click)="updateValues()" label="Guardar" [raised]="false" severity="success" icon="pi pi-save" iconPos="right"></p-button>
                                
                          </div>
                    </div>   

                    </form>                   
                
          </div>
    </div>
</div>