import { Component, OnInit, inject } from '@angular/core';
import { ReactiveFormsModule, FormsModule, FormGroup, FormBuilder } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { FieldsetModule } from 'primeng/fieldset';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ToastModule } from 'primeng/toast';
import { SystemService } from '../../service/system.service';

interface Parametros{
  valorHacendario: number;
  costoMaximoMulta: number;
  utilidad_maxima: number;
}

@Component({
  selector: 'app-params-config',
  standalone: true,
  imports: [
    FieldsetModule, 
    InputTextModule, 
    ButtonModule, 
    DividerModule,
    InputTextareaModule,
    ToastModule, 
    ReactiveFormsModule,
    FormsModule,
    InputNumberModule
  ],
  providers:[MessageService],
  templateUrl: './params-config.component.html',
  styleUrl: './params-config.component.scss'
})
export class ParamsConfigComponent implements OnInit{

  systemService = inject(SystemService)

  parametrosForm: FormGroup;

  valorHacendario: number;
  costoMaximoMulta: number;
  utilidad_maxima: number;

  constructor(
    private formBuilder: FormBuilder,
		private messageService: MessageService,
  ){}

  ngOnInit(){
    this.createForm();
    this.getParametros();
  }

  createForm(): FormGroup{
		return this.parametrosForm = this.formBuilder.group({
      costoMaximoMulta:[0,],
      valorHacendario:[0,],
      utilidad_maxima:[0,]
		});
	}

  getParametros(){
    this.systemService.getParametrosConfiguracion().subscribe({
      next: (data : Parametros) =>{

        this.valorHacendario = data.valorHacendario
        this.costoMaximoMulta = data.costoMaximoMulta
        this.utilidad_maxima = data.utilidad_maxima;

      }, error: (error) =>{
        this.addWarning('error','Error al obtener los parametros','Advertencia')
      }
    })
  }

  updateValues(){

    if(this.valorHacendario <= 0 || this.costoMaximoMulta <= 0 || this.utilidad_maxima <= 0){
        this.addWarning('error','Los valores deben ser mayores a 0','Advertencia')
        return;
    }

    this.parametrosForm.controls['valorHacendario'].setValue(this.valorHacendario)
    this.parametrosForm.controls['costoMaximoMulta'].setValue(this.costoMaximoMulta)
    this.parametrosForm.controls['utilidad_maxima'].setValue(this.utilidad_maxima)

    this.systemService.updateParametrosConfiguracion(this.parametrosForm.value).subscribe({
      next: (data)=>{
        this.addWarning('success','Parametros actualizados correctamente','Exito')
        setTimeout(() => {
          this.getParametros();
        }, 1000);
      }, error: (error)=>{
        this.addWarning('error','Error al actualizar los parametros','Advertencia')
      }
    })
  }

  addWarning(severity: string,message: string, resumen: string){
		this.messageService.add({ severity: severity, summary: resumen, 
		detail: message });
	}

}
