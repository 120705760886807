import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { BehaviorSubject, Observable, catchError, map, tap, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { JwtUsuarioModelLogin } from "../models/jwtUsuarioModelLogin";
import { API_URLS } from "../shared/models/apiURL";
import { JwtResponseModel } from "../models/jwtResponseModel";
import { Router } from "@angular/router";


@Injectable({
      providedIn: 'root'
})
export class AuthService{

      router = inject(Router)

      httpOptions = {
            headers: new HttpHeaders({
                  'Content-Type': 'application/json'
            })
      };

      currentUserLoginOn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
      currentUserData: BehaviorSubject<String> =new BehaviorSubject<String>("");

      constructor(private http: HttpClient) { 
            this.currentUserLoginOn=new BehaviorSubject<boolean>(sessionStorage.getItem("token")!=null);
            this.currentUserData=new BehaviorSubject<String>(sessionStorage.getItem("token") || "");
      }

      login(credentials:JwtUsuarioModelLogin):Observable<any>{
            return this.http.post<JwtResponseModel>(environment.API_URL+API_URLS.LOGEO,credentials, this.httpOptions).pipe(
            tap( (userData) => {
                  sessionStorage.setItem("token", userData.token);
                  sessionStorage.setItem("nombre", userData.nombre);
                  sessionStorage.setItem("rol", userData.rol);
                  sessionStorage.setItem("id", userData.id);
                  this.currentUserData.next(userData.token);
                  this.currentUserLoginOn.next(true);
            }),
            map((userData)=> userData.token),
            catchError(this.handleError)
            );
      }

      logout():void{
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("nombre");
            sessionStorage.removeItem("rol");
            this.router.navigate([''])
            this.currentUserLoginOn.next(false);
      }

      cambiarContraseña(id:string,password:any){
            return this.http.post<any>(environment.API_URL + API_URLS.POST_RESET_PASSWORD + id,password,this.httpOptions);
      }


      private handleError(error:HttpErrorResponse){
            if(error.status===0){
                  console.error('Se ha producio un error ', error.error);
            }
            else{
                  console.error('Backend retornó el código de estado ', error);
            }
                  return throwError(()=> new Error('Algo falló. Por favor intente nuevamente.'));
      }

      get userData():Observable<String>{
            return this.currentUserData.asObservable();
      }

      get userLoginOn(): Observable<boolean>{
            return this.currentUserLoginOn.asObservable();
      }

      get userToken():String{
            return this.currentUserData.value;
      }

}