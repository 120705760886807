<div class="grid">
      <div class="col-12">
            <div class="card px-6 py-6">
                  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
                        <li>
                              <a class="text-500 no-underline line-height-3 cursor-pointer">Home</a>
                        </li>
                        <li class="px-2">
                              <i class="pi pi-angle-right text-500 line-height-3"></i>
                        </li>
                        <li>
                              <a class="text-500 no-underline line-height-3 cursor-pointer">Coordinador</a>
                        </li>
                        <li class="px-2">
                              <i class="pi pi-angle-right text-500 line-height-3"></i>
                        </li>
                        <li>
                              <span class="text-900 line-height-3 ">Parametros de ventas</span>
                        </li>
                  </ul>
                  <br>

                  <p-toast></p-toast>

                        <div class="pb-2">   
                              <span class="text-900 line-height-3 font-semibold  text-xl">Configuración de paquetes para venta de saldo</span>
                        </div>

                       <form [formGroup]="costosForm">

                        <div class="p-fluid grid">
                              <div class="field col-3">
                                    <label htmlFor="amount">Desde</label>
                                    <!-- <span class="col-12 p-input-icon-left" > -->
                                          <!-- <i class="pi"></i> -->
                                          <p-inputNumber mode="currency" currency="USD" locale="en-US" [min]="50"
                                           [ngModelOptions]="{standalone: true}"  [(ngModel)]="paqueteDesde" type="Number" placeholder="Monto mínimo"/>
                                    <!-- </span> -->
                              </div>
                              <div class="field col-3">
                                    <label htmlFor="amount">Hasta</label>
                                    <!-- <span class="col-12"> -->
                                          <i class="pi"></i>
                                          <p-inputNumber mode="currency" currency="USD" locale="en-US" [min]="0"
                                           [ngModelOptions]="{standalone: true}"  [(ngModel)]="paqueteHasta" type="number" placeholder="Monto máximo"/>
                                    <!-- </span> -->
                              </div>
                              <div class="field col-3">
                                    <label htmlFor="amount">Intervalo</label>
                                    <!-- <span class="col-12"> -->
                                          <i class="pi"></i>
                                          <p-inputNumber mode="currency" currency="USD" locale="en-US" [min]="50"
                                           [ngModelOptions]="{standalone: true}"  [(ngModel)]="intervalo" type="number" placeholder="Monto máximo"/>
                                    <!-- </span> -->
                              </div>
                        </div>
                        <div class="p-fluid grid">
                              <div class="field col-3">
                                    <label htmlFor="amount">Saldo máximo permitido</label>
                                    <!-- <span class="col-12"> -->
                                          <i class="pi"></i>
                                          <p-inputNumber mode="currency" currency="USD" locale="en-US" [min]="0"
                                           [ngModelOptions]="{standalone: true}"  [(ngModel)]="saldoMaximo" type="Number" placeholder="Monto mínimo"/>
                                    <!-- </span> -->
                              </div>
                              <div class="field col-4">
                                    <label htmlFor="amount">Comisión para el vendedor de tickets</label>
                                    <!-- <span class="col-12"> -->
                                          <!-- <i class="pi"></i> -->
                                          <p-inputNumber suffix="%" [min]="0"
                                           [ngModelOptions]="{standalone: true}"  [(ngModel)]="porcentajeGanancia" type="Number" placeholder="Ingresar comisión"/>
                                    <!-- </span> -->
                              </div>
                        </div>
                        <p-divider></p-divider>
                        <br>

                        <div class="pb-2">   
                              <span class="text-900 line-height-3 font-semibold text-xl">Configuración para venta de tickets</span>
                        </div>
                        <div class="p-fluid grid">
                              <div class="field col-2">
                                    <label htmlFor="amount">Costo hora auto</label>
                                    <!-- <span class="col-12"> -->
                                          <!-- <i class="pi"></i> -->
                                          <p-inputNumber mode="currency" currency="USD" locale="en-US" [min]="0"
                                          [ngModelOptions]="{standalone: true}"  [(ngModel)]="costoAuto" type="Number" placeholder="Ingresar costo"/>
                                    <!-- </span> -->
                              </div>
                              <div class="field col-2">
                                    <label htmlFor="amount">Costo hora moto</label>
                                    <!-- <span class="col-12"> -->
                                          <!-- <i class="pi"></i> -->
                                          <p-inputNumber mode="currency" currency="USD" locale="en-US"  [min]="0"
                                          [ngModelOptions]="{standalone: true}"  [(ngModel)]="costoMoto" type="number"  placeholder="Ingresar costo"/>
                                    <!-- </span> -->
                              </div>
                              <div class="field col-4">
                                    <label htmlFor="amount">Venta máxima permitida por ticket</label>
                                    <!-- <span class="col-12"> -->
                                          <!-- <i class="pi"></i> -->
                                          <p-inputNumber mode="currency" currency="USD" locale="en-US" [min]="0"
                                          [ngModelOptions]="{standalone: true}"  [(ngModel)]="ticketMaximo" type="number" placeholder="$8.00"/>
                                    <!-- </span> -->
                              </div>
                        </div>


                        <div class="p-fluid grid">
                              <div class="field col-3">
                                    <label htmlFor="amount">Valor máximo código hacendario</label>
                                    <!-- <span class="col-12"> -->
                                          <!-- <i class="pi"></i> -->
                                          <p-inputNumber mode="currency" currency="USD" locale="en-US" 
                                          [ngModelOptions]="{standalone: true}"  [(ngModel)]="valorHacendario" type="number" placeholder="Ingresar costo"
                                          disabled/>
                                    <!-- </span> -->
                              </div>
                        </div>

                        <p-divider></p-divider>
                        <br>

                        <div class="pb-2">   
                              <span class="text-900 line-height-3 font-semibold text-xl">Horario de operación (Formato de 24 horas)</span>
                        </div>

                        <div class="p-fluid grid">
                              <div class="field col-3">
                                    <label for="amount">Desde</label>
                                    <span class="col-12" class="p-input-icon-left">
                                          <!-- <i class="pi pi-stopwatch"></i>
                                          <p-inputNumber [ngModelOptions]="{standalone: true}" suffix="AM" [min]="1"  [max]="11"  [(ngModel)]="hora_inicio" type="Number" pInputText placeholder="Hora inicio"/> -->
                                    
                                          <p-calendar
                                          [ngModelOptions]="{standalone: true}"
                                          [(ngModel)]="hora_inicio" 
                                          [iconDisplay]="'input'" 
                                          [showIcon]="true" 
                                          [timeOnly]="true" 
                                          [minDate]="minInicio"
                                          [maxDate]="maxInicio"
                                          >
                                                <ng-template pTemplate="inputicon" let-clickCallBack="clickCallBack">
                                                      <i 
                                                          class="pi pi-clock pointer-events-none pointer-events-none" 
                                                          (click)="clickCallBack($event)"></i>
                                                  </ng-template>
                                          </p-calendar>
                                          
                                          
                                    </span>
                              </div>
                              <div class="field col-3">
                                    <label htmlFor="amount">Hasta</label>
                                    <span class="col-12" class="p-input-icon-left">
                                          <!-- <i class="pi pi-stopwatch"></i>
                                          <p-inputNumber [ngModelOptions]="{standalone: true}" suffix="PM" [min]="12" [max]="24" [(ngModel)]="hora_fin " type="number" pInputText placeholder="Hora fin"/> -->
                                          <p-calendar
                                          [ngModelOptions]="{standalone: true}"
                                          [(ngModel)]="hora_fin" 
                                          [iconDisplay]="'input'" 
                                          [showIcon]="true" 
                                          [timeOnly]="true" 
                                          [minDate]="minFinal"
                                          [maxDate]="maxFinal"
                                          >
                                                <ng-template pTemplate="inputicon" let-clickCallBack="clickCallBack">
                                                      <i 
                                                          class="pi pi-clock pointer-events-none pointer-events-none" 
                                                          (click)="clickCallBack($event)"></i>
                                                  </ng-template>
                                          </p-calendar>
                                    </span>
                              </div>

                              <!-- <p-calendar [showTime]="true" [showSeconds]="true" [showOtherMonths]="false"
                              [selectOtherMonths]="false" [showSeconds]="false"></p-calendar> -->
                        </div>
                        

                        <div class="p-fluid grid">
                              <!-- <div class="field col-10"> 
      
                              </div> -->
      
                              <div class="field col-2">
                                    
                                          <p-button (click)="updateValues()" label="Guardar" [raised]="false" severity="success" icon="pi pi-save" iconPos="right"></p-button>
                                    
                              </div>
                        </div>

                       </form>
                        
                  
            </div>
      </div>
</div>