import { Component, OnInit, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FilterService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { ToolbarModule } from 'primeng/toolbar';
import { CatalogUsersService } from 'src/app/catalog-users/service/catalogUsers.service';
import { FormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { en } from '@fullcalendar/core/internal-common';


@Component({
	selector: 'app-sales-history',
	standalone: true,
	imports: [
    ToolbarModule, TableModule, InputTextModule, ButtonModule,CalendarModule, ReactiveFormsModule, FormsModule
	],
	templateUrl: './sales-history.component.html',
	styleUrl: './sales-history.component.scss'
})
export class SalesHistoryComponent implements OnInit{

	catalogUsers = inject(CatalogUsersService)
	
	cols: any[] = [];
	selectedProducts: any[] = [];
	products = [
	]

	originalProducts = [
	]
	
	rangeDates = []; 

	es:any;

	month: number;
	year: number;

	constructor(private filterService: FilterService) { }
	ngOnInit(): void {
	const { month, year } = this.getCurrentMonthAndYear();
	this.month = month;
	this.year = year;
	this.getSaldos(month, year);
	this.cols = [
			{ field: 'nombre', header: 'Nombre' },
			{ field: 'horas', header: 'Horas' },
			{ field: 'direccion', header:'Dirección'}
	];
	}

	changeMonth(event:any){

		console.log(event)

		let month = event.month;
		let year = event.year;

		this.getSaldos(month, year);
	}

	getCurrentMonthAndYear(): { month: number, year: number } {
		const now = new Date();
		const month = now.getMonth() + 1; // getMonth() returns month index starting from 0
		const year = now.getFullYear();
		// console.log(month, year)
		return { month, year };
	}
	

	getSaldos(month:number, year:number){
		this.catalogUsers.getHistorial(month,year).subscribe((data: any)=>{
			this.products = data
			this.products.sort((a, b) => (a.id > b.id ? -1 : 1));

			this.originalProducts = this.products;

			// console.log(this.products)
		})
	}

	filterProducts(avent:any): void {
		console.log(avent)
		const [startDate, endDate] = this.rangeDates.map(this.parseDate);

		console.log(startDate)
		console.log(endDate)

		const filteredProducts = this.originalProducts.filter(product => {
			const productDate = this.parseDate(product.fecha);
			return productDate >= startDate && productDate <= endDate;
		});

		this.products = filteredProducts; 
	}

	clearProducts(){
		// this.products = this.originalProducts;
		this.getSaldos(this.month, this.year);
	}

	async todayProducts(){
		this.getSaldos(this.month, this.year);

		// this.filterToday();

		setTimeout(
			() => {
			this.filterToday()
			},
			500 );
		// console.log(this.rangeDates)


		// const today = new Date();
        // const todayString = `${today.getDate().toString().padStart(2, '0')}/${(today.getMonth() + 1).toString().padStart(2, '0')}/${today.getFullYear()}`;

        // this.products = this.originalProducts.filter(product => product.fecha === todayString);
		

		// this.getSaldos(this.month, this.year);

	}

	filterToday(){
		let today = this.rangeDates
		today[1] = this.rangeDates[0];

		const [startDate, endDate] = today.map(this.parseDate);
		
		const filteredProducts = this.originalProducts.filter(product => {
			const productDate = this.parseDate(product.fecha);
			return productDate >= startDate && productDate <= endDate;
		});

		this.products = filteredProducts; 
	}

	parseDate(dateString: string): Date {
		// console.log(dateString)
		const [day, month, year] = dateString.split('/').map(Number);
		return new Date(year, month - 1, day);
	}	
}

