<div class="grid">
	<div class="col-12">

		<div class="card px-6 py-6"> 

			<ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
				<li>
					<a class="text-500 no-underline line-height-3 cursor-pointer">Home</a>
				</li>
				<li class="px-2">
					<i class="pi pi-angle-right text-500 line-height-3"></i>
				</li>
				<li>
					<a class="text-500 no-underline line-height-3 cursor-pointer">Auditor Web</a>
				</li>
				<li class="px-2">
					<i class="pi pi-angle-right text-500 line-height-3"></i>
				</li>
				<li>
					<span class="text-900 line-height-3">Bitacora de multas</span>
				</li>
			</ul>
			<br>
                  <p-toast></p-toast>
			<p-toolbar styleClass="mb-4">
				<ng-template pTemplate="left">

					<span class="block mt-2 md:mt-0 p-input-icon-left">
						<i class="pi pi-search"></i>
						<input pInputText type="text" (input)="dtBitacora.filterGlobal($event.target.value, 'contains')" placeholder="Buscar..."  class="w-full sm:w-auto"/>
					</span>
                              <span class="block ml-3 mt-2 md:mt-0 p-input-icon-left">
						<i class="pi pi-calendar"></i>
						<p-calendar clear
						placeholder="Fecha" 
						[(ngModel)]="rangeDates" 
						selectionMode="range" 
						dataType="string"
						dateFormat="dd/mm/yy"
						[showButtonBar]="true"
						(onSelect)="filterProducts($event)"
                                    (onMonthChange)="changeMonth($event)"
						(onClearClick)="clearProducts()"
						(onTodayClick)="todayProducts()"/>
					</span>
						<!-- <div class="my-2">
								<button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success mr-2" (click)="openNew()"></button>
								<button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger" (click)="deleteSelectedProducts()" [disabled]="!selectedProducts || !selectedProducts.length"></button>
						</div> -->
				</ng-template>
		</p-toolbar>

        <p-table #dtBitacora [value]="bitacora" responsiveLayout="scroll" [rows]="10" [globalFilterFields]="['campo','fecha','usuario','hora']" 
		[paginator]="true" [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true" currentPageReportTemplate="Monstrando {first} a {last} de {totalRecords} registros" 
		selectionMode="multiple" [rowHover]="true" dataKey="id">

		<ng-template pTemplate="header">
			<tr>
					<th pSortableColumn="fecha">Fecha <p-sortIcon field="fecha"></p-sortIcon></th>
                    <th pSortableColumn="hora">Hora</th>
					<th>Usuario</th>
					<th>Movimiento</th>
                    <th></th>
            </tr>
	</ng-template>

	<ng-template pTemplate="body" let-product>
		<tr>
				<td style="width:5%">
						{{product.fecha}}
				</td>

                <td style="width:5%">
                        {{product.hora}}
                </td>

                <td style="width:10%">
                    {{product.usuario}}
                </td>

                <td style="width:10%">

                  @if (product.movimiento == 'ACTUALIZACIÓN') {
                       COBRO DE MULTA       
                  }

                  @else {
                    {{product.movimiento}}
                  }

                </td>

                <td style="width:2%;"> 
                    <button  pTooltip="Detalle de multa"  pButton pRipple icon="pi pi-eye" class="p-button-rounded p-button-info mr-5" (click)="showDialog(product)"></button>
                </td>
		</tr>
</ng-template>

		</p-table>

		</div>
	</div>
</div>



<p-dialog header="Detalle de multa" [(visible)]="visibleModal" (onHide)="visibleModal=false" [modal]="true" [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">
    <div class="p-fluid grid">
          <div class="field col-8">
                <label htmlFor="amount">Vehiculo</label>
                <span class="p-input-icon-left">
                      <i class="pi pi-car"></i>
                      <input type="text" [(ngModel)]="vehiculo" pInputText disabled/>
                </span>
          </div>
          <div class="field col-4">
                <label htmlFor="amount">Tipo</label>
                <span class="p-input-icon-left">
                      <i class="pi pi-info-circle"></i>
                      <input type="text" pInputText [(ngModel)]="tipoMulta" disabled/>
                </span>
                <!-- <p-dropdown  [options]="multas" [(ngModel)]="selectedMulta" optionLabel="tipo" [showClear]="true" placeholder="Selecciona un rol">
                      <i class="pi pi-user"></i>
                </p-dropdown> -->
          </div>
    </div>

    <div class="p-fluid grid">

          <div class="field col-3">
                <label htmlFor="amount">Monto</label>
                <span class="p-input-icon-left">
                      <i class="pi pi-money-bill"></i>
                      <input type="number" pInputText [(ngModel)]="monto" disabled/>
                </span>
          </div>

          <div class="field col-4">
                <label htmlFor="amount">Agente</label>
                <span class="p-input-icon-left">
                      <i class="pi pi-user"></i>
                      <input type="text" pInputText [(ngModel)]="agente" disabled/>
                </span>
          </div>

          <div class="field col-5">
            <label htmlFor="amount">Folio</label>
            <span class="p-input-icon-left">
                  <i class="pi pi-info-circle"></i>
                  <input type="text" [(ngModel)]="folio" pInputText disabled/>
            </span>
      </div>

    </div>
</p-dialog>
