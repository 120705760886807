import { Component, OnInit, inject } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DividerModule } from 'primeng/divider';
import { FieldsetModule } from 'primeng/fieldset';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SystemService } from '../../service/system.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { InputNumberModule } from 'primeng/inputnumber';

interface Parametros{
  municipio: string;
  eslogan: string;
  letrero: string;
  municipioSegundaLinea: string;
  esloganSegundaLinea: string;
  // fundamento: string;
  // footerMulta: string;
  // costoMulta: number;
  // costoMaximoMulta;
}

@Component({
  selector: 'app-ticket-params',
  standalone: true,
  imports: [
    FieldsetModule, 
    InputTextModule, 
    ButtonModule, 
    DividerModule,
    InputTextareaModule,
    ToastModule, 
    ReactiveFormsModule,
    FormsModule,
    InputNumberModule
  ],
  providers:[MessageService],
  templateUrl: './ticket-params.component.html',
  styleUrl: './ticket-params.component.scss'
})
export class TicketParamsComponent implements OnInit{
  systemService = inject(SystemService)

  municipio: string = '';
  municipioSegundaLinea: string = '';
  eslogan: string = '';
  esloganSegundaLinea: string = '';
  letrero: string = '';
  fundamento: string = '';
  footerMulta: string = '';
  costoMulta: number = 0;
  costoMaximoMulta: number = 0;

  parametrosForm: FormGroup;

  municipioMaxlength: number = 30;
  esloganMaxlength: number = 30;
  textoMaxlength: number = 50;

  userName: string;

  currentValues: Parametros;

  constructor(
    private formBuilder: FormBuilder,
		private messageService: MessageService,
  ){}
  ngOnInit(){
    this.getParametrosTicket();
    this.createForm();
    this.getUserData();
  }

    createForm(): FormGroup{
		return this.parametrosForm = this.formBuilder.group({
			municipio: ['',],
      municipioSegundaLinea: ['',],
      esloganSegundaLinea: ['',],
			letrero: ['',],
      eslogan: ['',],
			// fundamento: ['',],
			// footerMulta: ['',],
			// costoMulta: [0, ],
      // costoMaximoMulta:[0,],
		});
	}

  updateValues(){

    // if(this.costoMulta>this.costoMaximoMulta){

    //   this.addWarning('error',`El costo de la multa debe ser menor 
    //   al costo del código hacendario`,'Advertencia')

    // }else{

    let saveValue;
    
    this.parametrosForm.controls['municipio'].setValue(this.municipio)
    this.parametrosForm.controls['eslogan'].setValue(this.eslogan)
    this.parametrosForm.controls['letrero'].setValue(this.letrero)
    this.parametrosForm.controls['municipioSegundaLinea'].setValue(this.municipioSegundaLinea)
    this.parametrosForm.controls['esloganSegundaLinea'].setValue(this.esloganSegundaLinea)
    // this.parametrosForm.controls['fundamento'].setValue(this.fundamento)
    // this.parametrosForm.controls['footerMulta'].setValue(this.footerMulta)
    // this.parametrosForm.controls['costoMulta'].setValue(this.costoMulta)
    // this.parametrosForm.controls['costoMaximoMulta'].setValue(this.costoMaximoMulta)

    saveValue = this.parametrosForm.value

    let modifiedFields = this.getModifiedFields(this.currentValues, saveValue);
    console.log("modifiedFields",modifiedFields)

    this.systemService.updateParametrosTicket(saveValue).subscribe({
      next: (data)=>{

        this.addWarning('success','Datos actualizados correctamente','Notificación')
        setTimeout(() => {
          this.getParametrosTicket();
        }, 1000);


        this.systemService.postModifiedFields(modifiedFields).subscribe(
          (data) => {
            console.log("modifiedFields",data)
          }
        )

      }, error: (error)=>{
        this.addWarning('error','Error al actualizar parametros','Advertencia')
      }
    })

    // }
  }

  getParametrosTicket(){
    this.systemService.getParametrosTicket().subscribe({
      next: (data : Parametros) =>{

        this.municipio = data.municipio
        this.eslogan = data.eslogan
        this.letrero = data.letrero
        this.municipioSegundaLinea = data.municipioSegundaLinea
        this.esloganSegundaLinea = data.esloganSegundaLinea
        // this.fundamento = data.fundamento
        // this.footerMulta = data.footerMulta
        // this.costoMulta = data.costoMulta
        // this.costoMaximoMulta = data.costoMaximoMulta

        this.currentValues = data;

      }, error: (error) =>{
        this.addWarning('error','Error al obtener los parametros','Advertencia')
      }
    })
  }

  getModifiedFields(currentValues: any, updateValues: any): any[] {
    const modifiedFields: any[] = [];

    for (const key in currentValues) {
        if (currentValues.hasOwnProperty(key) && updateValues.hasOwnProperty(key)) {
            if (currentValues[key] !== updateValues[key]) {
                modifiedFields.push({
                    campo: key,
                    valor_anterior: currentValues[key],
                    valor_actual: updateValues[key],
                    usuario: this.userName
                });
            }
        }
    }

    return modifiedFields;
}

getUserData(){
  let id = sessionStorage.getItem('id');
  this.systemService.getUsuario(Number(id)).subscribe({
    next: (data) => {
      this.userName = data.nombre
    }
  })
}

  addWarning(severity: string,message: string, resumen: string){
		this.messageService.add({ severity: severity, summary: resumen, 
		detail: message });
	}
}
