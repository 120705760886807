import { Product } from './../../../demo/api/product';
import { Component, OnInit, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { AuditService } from '../../service/audit.service';
import { CalendarModule } from 'primeng/calendar';
import { TooltipModule } from 'primeng/tooltip';

interface Bitacora {
  id:       number;
  usuario:  string;
  movimiento: string;
  multa:any;
  fecha:    string;
  hora:     string;
}

@Component({
  selector: 'app-bitacora-multas',
  standalone: true,
  imports: [
    ToolbarModule, 
    TableModule, 
    ButtonModule, 
    InputTextModule, 
    DialogModule, 
    DropdownModule, 
    FormsModule, 
    ReactiveFormsModule,
    ToastModule,
    InputTextareaModule,
    CalendarModule,
    FormsModule,
    TooltipModule
  ],
  providers:[MessageService],
  templateUrl: './bitacora-multas.component.html',
  styleUrl: './bitacora-multas.component.scss'
})
export class BitacoraMultasComponent implements OnInit{
  auditService = inject(AuditService);
  
  bitacora: Bitacora[] = [];
  originalProducts: Bitacora[] = [];

  rangeDates = []; 
  
  visibleModal: boolean = false;

  vehiculo: string | undefined
  tipoMulta: string | undefined
  agente: string | undefined

  monto: number | undefined
  idMulta: number | undefined

  pagada: boolean | undefined

  folio: string | undefined

  month: number; 
  year: number;

  constructor(){}
  ngOnInit(){
    const { month, year } = this.getCurrentMonthAndYear();
	  this.month = month;
	  this.year = year;
    this.getBitacora(month, year);
  }

  getBitacora(month:number, year:number){
    this.auditService.getBitacoraMultas(month,year).subscribe((data:Bitacora[])=>{
      this.bitacora = data.sort((a, b) => b.id - a.id);
      this.originalProducts = this.bitacora;
    })
  }
  getCurrentMonthAndYear(): { month: number, year: number } {
		const now = new Date();
		const month = now.getMonth() + 1; // getMonth() returns month index starting from 0
		const year = now.getFullYear();
		// console.log(month, year)
		return { month, year };
	}

  changeMonth(event:any){

		console.log(event)

		let month = event.month;
		let year = event.year;

		this.getBitacora(month, year);
	}

  showDialog(product: any): void{
    console.log(product);

    this.vehiculo = `${product?.multa.placa} ${product.multa.marca} ${product.multa.modelo}`;
    this.tipoMulta = product.multa.tipo;
    this.monto = product.multa.monto;
    this.agente = product.multa.agente.nombre;
    this.folio = product.multa.folio_municipio;

    this.visibleModal = true;
  }

  filterProducts(avent:any): void {

		const [startDate, endDate] = this.rangeDates.map(this.parseDate);

    console.log(this.rangeDates)

		// console.log(startDate)
		// console.log(endDate)

		const filteredProducts = this.originalProducts.filter(product => {
			const productDate = this.parseDate(product.fecha);
			return productDate >= startDate && productDate <= endDate;
		});

		this.bitacora = filteredProducts; 
	}

	clearProducts(){
		// this.bitacora = this.originalProducts;
    this.getBitacora(this.month, this.year);
	}

	todayProducts(){
    this.getBitacora(this.month, this.year);
    setTimeout(
      () => {
        this.filterToday();
      },
      500
    );
	}

  filterToday(){
    let today = this.rangeDates
		today[1] = this.rangeDates[0];

		const [startDate, endDate] = today.map(this.parseDate);
		
		const filteredProducts = this.originalProducts.filter(product => {
			const productDate = this.parseDate(product.fecha);
			return productDate >= startDate && productDate <= endDate;
		});

		this.bitacora = filteredProducts; 
  }

	parseDate(dateString: string): Date {
		const [day, month, year] = dateString.split('/').map(Number);
		return new Date(year, month - 1, day);
	}	
}
