import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URLS } from 'src/app/auth/shared/models/apiURL';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuditService {

  constructor(private http: HttpClient) {}

  httpOptions = {
        headers: new HttpHeaders({
              'Content-Type': 'application/json'
        })
  };

  public getToken(){
        return sessionStorage.getItem("token");
  }

  getAuditoriasTotal(month:number, year:number){
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
    return this.http.get<any>(environment.API_URL + API_URLS.GET_AUDITORIAS + `?mes=${month}&anio=${year}`, this.httpOptions);
  }

  getIncidencias(id:number){
      this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
      return this.http.get<any>(environment.API_URL + API_URLS.GET_AUDITORIAS + "/incidencias/" +id ,this.httpOptions);
    }

  getBitacoraParametros(month:number, year:number){
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
    return this.http.get<any>(environment.API_URL + API_URLS.GET_BITACORA_PARAMETROS + `?mes=${month}&anio=${year}`, this.httpOptions);
  }

  getTicketsByAuditoria(id:number){
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
    return this.http.get<any>(environment.API_URL + API_URLS.GET_AUDITORIAS + "/tickets/" +id ,this.httpOptions);
  }

  getBitacoraVentasSaldo(month:number, year:number){
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
    return this.http.get<any>(environment.API_URL + API_URLS.GET_BITACORA_VENTAS + `?mes=${month}&anio=${year}`, this.httpOptions);
  }

  getBitacoraMultas(month:number, year:number){
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
    return this.http.get<any>(environment.API_URL + API_URLS.GET_BITACORA_MULTAS + `?mes=${month}&anio=${year}`, this.httpOptions);
  }

  getBitacoraVendedores(month:number, year:number){
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
    return this.http.get<any>(environment.API_URL + API_URLS.GET_BITACORA_VENDEDORES + `?mes=${month}&anio=${year}`, this.httpOptions);
  }

}