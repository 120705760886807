import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, throwError } from "rxjs";


@Injectable({
      providedIn: 'root'
    })
    export class ErrorInterceptorService implements HttpInterceptor{
    
      constructor() { }
      
      intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
          catchError(error => {
            console.error(error);
            return throwError(()=>error);
          })
        )
      }
    }