import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { TicketSaleService } from '../../service/ticket-sales.service';
import { DatePipe } from '@angular/common';
import { MessageService } from 'primeng/api';
import { ToolbarModule } from 'primeng/toolbar';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { DividerModule } from 'primeng/divider';


interface Amount {
  id: number;
  amount: string;
}

interface Agente{
	id_usuario_plataforma: number,
	nombre: string,
	email: string,
  direccion: string,
	rol: string,
}

interface Costos{
  porcentajeGanancia: number
  costoAuto: number
  costoMoto: number
}

@Component({
  selector: 'app-ticket-sales',
  standalone: true,
  imports: [
    ToolbarModule, 
    InputTextModule, 
    ButtonModule, 
    DialogModule, 
    DropdownModule, 
    FormsModule,
    ReactiveFormsModule,
    ToastModule,
    DividerModule,
  ],
  templateUrl: './ticket-sales.component.html',
  styleUrl: './ticket-sales.component.scss',
  providers:[DatePipe, MessageService]
})
export class TicketSalesComponent implements OnInit{

  ticketService = inject(TicketSaleService);

  selectedProduct: any | undefined;
  date: any;

  selectedAmount: Amount | undefined;

  ventaTicketsForm: FormGroup

  products: Agente[] | undefined;

  amount = [];

  folio: string = "190109742612"

  porcentajeGanacia: number = 0;
  costoAuto: number = 0;
  costoMoto: number = 0;
  saldoAplicado: number;
  idMunicipal!: number;

  porcentajeEtiqueta: string;

constructor(private datePipe: DatePipe, private formBuilder: FormBuilder,
  private messageService: MessageService) {}

  ngOnInit(){
    this.getAgentesTicket();
    this.getDate();
    this.createForm();
    this.getPaquetesSaldo();
    this.getCostos();
    this.getId();
  }

  getId(){
      this.idMunicipal =  Number(sessionStorage.getItem('id'))
      console.log(this.idMunicipal)
  }

  selectAmount(value: any){
    let cantidad = value.amount;
    let total = this.getSaldoAplicado(cantidad,this.porcentajeGanacia)

    this.saldoAplicado = total;
  }

  getSaldoAplicado(cantidad: number, porcentajeUtilidad: number): number {
    if (typeof cantidad !== 'number' || typeof porcentajeUtilidad !== 'number') {
      throw new Error('Los parámetros deben ser números');
    }
    const ganancia = (cantidad * porcentajeUtilidad) / 100;
    const totalConGanancia = cantidad + ganancia;
  
    return totalConGanancia;
  }

  getCostos(){
    this.ticketService.getCostos().subscribe((data: Costos)=>{
        this.porcentajeGanacia = data.porcentajeGanancia
        this.porcentajeEtiqueta = `${data.porcentajeGanancia}%`
        this.costoAuto = data.costoAuto;
        this.costoMoto = data.costoMoto;
    })
  }

  createForm(): FormGroup{
		return this.ventaTicketsForm = this.formBuilder.group({
			paquete: ['',],
      agente: ['',],
      folio: ['',Validators.required],
      usuarioMunicipio: ['',],
		});
	}

  getPaquetesSaldo(){
    this.ticketService.getPaquetesSaldo().subscribe((data)=>{
      if(!data)return;

      const listaTransformada = data.map((numero) => {
        return { amount: numero };
      });

      this.amount = listaTransformada;
    })
  }

  getAgentesTicket(){
    this.ticketService.getAgentesTicket().subscribe((data: Agente[])=>{
      this.products = data;
      this.products.sort((a, b) => (a.id_usuario_plataforma < b.id_usuario_plataforma ? -1 : 1));

      console.log(this.products)
    })
  }

  getDate(){
    const currentDate = new Date();
    const formattedDate = this.datePipe.transform(currentDate, 'dd-MM-yyyy');
    this.date = formattedDate;
  }

  registrarVenta(){
    if(this.ventaTicketsForm.valid && this.saldoAplicado && this.selectedProduct){

      this.ventaTicketsForm.controls['paquete'].setValue(this.saldoAplicado)
      this.ventaTicketsForm.controls['agente'].setValue(this.selectedProduct.idUsuarioPlataforma)
      this.ventaTicketsForm.controls['usuarioMunicipio'].setValue(this.idMunicipal)

			let saveValue = this.ventaTicketsForm.value;
      JSON.stringify(saveValue)
      
      this.ticketService.postVentaPaquete(saveValue).subscribe({
        next: (data) =>{
          this.addWarning("success","Venta registrada correctamente","Notificación")

          let bitacora = {
            usuario: sessionStorage.getItem('nombre'),
            vendedor: this.selectedProduct.nombre,
            movimiento: 'VENTA DE SALDO',
            saldo: this.saldoAplicado,
          }
          
          this.ticketService.postBitacoraVentas(bitacora).subscribe({
            next: (data) => {
              console.log(data)
            }
          })

          this.ventaTicketsForm.reset();
          this.selectedAmount = undefined;
          this.selectedProduct = undefined
          this.saldoAplicado = undefined; 
  
        }, error: (errorData) => {

          this.addWarning("error",errorData.error.message,"Error")
          console.log(errorData)
          
        },complete: () => {
          
        }
      })

    }else{
      this.addWarning("warn",'Falta ingresar información', "Advertencia")
      console.log(this.ventaTicketsForm.value)
    }
  }

  addWarning(severity: string,message: string, resumen: string){
		this.messageService.add({ severity: severity, summary: resumen, 
		detail: message });
	}

}
