import { TicketSalesComponent } from './ticket-sales/components/ticket-sales/ticket-sales.component';
import { RouterModule } from '@angular/router';
import { NgModule, Component } from '@angular/core';
import { LoginComponent } from './auth/components/login/login.component';
import { SignInComponent } from './auth/components/sign-in/sign-in.component';
import { AppLayoutComponent } from './layout/app.layout.component';
import { TicketUsersComponent } from './catalog-users/components/ticket-users/ticket-users.component';
import { AgentUsersComponent } from './catalog-users/components/agent-users/agent-users.component';
import { ConfigurationComponent } from './system/components/configuration/configuration.component';
import { SystemUsersComponent } from './system/components/system-users/system-users.component';
import { CitizenPaymentComponent } from './system/components/citizen-payment/citizen-payment.component';
import { PenaltyPaymentComponent } from './penalty-payment/components/penalty-payment/penalty-payment.component';
import { loginGuard } from './guards/login.guard';
import { SalesHistoryComponent } from './ticket-sales/components/sales-history/sales-history.component';
import { TicketParamsComponent } from './system/components/ticket-params/ticket-params.component';
import { PenaltyReasonComponent } from './system/components/penalty-reason/penalty-reason.component';
import { AuditReportComponent } from './audit/components/audit-report/audit-report.component';
import { BitacoraComponent } from './audit/components/bitacora/bitacora.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { PenaltyParamsComponent } from './system/components/penalty-params/penalty-params.component';
import { ParamsConfigComponent } from './system/components/params-config/params-config.component';
import { BitacoraTicketParamsComponent } from './audit/components/bitacora-ticket-params/bitacora-ticket-params.component';
import { BitacoraMultaParamsComponent } from './audit/components/bitacora-multa-params/bitacora-multa-params.component';
import { BitacoraVentasComponent } from './audit/components/bitacora-ventas/bitacora-ventas.component';
import { BitacoraMultasComponent } from './audit/components/bitacora-multas/bitacora-multas.component';
import { BitacoraVendedoresComponent } from './audit/components/bitacora-vendedores/bitacora-vendedores.component';
import { AgentsComponent } from './catalog-users/components/agents/agents.component';
import { TimezoneComponent } from './timezone/timezone/timezone.component';


@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path:'', component: LoginComponent,
            },
            {
                path: 'home', component: AppLayoutComponent,
                canActivate:[loginGuard],
                children:[
                    { path:'usuarios-ticket', component:TicketUsersComponent},
                    { path:'agentes', component:AgentUsersComponent},
                    { path:'venta-ticket', component:TicketSalesComponent},
                    { path:'configuracion', component: ConfigurationComponent},
                    { path:'usuarios-sistema', component: SystemUsersComponent},
                    { path:'parametros', component:CitizenPaymentComponent},
                    { path:'auditorias', component: AuditReportComponent},
                    { path:'cobro-multas', component:PenaltyPaymentComponent}, 
                    { path:'historial-ventas', component: SalesHistoryComponent},
                    { path:'parametros-ticket', component:TicketParamsComponent},
                    { path: 'motivos-multa', component:PenaltyReasonComponent},
                    { path: 'bitacora-parametros', component: BitacoraComponent},
                    { path: 'cambiar-contrasena', component: ResetPasswordComponent},
                    { path: 'parametros-multa', component: PenaltyParamsComponent},
                    { path: 'configuracion-parametros', component:ParamsConfigComponent},
                    { path: 'bitacora-ticket-venta', component:BitacoraTicketParamsComponent},
                    { path: 'bitacora-ticket-multa', component:BitacoraMultaParamsComponent},
                    { path: 'bitacora-ventas', component:BitacoraVentasComponent},
                    { path: 'bitacora-multas', component:BitacoraMultasComponent},
                    { path: 'bitacora-vendedores', component: BitacoraVendedoresComponent},
                    { path: 'agentes-multas', component: AgentsComponent},
                    { path: 'configuracion-tiempo', component: TimezoneComponent}
                ]
            },{path: '**', redirectTo: '/notfound'}
            
        ], { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
