import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API_URLS } from "src/app/auth/shared/models/apiURL";
import { environment } from "src/environments/environment";

interface Agente{
      nombre: string;
      email: string;
      password: string;
      telefono: string;
      direccion?: string;
      rol: string;
}

@Injectable({
      providedIn: 'root'
})
export class CatalogUsersService{

      constructor(private http: HttpClient) { 

      }

      httpOptions = {
            headers: new HttpHeaders({
                  'Content-Type': 'application/json'
            })
      };

      public getToken(){
            return sessionStorage.getItem("token");
      }

      postNuevoAgente(agente: Agente){
            this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
            return this.http.post<any>(environment.API_URL + API_URLS.REGISTRAR_AGENTE, agente,this.httpOptions);
      }

      getAgentes(){
            this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
            return this.http.get<any>(environment.API_URL + API_URLS.GET_LISTADO_AGENTES, this.httpOptions);
      }

      getAgentesMultas(){
            this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
            return this.http.get<any>(environment.API_URL + API_URLS.GET_LISTADO_AGENTES_MULTAS, this.httpOptions);
      }

      getSaldos(){
            this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
            return this.http.get<any>(environment.API_URL + API_URLS.GET_SALDOS_AGENTES, this.httpOptions);
      }

      getHistorial(month: number, year: number){
            this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
            return this.http.get<any>(environment.API_URL + API_URLS.GET_HISTORIAL + sessionStorage.getItem("id") +
                  `?mes=${month}&anio=${year}`, this.httpOptions);
      }

      getHistorialAgente(id: number){
            this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
            return this.http.get<any>(environment.API_URL + API_URLS.GET_HISTORIAL_AGENTE + id, this.httpOptions);
      }

      deleteVenta(id: number){
            this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
            return this.http.delete<any>(environment.API_URL + API_URLS.GET_SALDOS_AGENTES + '/' + id, this.httpOptions);
      }

      borradoLogico(id: number){
            this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
            return this.http.delete<any>(environment.API_URL + API_URLS.BORRADO_LOGICO + id, this.httpOptions);
      }

      getById(id: number){
            this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
            return this.http.get<any>(environment.API_URL + API_URLS.GET_USUARIO + id, this.httpOptions);
      }

      getSaldoAgenteById(id: number){
            this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
            return this.http.get<any>(environment.API_URL + API_URLS.GET_SALDO + id, this.httpOptions);  
      }

      putUser(edit:any){
            this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
            return this.http.put<any>(environment.API_URL + API_URLS.EDIT_USER,edit, this.httpOptions);
      }

      getVendedores(){
            this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
            return this.http.get<any>(environment.API_URL + API_URLS.GET_VENDEDORES, this.httpOptions);
      }

      getVendedor(id:number){
            this.httpOptions.headers = this.httpOptions.headers.set('Authorization',`Bearer ${this.getToken()}`);
            return this.http.get<any>(environment.API_URL + API_URLS.GET_VENDEDORES + "/" + id, this.httpOptions);
      }
}