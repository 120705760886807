
<div class="grid">
    <div class="col-12">
          <div class="card px-6 py-6">
                <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
                      <li>
                            <a class="text-500 no-underline line-height-3 cursor-pointer">Home</a>
                      </li>
                      <li class="px-2">
                            <i class="pi pi-angle-right text-500 line-height-3"></i>
                      </li>
                      <li>
                            <a class="text-500 no-underline line-height-3 cursor-pointer">Administrador general</a>
                      </li>
                      <li class="px-2">
                            <i class="pi pi-angle-right text-500 line-height-3"></i>
                      </li>
                      <li>
                            <span class="text-900 line-height-3 ">Configuración de zona horaria</span>
                      </li>
                </ul>
                <br>

                <p-toast></p-toast>

  
                  <div class="p-fluid grid">
                      <div class="field col-5">
                            <label htmlFor="amount">Zona de horaria</label>
                            <span class="col-12" class="p-input-icon-left">
                              <p-dropdown 
                              [options]="timezones" 
                              [(ngModel)]="selectedZone" 
                              optionLabel="descripcion"
                              placeholder="Selecciona una zona horaria" /> 
                           </span>
                      </div>

                      <!-- <div class="field col-5">
                        <label htmlFor="amount">Valor máximo de multa por código hacendario</label>
                        <span class="col-12" class="p-input-icon-left">
                          
                        <p-inputNumber mode="currency" currency="USD" locale="en-US" 
                        [ngModelOptions]="{standalone: true}"  [(ngModel)]="costoMaximoMulta" type="number" placeholder="Ingresar costo"
                        />
                        
                          </span>
                  </div> -->
                      
                </div>
                    
  
                    <div class="p-fluid grid">
                          <!-- <div class="field col-10"> 
  
                          </div> -->
  
                          <div class="field col-2">
                                
                                      <p-button (click)="setTimeZone()" label="Guardar" [raised]="false" severity="success" icon="pi pi-save" iconPos="right"></p-button>
                                
                          </div>
                    </div>   
                
                
          </div>
    </div>
</div>