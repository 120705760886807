<div class="grid">
	<div class="col-12">

		<div class="card px-6 py-6"> 

			<ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
				<li>
					<a class="text-500 no-underline line-height-3 cursor-pointer">Home</a>
				</li>
				<li class="px-2">
					<i class="pi pi-angle-right text-500 line-height-3"></i>
				</li>
				<li>
					<a class="text-500 no-underline line-height-3 cursor-pointer">Ventas</a>
				</li>
				<li class="px-2">
					<i class="pi pi-angle-right text-500 line-height-3"></i>
				</li>
				<li>
					<span class="text-900 line-height-3">Historial de ventas</span>
				</li>
			</ul>
			<br>

			<p-toolbar styleClass="mb-4">
				<ng-template pTemplate="left">

					<span class="block mt-2 md:mt-0 p-input-icon-left">
						<i class="pi pi-search"></i>
						<input pInputText type="text" (input)="dtHistorial.filterGlobal($event.target.value, 'contains')" 
						placeholder="Buscar vendedor, folio de recibo de pago"  class="w-20rem sm:w-20rem"/>
					</span>

					<span class="block ml-3 mt-2 md:mt-0 p-input-icon-left">
						<i class="pi pi-calendar"></i>
						<p-calendar clear
						placeholder="Fecha" 
						[(ngModel)]="rangeDates" 
						selectionMode="range" 
						dataType="string"
						dateFormat="dd/mm/yy"
						[showButtonBar]="true"
						(onTodayClick)="todayProducts($event)"
						(onMonthChange)="changeMonth($event)"
						(onSelect)="filterProducts($event)"
						(onClearClick)="clearProducts()"
						/>
					</span>

						<!-- <div class="my-2">
								<button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success mr-2" (click)="openNew()"></button>
								<button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger" (click)="deleteSelectedProducts()" [disabled]="!selectedProducts || !selectedProducts.length"></button>
						</div> -->
				</ng-template>

				<!-- <ng-template pTemplate="right">
					<button pButton pRipple label="Agregar" icon="pi pi-plus" class="p-button-success mr-2" ></button>
			</ng-template> -->
		</p-toolbar>

		<p-table #dtHistorial [value]="products" [columns]="cols" responsiveLayout="scroll" [rows]="10" [globalFilterFields]="['agente.nombre','folio_municipio']" 
		[paginator]="true" [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true" currentPageReportTemplate="Monstrando {first} a {last} de {totalRecords} registros" 
		[(selection)]="selectedProducts" selectionMode="multiple" [rowHover]="true" dataKey="id">

		<ng-template pTemplate="header">
			<tr>
					<!-- <th style="width: 3rem">
							<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
					</th> -->
                        <th pSortableColumn="fecha">Fecha <p-sortIcon field="fecha"/></th>
                        <th pSortableColumn="code">Hora</th>
						<th pSortableColumn="code">Folio registro venta</th>
						<th pSortableColumn="code">Folio recibo de pago</th>
					<th pSortableColumn="code">Vendedor</th>
					<th pSortableColumn="code">Dirección</th>
					<th pSortableColumn="name">Saldo vendido</th>
				
			</tr>
	</ng-template>

	<ng-template pTemplate="body" let-product>
		<tr>
            <td	style="width:10%; min-width:8rem;">
                        <span class="p-column-title"></span>
                        {{product.fecha}} 
                        </td>

						<td style="width:5%; min-width:8rem;">
							<span class="p-column-title"></span>
							{{product.hora}}
							</td>

							<td style="width:5%; min-width:10rem;"><span class="p-column-title">Nombre</span>
								{{product.folio_interno}}	
							</td>
							<td style="width:5%; min-width:10rem;"><span class="p-column-title">Nombre</span>
								{{product.folio_municipio}}	
							</td>

				<td style="width:7%; min-width:15rem;"><span class="p-column-title">Nombre</span>
					{{product.agente.nombre}}	
				</td>
				<td style="width:10%; min-width:25rem;">
					<span class="p-column-title"></span>
					{{product.agente.direccion}}
				</td>
				
				<td style="width:5%; min-width:8rem;">
						<span class="p-column-title">Horas</span>
						${{product.paquete}}
				</td>
				<!-- <td>
						<div class="flex justify-content-end">
								<button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-info mr-5" (click)="editProduct(product)"></button>
						</div>
				</td> -->
		</tr>
</ng-template>

		</p-table>
		</div>
	</div>
</div>