import { MessageModule } from 'primeng/message';
import { Component, OnInit, inject } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { Checkbox, CheckboxModule } from 'primeng/checkbox';
import { PasswordModule } from 'primeng/password';
import { JwtUsuarioModel } from '../../models/jwtUsuarioModel';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../service/user.service';
import { JwtUsuarioModelLogin } from '../../models/jwtUsuarioModelLogin';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [PasswordModule, CheckboxModule, ButtonModule, InputTextModule, ReactiveFormsModule, ToastModule],
  templateUrl: './login.component.html',
  providers:[MessageService],
  styles: [`
  :host ::ng-deep .pi-eye,
  :host ::ng-deep .pi-eye-slash {
      transform:scale(1.6);
      margin-right: 1rem;
      color: var(--primary-color) !important;
  }
`]
})
export class LoginComponent implements OnInit{

  public modelo: JwtUsuarioModel;
  public showpassword: boolean = false;
  
  loginForm = this.formBuilder.group({
    email:['',[Validators.required]],
    password: ['',Validators.required],
  })

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private messageService: MessageService
  ){  }

  ngOnInit(){
  }

  get email(){
    return this.loginForm.controls.email;
  }

  get password()
  {
    return this.loginForm.controls.password;
  }

  valCheck: string[] = ['remember'];



  login(){
    if(this.loginForm.valid){
      this.authService.login(this.loginForm.value as JwtUsuarioModelLogin).subscribe({
        
        next: (userData) => {
          console.log(this.loginForm.value)
          console.log(userData);
        },
        error: (errorData) => {
          console.error(errorData);
          console.log(errorData.status);
          this.addWarning('error','Usuario o contraseña incorrectos.')

        },
        complete: () => {
          this.router.navigateByUrl('/home');
          this.loginForm.reset();
        }
      })

    }
    else{

      if(this.loginForm.controls['email'].value == "" && this.loginForm.controls['password'].value !== ""){
        this.addWarning('warn','El campo usuario es requerido')
      }

      if(this.loginForm.controls['password'].value == "" && this.loginForm.controls['email'].value !== ""){
        this.addWarning('warn','El campo contraseña es requerido')
      }

      if(this.loginForm.controls['password'].value == "" && this.loginForm.controls['email'].value == ""){
        this.addWarning('warn','Falta ingresar datos');
      }

    }
  }

  addWarning(severity: string,message: string){
    this.messageService.add({ severity: severity, summary: 'Advertencia', 
    detail: message });
  }

}
