
import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { JsonRequestError } from '@fullcalendar/core';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { Dropdown, DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { CatalogUsersService } from '../../service/catalogUsers.service';
import { DividerModule } from 'primeng/divider';
import { InputMaskModule } from 'primeng/inputmask';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ignoreElements, filter } from 'rxjs';
import { TooltipModule } from 'primeng/tooltip';

interface Rol{
	id:number,
	rol:string,
	clave: string
}

interface Agente{
	id_usuario_plataforma: number,
	nombre: string,
	email: string,
	rol: any,
	rfc:string,
	alias: string
}

@Component({
  selector: 'app-agent-users',
  standalone: true,
  imports: [
	ToolbarModule, 
	TableModule, 
	InputTextModule, 
	ButtonModule, 
	DialogModule, 
	DropdownModule, 
	FormsModule,
	ReactiveFormsModule,
	ToastModule,
	DividerModule,
	InputMaskModule,
	KeyFilterModule,
	TooltipModule
],
 providers:[MessageService],
  templateUrl: './agent-users.component.html',
  styleUrl: './agent-users.component.scss'
})

export class AgentUsersComponent implements OnInit {

	userService = inject(CatalogUsersService);
	
	cols: any[] = [];
	selectedProducts: any[] = [];
	products:Agente[]
	roles = [
		{id:4, rol:"Vendedor de tickets", clave:"AGENTE_TICKETS"},
		// {id:5, rol:"Agente de multas", clave:"AGENTE_MULTAS"},
	]

	tipos = [
		{id:1, tipo:"Fisica"},
		{id:2, tipo:"Moral"},
	]

	selectedRol: Rol | undefined;

	selectedTipo: any;
	
	visibleModal: boolean = false;
	deleteMod: boolean = false;
	editMod: boolean = false;
	warningSaldoMod: boolean = false;
	
	nuevoAgenteForm: FormGroup;
	editForm: FormGroup;  
	

	deleteRol: string | undefined;
	deleteName: string | undefined;
	deleteProd: any;

	saldo: number | undefined;
	editId: number | undefined;

	datosVendedor: any | undefined;	

	constructor(
		private formBuilder: FormBuilder,
		private messageService: MessageService,
	){}

	ngOnInit(): void {
		this.createForm()
		this.getAgentes()
		this.createEditForm()
		// this.getVendedores()

		this.cols = [
			{ field: 'nombre', header: 'Nombre' },
			{ field: 'correo', header: 'Correo' },
			{ field: 'tipo', header: 'Tipo agente'},
		];
	}

	createForm(): FormGroup{
		return this.nuevoAgenteForm = this.formBuilder.group({
			rol: ['',],
			nombre: ['', Validators.required],
			email: ['', Validators.required],
			telefono: ['', Validators.required],
			calle: ['', Validators.required],
			numero: ['', Validators.required],
			colonia: ['', Validators.required],
			cp: ['', Validators.required],
			alias: ['', Validators.required],
			rfc: ['', Validators.required],
			tipo_persona: ['',],
			municipio: ['', Validators.required],
			password: ['', Validators.required],
			passwordRepeat: ['', Validators.required],
		});
	}

	createEditForm(): FormGroup{
		return this.editForm = this.formBuilder.group({
			id: [,],
      		nombre: ['',Validators.required],
			email: ['', Validators.required],
      		telefono: ['',Validators.required],
			  calle: ['',Validators.required],
			  numero: ['',Validators.required],
			  colonia: ['',Validators.required],
			  cp: ['',Validators.required],
			  municipio: ['',Validators.required],
			  rfc: ['', Validators.required],
			  alias: ['', Validators.required],
			  tipo_persona: ['',],
		});
	}

	edit(product: any){
		this.editMod = true;
		this.editId = product.idUsuarioPlataforma;
	
	  this.userService.getById(product.idUsuarioPlataforma).subscribe({
		  next: (data) => {
	
			this.editForm.patchValue({
			  id: ignoreElements,
			  nombre: product.nombre,
			  email: product.email,
			  telefono: data.telefono,
			  calle: data.calle,
			  numero: data.numero_domicilio,
			  colonia: data.colonia,
			  cp: data.codigo_postal,
			  municipio: data.municipio,
			  rfc: data.rfc,
			  alias: data.alias,
			})		
			
			if(data.tipo_persona == "Fisica"){
			  this.selectedTipo = this.tipos[0];
			}

			if(data.tipo_persona == "Moral"){
			  this.selectedTipo = this.tipos[1];
			}
	
		  },
		  error: (errorData) => {
			console.log(errorData);
			this.addWarning('error', "Error al obtener usuario", "Advertencia")
		  },
		  complete: () => {
			
		  }
		}) 
	  }


	getVendedores(){
		this.userService.getVendedores().subscribe({
			next: (data) => {
				console.log(data);
		}, error: (error) => {

		}
	})
	}

	getVendedorById(id:number){
		this.userService.getVendedor(id).subscribe((data)=>{
			console.log(data);
			this.datosVendedor = data;
		})
	}

	cancelDelete(){
		this.deleteMod = false;
		this.deleteProd = undefined;
		this.deleteName = undefined;
		this.deleteRol = undefined;
	  }
	
	  deleteModal(product: any){
		console.log(product);
		
		this.deleteProd = product;
	
		this.deleteName = product.nombre;
		this.deleteRol = product.rol.descripcion;

		if(this.deleteRol == "AGENTE_TICKETS"){
			this.userService.getSaldoAgenteById(this.deleteProd.idUsuarioPlataforma).subscribe({
				next: (data) => {

					console.log(data);
					this.saldo = data.saldo_disponible;

					if(this.saldo > 0){
						this.warningSaldoMod = true;
					}else{
						this.deleteMod = true;
					}

				},error: (error) => {
					console.log(error.error);

					let errorMessage = error.error;

					if(errorMessage.message == "No value present"){
						this.deleteMod = true;
					}
				}
			})
		}else{
			this.deleteMod = true;
		}
	  }

	  closeWarning(){
		this.saldo = undefined;
		this.warningSaldoMod = false;
		this.deleteProd = undefined;
		this.deleteRol = undefined;
		this.deleteName = undefined;
	  }
	
	  deleteProduct(){
		this.userService.borradoLogico(this.deleteProd.idUsuarioPlataforma).subscribe({
		  next: (data) => {
			this.addWarning('success','Usuario eliminado correctamente.','Notificación')
		  },
		  error: (errorData) => {
			console.log(errorData);
			
			this.addWarning('error', "Error al eliminiar usuario", "Advertencia")
	
		  },
		  complete: () => {
			this.getAgentes();
			this.deleteMod = false;
			this.deleteProd = undefined;
			this.deleteName = undefined;
			this.deleteRol = undefined;
			this.saldo = undefined;
		  }
		})
	  }


	cancelEdit(){
		this.editMod = false;
		this.editId = undefined;
		this.saldo = undefined;
		this.datosVendedor = undefined;
		this.selectedTipo = undefined;
		this.editForm.reset();
	}

	saveEdit(){

		if(!this.editForm.valid || !this.selectedTipo){
			this.addWarning('warn','Favor de llenar todos los campos.','Advertencia')
			return
		}

		this.editForm.controls['id'].setValue(this.editId)
		this.editForm.controls['tipo_persona'].setValue(this.selectedTipo.tipo)

		let saveValue = this.editForm.value;

		if(saveValue.rfc.length < 10){
			this.addWarning("warn",'El RFC debe tener al menos 10 caracteres.', "Advertencia")
			return;
		}
		
		saveValue.rfc = saveValue.rfc.toUpperCase();
	
		this.userService.putUser(saveValue).subscribe({
		  next: (data) => {
			this.addWarning('success','Usuario editado correctamente.','Notificación')
		  },
		  error: (errorData) => {
			console.log(errorData);
			
			this.addWarning('error', "Error al editar usuario", "Advertencia")
	
		  },
		  complete: () => {
			this.datosVendedor = undefined;
			this.editMod = false;
			this.editId = undefined;
			this.selectedTipo = undefined;
			this.editForm.reset();
			this.getAgentes();
		  }
		})
	  }


	

	getAgentes(){
		this.userService.getVendedores().subscribe({
			next: (data) =>{

				// let agentes = data.filter((item) => item.rol.descripcion == 'AGENTE_TICKETS');
				this.products = data
				// this.products.sort((a, b) => (a.id_usuario_plataforma < b.id_usuario_plataforma ? -1 : 1));
				this.products.sort((a, b) => a.alias.localeCompare(b.alias));


				console.log(this.products);

			},error(err) {
				this.addWarning("error",'Error al obtener agentes disponibles.', 'Error')
			},complete() {
				
			},
		})
	}


	registrarAgente(){

		if(this.nuevoAgenteForm.valid && this.selectedTipo){

			this.nuevoAgenteForm.controls['rol'].setValue(4)
			this.nuevoAgenteForm.controls['tipo_persona'].setValue(this.selectedTipo.tipo)
			let saveValue = this.nuevoAgenteForm.value;

			if(saveValue.rfc.length < 10){
				this.addWarning("warn",'El RFC debe tener al menos 10 caracteres.', "Advertencia")
				return;
			}


			if(saveValue.password !== saveValue.passwordRepeat){
				this.addWarning("warn",'Las contraseñas deben coincidir.', "Advertencia")
			}else{

			delete saveValue.passwordRepeat;

			saveValue.rfc = saveValue.rfc.toUpperCase();

			JSON.stringify(saveValue);
			// console.log(JSON.stringify(saveValue))
			this.userService.postNuevoAgente(saveValue).subscribe({
				next: (data) => {
					this.getAgentes();
					this.addWarning('success','Agente registrado correctamente.','Notificación')
				},
				error: (errorData) => {
					console.log(errorData);
					
					this.addWarning('error', errorData.error, "Advertencia")

				},
				complete: () => {
					this.nuevoAgenteForm.reset();
					this.selectedRol = undefined;
					this.selectedTipo = undefined;
					this.hideDialog();
				}
			})

			}
			
		}else{
			this.addWarning("warn",'Favor de ingresar todos los campos.', "Advertencia")
		}

	}

	addWarning(severity: string,message: string, resumen: string){
		this.messageService.add({ severity: severity, summary: resumen, 
		detail: message });
	}

	
	showDialog(): void{
		this.visibleModal = true;
	}

	hideDialog(): void{
		this.visibleModal = false;
		this.nuevoAgenteForm.reset();
		this.selectedRol = undefined;
	}

}
