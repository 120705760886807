<div class="grid">
	<div class="col-12">

		<div class="card px-6 py-6"> 

			<ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
				<li>
					<a class="text-500 no-underline line-height-3 cursor-pointer">Home</a>
				</li>
				<li class="px-2">
					<i class="pi pi-angle-right text-500 line-height-3"></i>
				</li>
				<li>
					<a class="text-500 no-underline line-height-3 cursor-pointer">Auditor Web</a>
				</li>
				<li class="px-2">
					<i class="pi pi-angle-right text-500 line-height-3"></i>
				</li>
				<li>
					<span class="text-900 line-height-3">Bitacora de parametros de ventas</span>
				</li>
			</ul>
			<br>
                <p-toast></p-toast>
			<p-toolbar styleClass="mb-4">
				<ng-template pTemplate="left">

					<span class="block mt-2 md:mt-0 p-input-icon-left">
						<i class="pi pi-search"></i>
						<input pInputText type="text" (input)="dtBitacora.filterGlobal($event.target.value, 'contains')" placeholder="Buscar..."  class="w-full sm:w-auto"/>
					</span>

					<span class="block ml-3 mt-2 md:mt-0 p-input-icon-left">
						<i class="pi pi-calendar"></i>
						<p-calendar clear
						placeholder="Fecha" 
						[(ngModel)]="rangeDates" 
						selectionMode="range" 
						dataType="string"
						dateFormat="dd/mm/yy"
						[showButtonBar]="true"
						(onSelect)="filterProducts($event)"
						(onMonthChange)="changeMonth($event)"
						(onClearClick)="clearProducts()"
						(onTodayClick)="todayProducts()"/>
					</span>
						<!-- <div class="my-2">
								<button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success mr-2" (click)="openNew()"></button>
								<button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger" (click)="deleteSelectedProducts()" [disabled]="!selectedProducts || !selectedProducts.length"></button>
						</div> -->
				</ng-template>
		</p-toolbar>

        <p-table #dtBitacora [value]="bitacora" responsiveLayout="scroll" [rows]="10" [globalFilterFields]="['campo','fecha','usuario','hora']" 
		[paginator]="true" [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true" currentPageReportTemplate="Monstrando {first} a {last} de {totalRecords} registros" 
		selectionMode="multiple" [rowHover]="true" dataKey="id">

		<ng-template pTemplate="header">
			<tr>
					<th pSortableColumn="fecha">Fecha<p-sortIcon field="fecha"></p-sortIcon></th>
                    <th>Hora</th>
					<th>Usuario</th>
					<th>Campo</th>
                    <th>Valor anterior</th>
                    <th>Valor actual</th>
			</tr>
	</ng-template>

	<ng-template pTemplate="body" let-product>
		<tr>
				<td style="width:5%">
						{{product.fecha}}
				</td>

                <td style="width:5%">
                        {{product.hora}}
                </td>

                <td style="width:10%">
                    {{product.usuario}}
                </td>

                <td style="width:10%">
					
					@switch (product.campo) {
						@case ("costoAuto") {
							COSTO AUTO
						}

						@case ("costoMoto") {
							COSTO MOTO
						}

						@case ("paqueteDesde") {
							PAQUETE DESDE
						}

						@case ("paqueteHasta") {
							PAQUETE HASTA
						}

						@case ("ticketMaximo") {
							TICKET MAXIMO
						}

						@case ("valorHacendario") {
							VALOR HACENDARIO
						}

						@case ("saldoMaximo") {
							SALDO MAXIMO
						}

						@case ("intervalo") {
							INTERVALO
						}

						@case ("porcentajeGanancia") {
							PORCENTAJE GANANCIA
						}

						@case ("hora_inicio") {
							HORA INICIO
						}

						@case ("hora_fin") {
							HORA FIN
						}

						@default {
							{{product.campo}}
						}
					}

                </td>

                <td style="width:10%">
					@if(product.campo == "costoAuto" || product.campo == "costoMoto" || product.campo == "paqueteDesde" || product.campo == "paqueteHasta"
					|| product.campo == "ticketMaximo" || product.campo == "valorHacendario" || product.campo == "saldoMaximo" || product.campo == "intervalo"){
						${{product.anterior}}
					}

					@else if (product.campo == "porcentajeGanancia") {
						{{product.anterior}}%
					}

					@else {
						{{product.anterior}}
					}
                </td>

                <td style="width:10%">
                    @if(product.campo == "costoAuto" || product.campo == "costoMoto" || product.campo == "paqueteDesde" || product.campo == "paqueteHasta"
					|| product.campo == "ticketMaximo" || product.campo == "valorHacendario" || product.campo == "saldoMaximo" || product.campo == "intervalo"){
						${{product.actual}}
					}

					@else if (product.campo == "porcentajeGanancia") {
						{{product.actual}}%
					}

					@else {
						{{product.actual}}
					}
                </td>
		</tr>
</ng-template>

		</p-table>

		</div>
	</div>
</div>
