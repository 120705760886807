import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { Fieldset, FieldsetModule } from 'primeng/fieldset';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-configuration',
  standalone: true,
  imports: [FieldsetModule, InputTextModule, ButtonModule],
  templateUrl: './configuration.component.html',
  styleUrl: './configuration.component.scss'
})
export class ConfigurationComponent {

}
