import { filter } from 'rxjs';
import { ProductService } from 'src/app/demo/service/product.service';
import { Component, NgModule, OnInit, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { AuditService } from '../../service/audit.service';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CalendarModule } from 'primeng/calendar';
import { CommonModule } from '@angular/common';
import { Tooltip } from 'chart.js';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-audit-report',
  standalone: true,
  imports: [
    ToolbarModule, 
    TableModule, 
    ButtonModule, 
    InputTextModule, 
    DialogModule, 
    DropdownModule, 
    FormsModule, 
    ReactiveFormsModule,
    ToastModule,
    InputTextareaModule,
    CalendarModule,
    FormsModule,
    CommonModule,
    TooltipModule
  ],
  providers:[MessageService],
  templateUrl: './audit-report.component.html',
  styleUrl: './audit-report.component.scss'
})
export class AuditReportComponent implements OnInit{
  auditService = inject(AuditService);

  products: any[] = [];
  originalProducts: any[] = [];
  selectedProducts: any;
  cols: any[] = [];
  incidencias: any[] = [];
  ticketsAuditoria: any[] = [];
  selectedObs:any;

  visibleModal: boolean = false;
  visibleTicket: boolean = false;
  rangeDates = []; 

  month: number;
  year: number;
  
  ngOnInit(){ 

    const { month, year } = this.getCurrentMonthAndYear();
	  this.month = month;
	  this.year = year;

    this.getAuditorias(month, year);

    this.cols = [
			{ field: 'folio', header: 'Folio' },
			{ field: 'nombre', header: 'Nombre' },
			{ field: 'fecha', header: 'Fecha' },
			{ field: 'auditor', header: 'Auditor'},
			{ field: 'accion', header: 'Accion'},
	 ];
  }

  getCurrentMonthAndYear(): { month: number, year: number } {
		const now = new Date();
		const month = now.getMonth() + 1; // getMonth() returns month index starting from 0
		const year = now.getFullYear();
		// console.log(month, year)
		return { month, year };
	}

  getAuditorias(month:number, year:number){
    this.auditService.getAuditoriasTotal(month, year).subscribe((data)=>{
      let auditorias = this.transformAuditorias(data);

      this.products = auditorias.sort((a, b) => b.id - a.id);

      this.originalProducts = this.products;

      console.log(this.products);

    })
  }

  getIncidenciasAuditoria(id:number){
    this.auditService.getIncidencias(id).subscribe((data)=>{
      this.incidencias = data.sort((a, b) => b.id - a.id);
      console.log(this.incidencias);
    })
  }

  async getTicketsByAuditoria(id:number){
  this.auditService.getTicketsByAuditoria(id).subscribe((data) => {
      this.ticketsAuditoria = data.sort((a, b) => b.id - a.id);
      console.log(this.ticketsAuditoria);
    })

    this.visibleTicket = true;
  }

  showIncidencias(product:any){
    this.getIncidenciasAuditoria(product.id);
    this.selectedObs = product.observacion;
    
    this.visibleModal = true;
  }

  transformAuditorias(auditorias: any[]): any[] {
    return auditorias.map(auditoria => {
      if (auditoria.inicio) {
        auditoria.inicio = `${this.formatTime(auditoria.inicio)}` 
      }

      if (auditoria.fin !== null) {
        auditoria.fin =  `${this.formatTime(auditoria.fin)}`;
      }

      return auditoria;
    });
  }
  
  formatTime(timeArray: number[]): string {
    const [hours = 0, minutes = 0, seconds = 0] = timeArray;
    return `${this.padNumber(hours)}:${this.padNumber(minutes)}:${this.padNumber(seconds)}`;
  }
  
  padNumber(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }

  formatDate(date: Date): string {
    const format = new Date(date);
    const day = String(format.getUTCDate()).padStart(2, '0');
    const month = String(format.getUTCMonth() + 1).padStart(2, '0'); // Los meses empiezan desde 0
    const year = format.getUTCFullYear();

    return `${day}/${month}/${year}`;
  }


  filterProducts(avent:any): void {

		const [startDate, endDate] = this.rangeDates.map(this.parseDate);

    console.log(this.rangeDates)

		// console.log(startDate)
		// console.log(endDate)

		const filteredProducts = this.originalProducts.filter(product => {
			const productDate = this.parseDate(product.fecha);
			return productDate >= startDate && productDate <= endDate;
		});

		this.products = filteredProducts; 
	}

	clearProducts(){
		// this.products = this.originalProducts;
    this.getAuditorias(this.month, this.year);
	}

	todayProducts(){
    this.getAuditorias(this.month, this.year);

    setTimeout(
      () => {
        this.filtertoday();
      }, 500);
	}

  filtertoday(){
		let today = this.rangeDates
		today[1] = this.rangeDates[0];

		const [startDate, endDate] = today.map(this.parseDate);
		
		const filteredProducts = this.originalProducts.filter(product => {
			const productDate = this.parseDate(product.fecha);
			return productDate >= startDate && productDate <= endDate;
		});

		this.products = filteredProducts; 
  }

  changeMonth(event:any){

		console.log(event)

		let month = event.month;
		let year = event.year;

		this.getAuditorias(month, year);
	}

	parseDate(dateString: string): Date {
		const [day, month, year] = dateString.split('/').map(Number);
		return new Date(year, month - 1, day);
	}	

}

