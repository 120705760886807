<div class="grid">
	<div class="col-12">

		<div class="card px-6 py-6"> 

			<ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
				<li>
					<a class="text-500 no-underline line-height-3 cursor-pointer">Home</a>
				</li>
				<li class="px-2">
					<i class="pi pi-angle-right text-500 line-height-3"></i>
				</li>
				<li>
					<a class="text-500 no-underline line-height-3 cursor-pointer">Administrador general</a>
				</li>
				<li class="px-2">
					<i class="pi pi-angle-right text-500 line-height-3"></i>
				</li>
				<li>
					<span class="text-900 line-height-3">Usuarios plataforma</span>
				</li>
			</ul>
			<br>
                  <p-toast></p-toast>
			<p-toolbar styleClass="mb-4">
				<ng-template pTemplate="left">

					<span class="block mt-2 md:mt-0 p-input-icon-left">
						<i class="pi pi-search"></i>
						<input pInputText type="text" (input)="dtUsuarios.filterGlobal($event.target.value, 'contains')" placeholder="Nombre, correo"  class="w-full sm:w-auto"/>
					</span>
						<!-- <div class="my-2">
								<button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success mr-2" (click)="openNew()"></button>
								<button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger" (click)="deleteSelectedProducts()" [disabled]="!selectedProducts || !selectedProducts.length"></button>
						</div> -->
				</ng-template>

				<ng-template pTemplate="right">
					<button pButton pRipple label="Agregar" icon="pi pi-plus" class="p-button-success mr-2" (click)="showDialog()"></button>
			</ng-template>
		</p-toolbar>

		<p-table #dtUsuarios [value]="products" [columns]="cols" responsiveLayout="scroll" [rows]="10" [globalFilterFields]="['nombre','correo']" 
		[paginator]="true" [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true" currentPageReportTemplate="Monstrando {first} a {last} de {totalRecords} registros" 
		[(selection)]="selectedProducts" selectionMode="multiple" [rowHover]="true" dataKey="id">

		<ng-template pTemplate="header">
			<tr>
					<th pSortableColumn="code">Nombre <p-sortIcon field="nombre"></p-sortIcon></th>
					<th pSortableColumn="name">Correo <p-sortIcon field="correo"></p-sortIcon></th>
					<th pSortableColumn="name">Rol <p-sortIcon field="rol"></p-sortIcon></th>
                              <th></th>
							  <th></th>
			</tr>
	</ng-template>

	<ng-template pTemplate="body" let-product>
		<tr>
				<td style="width:10%; min-width:18rem;"><span class="p-column-title">Nombre</span>
						{{product.nombre}}
				</td>
				<td style="width:14%; min-width:15rem;">
						<span class="p-column-title">Correo</span>
						{{product.email}}
				</td>
                        <td style="width:14%; min-width:15rem;">
                              <span class="p-column-title">Rol</span>

							  @switch (product.rol.descripcion) {

								@case('SISTEMA'){
									COORDINADOR MUNICIPAL
								}

								@case ('ADMINISTRADOR') {
									ADMINISTRADOR GENERAL
								}
								
								@case ('AUDITOR_WEB') {
									AUDITOR WEB
								}

								@case ('AUDITOR') {
									AUDITOR MOVIL
								}

								@default {
									{{product.rol.descripcion}}

							  }
							}

							  <!-- @if(product.rol.descripcion == 'SISTEMA'){
								COORDINADOR MUNICIPAL
							  }

							  @if(product.rol.descripcion == 'ADMINISTRADOR'){
								ADMINISTRADOR GENERAL
							  }

							  @else {
								{{product.rol.descripcion}}
							  } -->

                        </td>
				<td>
						<div class="flex justify-content-end">
								<button  pTooltip="Actualizar usuario"  pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-info mr-5" (click)="edit(product)"></button>
								<!-- <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger" (click)="deleteProduct(product)"></button> -->
						</div>
				</td>
				<td>
					<div class="flex justify-content-end">
							<button  pTooltip="Desactivar usuario"  pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger mr-5" (click)="deleteModal(product)" [disabled]="product.idUsuarioPlataforma == mainId"></button>
							<!-- <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger" (click)="deleteProduct(product)"></button> -->
					</div>
			</td>
		</tr>
</ng-template>

		</p-table>
		</div>
	</div>
</div>



      <p-dialog header="Registrar usuario" [(visible)]="visibleModal" [modal]="true" [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">
            <form [formGroup]="nuevoUsuarioForm">

                  <div class="p-fluid grid">
                        <div class="field col-4">
                              <label htmlFor="amount">Rol</label>
                              <p-dropdown [ngModelOptions]="{standalone: true}" [options]="roles" [(ngModel)]="selectedRol" optionLabel="rol" [showClear]="true" placeholder="Selecciona un rol"></p-dropdown>
                        </div>
                        <div class="field col-5">
                              <label htmlFor="amount">Nombre</label>
                              <span class="p-input-icon-left">
                                    <i class="pi pi-user"></i>
                                    <input formControlName="nombre" maxlength="30" type="text" pInputText placeholder="Ingresar nombre" />
                              </span>
                        </div>
						<div class="field col-3">
							<label htmlFor="amount">Iniciales</label>
							<span class="p-input-icon-left">
								  <i class="pi pi-exclamation-circle"></i>
								  <input formControlName="iniciales" maxlength="4" type="text" pInputText pKeyFilter="alpha"  placeholder="ABC" />
							</span>
					  </div>
                  </div>
      
                  <div class="p-fluid grid">
      
                        <div class="field col-6">
                              <label htmlFor="amount">Correo</label>
                              <span class="p-input-icon-left">
                                    <i class="pi pi-envelope"></i>
                                    <input maxlength="30" formControlName="email" type="email" [email]="true" pInputText placeholder="Ingresar correo" />
									@if (nuevoUsuarioForm.get('email').invalid && nuevoUsuarioForm.get('email').touched) {
										@if (nuevoUsuarioForm.get('email').errors.required) {
											<small>El correo es requerido</small>
										}
										@if (nuevoUsuarioForm.get('email').errors.email) {
											<small>El correo es invalido</small>
										}
									}
                              </span>
                        </div>
      
                        <div class="field col-6">
                              <label htmlFor="amount">Telefono</label>
                              <span class="p-input-icon-left">
                                    <!-- <i class="pi pi-whatsapp"></i> -->
                                    <!-- <input formControlName="telefono" type="text" pInputText placeholder="Ingresar telefono" /> -->
									<p-inputMask 
   									 mask="(999) 999-9999" 
   									 formControlName="telefono"
   									 placeholder="(000) 000-0000" />

								</span>
                        </div>
      
                  </div>
      
                  <div class="p-fluid grid">
      
                        <div class="field col-6">
                              <label htmlFor="amount">Contraseña</label>
                              <span class="p-input-icon-left">
                                    <i class="pi pi-eye"></i>
                                    <input maxlength="20" formControlName="password"  type="email" pInputText placeholder="Ingresar contraseña" />
                              </span>
                        </div>
      
                        <div class="field col-6">
                              <label htmlFor="amount">Repetir contraseña</label>
                              <span class="p-input-icon-left">
                                    <i class="pi pi-eye"></i>
                                    <input maxlength="20" formControlName="passwordRepeat" type="text" pInputText placeholder="Ingresar contraseña" />
                              </span>
                        </div>
      
                  </div>
      
                  <div class="p-fluid grid">
                        <div class="field col-9"></div>
                        <div class="field col-3">
                              <p-button (click)="registrarUsuario()" label="Guardar" [raised]="false" severity="success" icon="pi pi-save" iconPos="left"></p-button>
                        </div>
                  </div>

            </form>
      </p-dialog>

	  <p-dialog header="Eliminar usuario" [modal]="true" [(visible)]="deleteMod" [style]="{ width: '25rem' }">
		<!-- <span class="p-text-secondary block mb-5">El siguiente será eliminado:</span> -->
		<div class="flex align-items-center gap-3 mb-5">
			<label class="font-semibold w-6rem">Rol</label>
			<input pInputText id="email" class="flex-auto" autocomplete="off" [(ngModel)]="deleteRol" disabled/>
		</div>
		<div class="flex align-items-center gap-3 mb-5">
			<label class="font-semibold w-6rem">Nombre</label>
			<input pInputText id="email" class="flex-auto" autocomplete="off" [(ngModel)]="deleteName" disabled/>
		</div>
		<div class="flex justify-content-end gap-2">
			<p-button label="Cancelar" severity="secondary" (click)="cancelDelete()" />
			<p-button label="Confirmar" (click)="deleteProduct()" />
		</div>
	</p-dialog>

	<p-dialog header="Actualizar usuario" [modal]="true" [(visible)]="editMod" [style]="{ width: '25rem' }">
		<!-- <span class="p-text-secondary block mb-5">El siguiente será eliminado:</span> -->
		<form [formGroup]="editForm">

			<div class="flex align-items-center gap-3 mb-5">
				<label class="font-semibold w-6rem">Correo</label>
				<input maxlength="30" formControlName="email" [email]="true" pInputText id="email" class="flex-auto" autocomplete="off"/>
				@if (editForm.get('email').invalid && editForm.get('email').touched) {
					@if (editForm.get('email').errors.required) {
						<small>El correo es requerido</small>
					}
					@if (editForm.get('email').errors.email) {
						<small>El correo es invalido</small>
					}
				}
			</div>
			<div class="flex align-items-center gap-3 mb-5">
				<label class="font-semibold w-6rem">Nombre</label>
				<input maxlength="25" formControlName="nombre" pInputText pKeyFilter="alpha"  id="email" class="flex-auto" autocomplete="off"/>
			</div>
			<div class="flex align-items-center gap-3 mb-5">
				<label class="font-semibold w-6rem">Iniciales</label>
				<input maxlength="4" formControlName="iniciales" pInputText pKeyFilter="alpha" class="flex-auto" autocomplete="off"/>
			</div>
			<div class="flex align-items-center gap-3 mb-5">
				<label class="font-semibold w-6rem">Telefono</label>
				<!-- <input pInputText id="email" class="flex-auto" autocomplete="off"/> -->
				<p-inputMask 
				mask="(999) 999-9999" 
				formControlName="telefono"
				placeholder="(000) 000-0000" />
			</div>

		</form>
		<div class="flex justify-content-end gap-2">
			<p-button label="Cancelar" severity="secondary" (click)="cancelEdit()" />
			<p-button label="Confirmar" (click)="saveEdit()"/>
		</div>
	</p-dialog>
