import { filter } from 'rxjs';
import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { AuditService } from '../../service/audit.service';
import { CalendarModule } from 'primeng/calendar';
import { TooltipModule } from 'primeng/tooltip';

interface Bitacora {
  id:       number;
  usuario:  string;
  movimiento: string;
  fecha:    string;
  hora:     string;
  vendedor: Vendedor;
}

interface Vendedor {
  alias: string;
  direccion: string;
  email: string;
  id: number;
  nombre: string;
  rfc: string;
  telefono: string;
  tipo_persona: string;
}

@Component({
  selector: 'app-bitacora-vendedores',
  standalone: true,
  imports: [
    ToolbarModule, 
    TableModule, 
    ButtonModule, 
    InputTextModule, 
    DialogModule, 
    DropdownModule, 
    FormsModule, 
    ReactiveFormsModule,
    ToastModule,
    InputTextareaModule,
    CalendarModule,
    TooltipModule
  ],
  providers:[MessageService],
  templateUrl: './bitacora-vendedores.component.html',
  styleUrl: './bitacora-vendedores.component.scss'
})
export class BitacoraVendedoresComponent implements OnInit{
  auditService = inject(AuditService);

  bitacora: any[] = [];

  editForm: FormGroup; 
  
  rangeDates = [];

  editMod: boolean = false;

  originalProducts: any[] = [];

  month: number;
  year: number

  constructor(private formBuilder: FormBuilder){}
  ngOnInit(){

    const { month, year } = this.getCurrentMonthAndYear();
	  this.month = month;
	  this.year = year;

    this.getBitacora(month, year);
    this.createEditForm();
  }

  getCurrentMonthAndYear(): { month: number, year: number } {
		const now = new Date();
		const month = now.getMonth() + 1; // getMonth() returns month index starting from 0
		const year = now.getFullYear();
		// console.log(month, year)
		return { month, year };
	}

  createEditForm(): FormGroup{
		return this.editForm = this.formBuilder.group({
			id: [,],
      nombre: ['',],
			email: ['', ],
      telefono: ['',],
		  direccion: ['',],
			rfc: ['',],
			alias: ['',],
			tipo_persona: ['',],
		});
	}

  getBitacora(month:number, year:number){
    this.auditService.getBitacoraVendedores(month, year).subscribe((data: Bitacora[])=>{
      console.log(data);
      this.bitacora = data.sort((a, b) => b.id - a.id);
      this.originalProducts = this.bitacora;
    })
  }

  showDialog(product:Bitacora){
    this.editMod = true;
    // this.editForm = this.createEditForm();
    this.editForm.patchValue(product.vendedor);
  }

  filterProducts(avent:any): void {

    const [startDate, endDate] = this.rangeDates.map(this.parseDate);

    console.log(this.rangeDates)

		// console.log(startDate)
		// console.log(endDate)

		const filteredProducts = this.originalProducts.filter(product => {
			const productDate = this.parseDate(product.fecha);
			return productDate >= startDate && productDate <= endDate;
		});

		this.bitacora = filteredProducts; 
		
	}

	clearProducts(){
		// this.bitacora = this.originalProducts;
    this.getBitacora(this.month, this.year);
	}

	todayProducts(){
    this.getBitacora(this.month, this.year);
    setTimeout(
      () => {
        this.filtertoday();
      }, 500);
	}

  filtertoday(){

		let today = this.rangeDates
		today[1] = this.rangeDates[0];

		const [startDate, endDate] = today.map(this.parseDate);
		
		const filteredProducts = this.originalProducts.filter(product => {
			const productDate = this.parseDate(product.fecha);
			return productDate >= startDate && productDate <= endDate;
		});

		this.bitacora = filteredProducts; 
  }

	parseDate(dateString: string): Date {
		const [day, month, year] = dateString.split('/').map(Number);
		return new Date(year, month - 1, day);
	}	

  changeMonth(event:any){

		console.log(event)

		let month = event.month;
		let year = event.year;

		this.getBitacora(month, year);
	}

}
