<div class="grid">
	<div class="col-12">

		<div class="card px-6 py-6"> 

			<ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
				<li>
					<a class="text-500 no-underline line-height-3 cursor-pointer">Home</a>
				</li>
				<li class="px-2">
					<i class="pi pi-angle-right text-500 line-height-3"></i>
				</li>
				<li>
					<a class="text-500 no-underline line-height-3 cursor-pointer">Coordinador</a>
				</li>
				<li class="px-2">
					<i class="pi pi-angle-right text-500 line-height-3"></i>
				</li>
				<li>
					<span class="text-900 line-height-3">Motivos de multa</span>
				</li>
			</ul>
			<br>
                  <p-toast></p-toast>
			<p-toolbar styleClass="mb-4">
				<ng-template pTemplate="left">

					<span class="block mt-2 md:mt-0 p-input-icon-left">
						<i class="pi pi-search"></i>
						<input pInputText type="text" (input)="onGlobalFilter(dt, $event)" placeholder="Search..."  class="w-full sm:w-auto"/>
					</span>
						<!-- <div class="my-2">
								<button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success mr-2" (click)="openNew()"></button>
								<button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger" (click)="deleteSelectedProducts()" [disabled]="!selectedProducts || !selectedProducts.length"></button>
						</div> -->
				</ng-template>

				<ng-template pTemplate="right">
					<button pButton pRipple label="Agregar" icon="pi pi-plus" class="p-button-success mr-2" (click)="showDialog()"></button>
			</ng-template>
		</p-toolbar>

        <p-table [value]="products" [columns]="cols" responsiveLayout="scroll" [rows]="10" [globalFilterFields]="['name','country.name','representative.name','status']" 
		[paginator]="true" [rowsPerPageOptions]="[10,20,30]" [showCurrentPageReport]="true" currentPageReportTemplate="Monstrando {first} a {last} de {totalRecords} registros" 
		[(selection)]="selectedProducts" selectionMode="multiple" [rowHover]="true" dataKey="id">

		<ng-template pTemplate="header">
			<tr>
					<th pSortableColumn="code">Motivo <p-sortIcon field="nombre"></p-sortIcon></th>
					<th pSortableColumn="name">Fecha <p-sortIcon field="correo"></p-sortIcon></th>
                              <th></th>
			</tr>
	</ng-template>

	<ng-template pTemplate="body" let-product>
		<tr>
				<td style="width:25%; min-width:40rem;">
						<span class="p-column-title">Motivo</span>
						{{product.motivo}}
				</td>
                        <td style="width:10%; min-width:20rem;">
                              <span class="p-column-title">Fecha</span>
                              {{product.fecha}}
                        </td>
				<td>
						<div class="flex justify-content-end">
								<button  pTooltip="Editar motivo"  pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-info mr-5" (click)="editDialog(product)"></button>
								<button  pTooltip="Eliminar motivo"  pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger" (click)="deleteDialog(product)"></button>
						</div>
				</td>
		</tr>
</ng-template>

		</p-table>

		</div>
	</div>
</div>

<p-dialog [header]="header" [(visible)]="visibleModal" (onHide)="hideDialog()" [modal]="true" [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">
	<form [formGroup]="motivoForm">


		  <div class="p-fluid grid">

				<div class="field col-12">
					  <label htmlFor="amount">Motivo</label>
					  <span class="p-input-icon-left">
							<i class="pi pi-comment"></i>
							<input formControlName="motivo" type="text" maxlength="100" pInputText placeholder="Ingresar motivo de multa" />
					  </span>
				</div>

		  </div>

		  <div class="p-fluid grid">
				<div class="field col-9"></div>
				<div class="field col-3">
					@if(edit){
						<p-button (click)="updateMotivo()" label="Actualizar" [raised]="false" severity="success" icon="pi pi-save" iconPos="left"></p-button>
					}

					@if (!edit) {
						<p-button (click)="registrarMotivo()" label="Guardar" [raised]="false" severity="success" icon="pi pi-save" iconPos="left"></p-button>
					}
				</div>
		  </div>

	</form>
</p-dialog>


<p-dialog header="Eliminar motivo" [(visible)]="delete" (onHide)="hideDeleteDialog()"  [style]="{ width: '40vw' }" [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"   [modal]="true" [draggable]="false" [resizable]="false">
		  <div class="p-fluid grid">

				<div class="field col-12">
					  <!-- <label htmlFor="amount">El siguiente motivo será eliminado</label> -->
					  <span class="p-input-icon-left">
							<i class="pi pi-comment"></i>
							<input [(ngModel)]="deleteMotivo" type="text" maxlength="100" pInputText disabled/>
					  </span>
				</div>

		  </div>

		  <div class="p-fluid grid">
			<div class="field col-3">
				<p-button (click)="hideDeleteDialog()" label="Cancelar" [raised]="false" severity="warning" icon="pi pi-times" iconPos="left"></p-button>
			</div>
				<div class="field col-3">
					<p-button (click)="deleteMotiv()" label="Eliminar" [raised]="false" severity="success" icon="pi pi-trash" iconPos="left"></p-button>
				</div>
		  </div>
</p-dialog>
     
