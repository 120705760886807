import { filter } from 'rxjs';
import { Product } from 'src/app/demo/api/product';
import { Component, Inject, OnInit, inject } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { ToolbarModule } from 'primeng/toolbar';
import { CatalogUsersService } from '../../service/catalogUsers.service';
import { DialogModule } from 'primeng/dialog';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { TicketSaleService } from 'src/app/ticket-sales/service/ticket-sales.service';
import { TooltipModule } from 'primeng/tooltip';

@Component({
selector: 'app-ticket-users',
standalone: true,
imports: [
	ToolbarModule, 
	TableModule, 
	InputTextModule, 
	ButtonModule,
	DialogModule,
	ToastModule,
	TooltipModule
],
providers:[MessageService],
templateUrl: './ticket-users.component.html',
styleUrl: './ticket-users.component.scss'
})
export class TicketUsersComponent implements OnInit {
	ticketService = inject(TicketSaleService);
	catalogUsers = inject(CatalogUsersService)
	
	cols: any[] = [];
	historyCols: any[] = [];
	selectedProducts: any[] = [];
	products = []
	history = []

	visible: boolean = false;

	header: string;
	
	constructor(private messageService: MessageService){}
	ngOnInit(): void {

	this.getSaldos();

	this.cols = [
			{ field: 'nombre', header: 'Nombre' },
			{ field: 'horas', header: 'Horas' },
			{ field: 'direccion', header:'Dirección'}
	];

	this.historyCols = [
		{ field: 'fecha', header: 'Fecha' },
		{ field: 'saldo', header:'Saldo'}
	];
	}

	getSaldos(){
		this.catalogUsers.getSaldos().subscribe((data: any)=>{
			this.products = data.filter(product => product.agente.activo)
			this.products.sort((a, b) => (a.id < b.id ? -1 : 1));

			console.log(this.products)
		})
	}

	editProduct(product: any){
		let idAgente = product.agente.id
		this.header = `Vendedor: ${product.agente.nombre}`

		this.catalogUsers.getHistorialAgente(idAgente).subscribe((data)=>{
			console.log(data)
			this.history = data;
			this.history.sort((a, b) => (a.id > b.id ? -1 : 1));
		})
		this.visible = true
	}

	eliminarVenta(product: any){
		console.log(product)
		try{
			this.catalogUsers.deleteVenta(product.id).subscribe((data)=>{

				let bitacora = {
					usuario: sessionStorage.getItem('nombre'),
					vendedor: product.agente.nombre,
					movimiento: 'REVOCACIÓN DE SALDO',
					saldo: product.paquete,
				  }

				  this.ticketService.postBitacoraVentas(bitacora).subscribe({
					next: (data) => {}
				  })

			});
			this.visible = false;
			this.addWarning("success",'Venta cancelada correctamente.', "Mensaje")



		}catch(err){
			this.addWarning("error",'Error al eliminar venta.', "Error")
		}
		setTimeout(() => {
			this.getSaldos();
		}, 1000);
	}

	addWarning(severity: string,message: string, resumen: string){
		this.messageService.add({ severity: severity, summary: resumen, 
		detail: message });
	}
}
